import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Pagination } from "@mui/material";
import styled from "styled-components";
import PaintTop from "../../../resources/images/about/PaintTop.svg";
import TextureBG from "../../../resources/images/home/bg_texture.png";
import GreenBottom from "../../../resources/images/producers/GreenSection_Bottom.svg";
import GreenTop from "../../../resources/images/producers/GreenSection_Top.svg";
import InsuranceBG from "../../../resources/images/producers/insurance_hero.png";
import BGMask from "../../../resources/images/producers/insurance_hero_mask.png";
import HeroBG from "../../../resources/images/producers/media_hero.png";
import Hero_Bottom_Banner from "../../../resources/images/producers/rules_hero_overlay.png";
export const ProdHero = styled("div")`
  width: 100%;
  min-height: 50vh;
  background-image: url(${HeroBG});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;
export const InsuranceHero = styled("div")`
  width: 100%;
  min-height: 50vh;
  background-image: url(${InsuranceBG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 50%;
  position: relative;
  mask-image: url(${BGMask});
  -webkit-mask-image: url(${BGMask});
  mask-position: 50% 100%;
  mask-size: cover;
  mask-repeat: no-repeat;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(23, 35, 39, 0) 0%, #172327 100%);
    z-index: -1;
  }

  @media (min-width: 1280px) {
    mask-size: calc(100vw + 20%);
  }
`;

export const SubPagesHero = styled("div")`
  width: 100%;
  min-height: 70vh;
  position: relative;
  mask-image: url(${Hero_Bottom_Banner});
  mask-position: 35% 100%;
  mask-repeat: no-repeat;
  z-index: 2;
  &::before {
    content: "";
    background: url(${(props) => props.herobg});
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
  }
  &::after {
    content: "";
    background: ${(props) =>
      props.secondary
        ? "linear-gradient(0deg, #F2BB36 0%, rgba(250, 250, 250, 0) 100%)"
        : "linear-gradient(0deg,#c5dba4 0%, rgba(197, 219, 164, 0) 100%)"};
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
  }

  @media (min-width: 1920px) {
    mask-size: 150%;
  }
`;
export const HeroDivider = styled("div")``;
export const TextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;
  z-index: 0;
`;

export const SecondaryTextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
`;

export const TopDivider = styled("div")`
  width: 100%;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    background-image: url(${PaintTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
const VideoImageWrap = styled("div")`
  min-width: 250px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #414141 100%);
  }
`;
export function VideoCard({ src, data, item }) {
  // const [thumbnailUrl, setThumbnailUrl] = useState();

  // useEffect(() => {
  //   // const videoUrl = item?.multimedia_url;
  //   // const videoId = videoUrl?.split("v=")[1];
  //   const videoId = "MpxIe1mFVzY";
  //   const thumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  //   setThumbnailUrl(thumbnail);
  // }, []);

  return (
    <div className="basis-full md:basis-[calc(33%_-_20px)] flex flex-col">
      <VideoImageWrap>
        <video muted className="w-full h-auto" controls={false} playsInline>
          <source
            src={item.multimedia_url}
            type="video/mp4"
            style={{ pointerEvents: "none" }}
          />
        </video>
        <PlayCircleIcon
          fontSize="large"
          htmlColor="#FFFFFFCC"
          className="absolute z-[1] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
        />
      </VideoImageWrap>
      <p className="text-lg font-semibold mt-2">{item?.name}</p>
    </div>
  );
}
export const VideoPagination = styled(Pagination)`
  & .MuiPaginationItem-root {
    &.Mui-selected {
      background-color: #172726;
      color: #fff;
      &:hover {
        background-color: #172726;
      }
    }
  }
`;
export const GreenSection = styled("div")`
  background-color: #c5dba4;
  position: relative;
  padding: 40px 0;
  z-index: 1;

  &::before {
    content: "";
    width: 100%;
    height: 100px;
    top: -50px;
    left: 0;
    z-index: -1;
    position: absolute;
    background-image: url(${GreenTop});
    background-size: cover;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100px;
    bottom: -50px;
    left: 0;
    z-index: -1;
    position: absolute;
    background-image: url(${GreenBottom});
    transform: rotate(180deg);
    background-size: cover;
  }
`;
