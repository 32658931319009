import React, { useEffect, useState } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import "@splidejs/react-splide/css/core";
import moment from "moment";
import AnimatedNumbers from "react-animated-numbers";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";
import WeatherCard from "../../resources/components/general/WeatherCard";
import Wrapper from "../../resources/components/general/Wrapper";
import AlertCard from "../../resources/components/home/AlertCard";
import GesbaLogo from "../../resources/images/home/Gesba_long_logo.svg";
import CachoBG from "../../resources/images/home/cacho.png";
import CachoBGMobile from "../../resources/images/home/cacho_mobile.png";
import CallToAction from "../../resources/images/home/calltoaction_img.png";
import CertifiedSticker from "../../resources/images/home/certificacao_sticker.png";
import TeachSticker from "../../resources/images/home/formacao_sticker.png";
import PopUp from "../../resources/images/home/homepage_popup.svg";
import PromoSticker from "../../resources/images/home/promo_sticker.png";
import QualitySticker from "../../resources/images/home/qualidade_sticker.png";
import {
  AlertCarousel,
  CalltoAction,
  HeroArea,
  HeroCaption,
  StatsSection,
  TextureBackground,
} from "./styles/home_styles";

function Home() {
  const large = useMediaQuery("(min-width:1024px)");
  const medium = useMediaQuery("(min-width:769px)");
  const [closePopup, setClosePopup] = useState(true);
  const producersNum = 2900;
  const [productionNum, setProductionNum] = useState(20215353);
  const [productionYear, setProductionYear] = useState(2022);
  const [productionInterval, setProductionInterval] = useState();
  const [alerts, setAlerts] = useState();
  const [bottom, setBottom] = useState(null);
  const [bottomKey, setBottomKey] = useState(null);

  const [meteoData, setMeteoData] = useState();
  const [meteoWarnings, setMeteoWarnings] = useState();
  const [uvData, setUvData] = useState();
  const [selectedMeteo, setSelectedMeteo] = useState(0);
  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: ref3, inView: inView3 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const keyToUrl = (key) => {
    switch (key) {
      case "Avisos":
        return "/communications";
      case "Eventos":
        return "/events";
      case "Notícias":
        return "/communications";
      case "Seguros e Ajudas":
        return "/producers/insurance";
      default:
        return "/production";
    }
  };

  useEffect(() => {
    axiosConfig.get(`/dashboardInfo`).then((res) => {
      setProductionNum(res.data?.production?.value);
      setProductionYear(res.data?.production?.year);
      setProductionInterval(res.data?.production?.production_interval);
      setAlerts(res.data?.latest);
      if (res.data?.latest) {
        let date;
        let indexOfLatest;

        Object.keys(res.data.latest).forEach((key) => {
          if (!date) {
            date = res.data.latest[key].created_at;
            indexOfLatest = key;
          } else {
            if (moment(res.data.latest[key].created_at).isAfter(moment(date))) {
              date = res.data.latest[key].created_at;
              indexOfLatest = key;
            }
          }
        });
        if (moment().diff(moment(date), "days") < 5) {
          setBottom(res.data.latest[indexOfLatest]);
          setBottomKey(indexOfLatest);
        }
      }
    });
  }, []);

  useEffect(() => {
    axiosConfig
      .get(
        "https://api.ipma.pt/open-data/forecast/meteorology/cities/daily/2310300.json"
      )
      .then((res) => setMeteoData(res.data.data))
      .catch((err) => console.log(err));

    axiosConfig
      .get("https://api.ipma.pt/open-data/forecast/meteorology/uv/uv.json")
      .then((res2) => setUvData(res2.data))
      .catch((err) => console.log(err));

    axiosConfig
      .get("https://api.ipma.pt/open-data/forecast/warnings/warnings_www.json")
      .then((res3) => {
        let tempWarning = res3.data.filter(
          (record) => record.idAreaAviso === "MCS"
        );
        setMeteoWarnings(tempWarning);
      })
      .catch((err) => console.log(err));
  }, []);
  const animationClass = medium ? "animate-slideLeft" : "animate-slideDown";
  const redAlerts = meteoWarnings?.filter(
    (record) => record.awarenessLevelID === "red"
  );
  const orangeAlerts = meteoWarnings?.filter(
    (record) => record.awarenessLevelID === "orange"
  );
  const yellowAlerts = meteoWarnings?.filter(
    (record) => record.awarenessLevelID === "yellow"
  );
  return (
    <>
      <HeroArea className="min-h-[900px] md:min-h-max pb-20">
        <Wrapper>
          <div className="flex flex-col items-center justify-center gap-5">
            <div className="flex xl:flex-row flex-col items-center w-full xl:py-[60px] mt-10">
              <div className="xl:basis-4/12 basis-2/12"></div>
              <HeroCaption className="flex-grow gap-2 sm:gap-5 mt-44 md:mt-24">
                <h1 className="text-5xl md:text-6xl text-white uppercase font-bold">
                  GESBA
                </h1>
                <h2 className="text-3xl text-white uppercase font-semibold">
                  Juntos fazemos crescer a produção
                </h2>
                <Link
                  to="/about"
                  className="mt-3 py-2 px-4 bg-[#DCDCDC] rounded uppercase font-semibold"
                >
                  Saber Mais
                </Link>{" "}
              </HeroCaption>
              {meteoData && (
                <div className="text-white w-full basis-4/12 h-full mt-2 xl:mt-0">
                  <div className=" flex flex-col h-full max-w-screen-md xl:max-w-[250px] mx-auto xl:ml-auto xl:mr-0">
                    <p className="uppercase text-center font-bold text-base">
                      Madeira
                    </p>
                    <WeatherCard
                      data={meteoData[selectedMeteo]}
                      uv={uvData}
                      warnings={meteoWarnings}
                    />
                    <div className="flex justify-center my-3">
                      {meteoData?.map((data, index) => {
                        let parsedWeatherType =
                          data.idWeatherType < 10
                            ? `0${data.idWeatherType}`
                            : data.idWeatherType;

                        const date = new Date(data.forecastDate);
                        const dayOfWeek = date
                          .toLocaleDateString("pt-PT", {
                            weekday: "short",
                          })
                          .slice(0, 3);

                        const isSelected = index === selectedMeteo;
                        return (
                          <div className="flex flex-col">
                            <div
                              className={`border-[1px] ${
                                redAlerts?.length > 0 &&
                                new Date(redAlerts[0].endTime) > date
                                  ? "border-[#FF4949] bg-[#FF494940]"
                                  : orangeAlerts?.length > 0 &&
                                    new Date(orangeAlerts[0].endTime) > date
                                  ? "border-[#FF9D00] bg-[#FF9D0040]"
                                  : yellowAlerts?.length > 0 &&
                                    new Date(yellowAlerts[0].endTime) > date
                                  ? "border-[#F2BB36] bg-[#F2BB3640]"
                                  : "border-white"
                              } rounded-md cursor-pointer ${
                                isSelected
                                  ? "bg-[#00000040] border-[#ffffff00]"
                                  : ""
                              }`}
                              onClick={() => setSelectedMeteo(index)}
                            >
                              <img
                                src={`/files/meteo/w_ic_d_${parsedWeatherType}anim.svg`}
                                alt=""
                                className="h-10 w-10"
                              />
                              <p className="text-center xl:text-base text-sm">
                                {dayOfWeek}
                              </p>
                            </div>
                            <span
                              className={`text-xs text-center font-semibold ${
                                redAlerts?.length > 0 &&
                                new Date(redAlerts[0].endTime) > date
                                  ? "text-[#FF4949] visible"
                                  : orangeAlerts?.length > 0 &&
                                    new Date(orangeAlerts[0].endTime) > date
                                  ? "text-[#FF9D00] visible"
                                  : yellowAlerts?.length > 0 &&
                                    new Date(yellowAlerts[0].endTime) > date
                                  ? "text-[#F2BB36] visible"
                                  : "text-white hidden"
                              }`}
                            >
                              Alerta
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full mt-auto mb-10">
              <AlertCarousel>
                {alerts &&
                  Object.entries(alerts).map(([key, value], index) => {
                    if (index === 3) {
                      return <AlertCard type={key} data={value} key={index} />;
                    } else {
                      return (
                        <AlertCard
                          divider
                          type={key}
                          data={value}
                          key={index}
                        />
                      );
                    }
                  })}
              </AlertCarousel>
            </div>
          </div>
        </Wrapper>
      </HeroArea>
      <StatsSection className="flex flex-col lg:flex-col">
        <Wrapper twoThirds className={"pt-20 pb-10"}>
          <div className="flex flex-col items-center justify-center w-full bg-white rounded box-border shadow-md p-10 md:flex-row gap-5">
            <div className="flex flex-col items-center gap-2 basis-1/2 w-full py-4 md:py-0 md:border-b-0 border-b-[1px] border-b-[#70707080] ">
              <p className="flex items-center gap-5 font-semibold text-2xl">
                <AnimatedNumbers
                  animateToNumber={producersNum}
                  fontStyle={{ fontSize: 32 }}
                  configs={(number, index) => {
                    return {
                      mass: 1,
                      tension: 230 * (index + 1),
                      friction: 140,
                    };
                  }}
                />
              </p>
              {/* <p className="font-bold text-2xl">2 900</p> */}
              <p className="text-[#707070] text-center">Produtores</p>
            </div>
            <hr className="hidden md:block h-[50px] border-b-[1px] border-b-[#70707080] md:border-b-0 md:border-r-[2px] md:border-r-[#70707080]" />
            <div className="flex flex-col items-center gap-2 border-b-[1px] basis-1/2 w-full py-4  md:py-0 md:border-b-0 text-center">
              <p className="flex items-center gap-5 font-semibold text-2xl">
                <AnimatedNumbers
                  animateToNumber={productionNum}
                  includeComma
                  locale="pt"
                  fontStyle={{ fontSize: 32 }}
                  configs={(number, index) => {
                    return {
                      mass: 1,
                      tension: 230 * (index + 1),
                      friction: 140,
                    };
                  }}
                />{" "}
                Kg
              </p>
              <p className="text-[#707070]">
                Produção{" "}
                {Array.isArray(productionInterval) ? (
                  <>
                    <span className="capitalize">
                      {moment(productionInterval[0]).format("MMMM")}
                    </span>{" "}
                    -{" "}
                    <span className="capitalize">
                      {moment(productionInterval[1]).format("MMMM")}
                    </span>
                  </>
                ) : (
                  <span className="capitalize">
                    {moment(productionInterval).format("MMMM")}
                  </span>
                )}{" "}
                {productionYear}
              </p>
            </div>
          </div>
        </Wrapper>
        <Wrapper className={"pt-10 pb-20"}>
          <h2 className="text-center text-2xl font-bold uppercase py-10">
            Seja bem vindo à gesba
          </h2>
          <div
            className="flex flex-col items-center h-full gap-10 md:flex-row overflow-hidden"
            ref={ref3}
          >
            <div
              className={`basis-1/2 flex justify-center ${
                inView3 ? "slide-in-left visible" : "invisible"
              }`}
            >
              <img src={GesbaLogo} alt="" />
            </div>
            <div
              className={`flex flex-col basis-1/2 md:mr-20 slide-in-right 
                `}
            >
              <p className="mb-5 text-justify text-[#5E5E5E]">
                Seja bem-vindo ao website da GESBA – Empresa de Gestão do Sector
                da Banana, Lda. Quer seja produtor, trabalhador agrícola,
                cliente, consumidor ou apenas um curioso sobre o sector da
                Banana da Madeira, esperamos que possa desfrutar de toda a
                informação que disponibilizamos neste espaço digital.
              </p>
              <p className="mb-5 text-justify text-[#5E5E5E]">
                Mais do que tempo, dedicamos energia e paixão na criação deste
                website, de modo a oferecer algo novo, inovador e intuitivo, que
                responda às necessidades dos vários tipos de utilizador.
              </p>
              <p className="mb-5 text-justify text-[#5E5E5E]">
                Desde 2008 que estamos em atividade, procurando que o sector
                cresça de forma estável e sustentável, permitindo aos produtores
                de Banana da Madeira ter uma empresa que valorize e dê mais
                notoriedade ao fruto do seu trabalho.
              </p>
              <p className="mb-5 text-justify text-[#5E5E5E]">
                Nos últimos 15 anos, a GESBA apostou na formação dos produtores
                e colaboradores, e na modernização do sector, através da
                requalificação e construção de infraestruturas que, por sua vez
                permitiram a implementação de certificações de qualidade,
                garantindo sempre autenticidade e reconhecimento da Banana da
                Madeira nos mais exigentes mercados, o que permitiu fazer
                crescer a produção e aumentar o rendimento dos produtores.
              </p>
            </div>
          </div>
        </Wrapper>
      </StatsSection>
      <CalltoAction
        style={{
          height: "unset",
        }}
        className="h-full"
      >
        <Wrapper full>
          <div className="flex items-center w-full h-full py-20">
            <div
              className={`flex flex-col gap-5 mx-auto items-center justify-center h-full`}
            >
              <h3 className="text-white text-xl md:text-2xl xl:text-3xl font-semibold  leading-7 xl:leading-10 uppercase">
                Quem faz acontecer
              </h3>
              <p className="text-white text-lg max-w-screen-sm lg:max-w-screen-md text-center">
                Todos os funcionários que fazem parte da Gesba unem esforços
                todos os dias para que a produção de banana seja maior e com a
                melhor qualidade possível. Juntos fazemos com que esta marca se
                eleve ao mais alto nível de qualidade.
              </p>
              <div className="flex justify-center gap-10 lg:gap-20 w-full flex-col md:flex-row">
                <div className="flex flex-col items-center text-white">
                  <p className="text-4xl font-bold">
                    <AnimatedNumbers
                      animateToNumber={320}
                      fontStyle={{ fontSize: 32, fontWeight: 800 }}
                      configs={(number, index) => {
                        return {
                          mass: 1,
                          tension: 230 * (index + 1),
                          friction: 140,
                        };
                      }}
                    />
                  </p>
                  <div className="h-px w-[120%] bg-white my-3 max-md:hidden" />
                  <p>Funcionários</p>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </CalltoAction>
      <TextureBackground className="py-10 overflow-hidden">
        <Wrapper>
          <h2 className="hidden lg:block text-center text-2xl font-bold uppercase py-7 lg:py-10">
            A Nossa Missão
          </h2>

          <div className="flex flex-col w-full lg:flex-row " ref={ref1}>
            <div
              className={`w-full text-center relative lg:ml-[-20vw] lg:w-[unset] xl:ml-[-10vw] 2xl:ml-[-25vw] ${
                inView1 ? `${animationClass} visible` : "invisible"
              }`}
            >
              {large ? (
                <img
                  src={CachoBG}
                  alt="Cacho de banana adaptado desktop"
                  className="relative object-contain w-full lg:relative lg:max-h-[700px] 2xl:max-h-[unset]"
                />
              ) : (
                <img
                  src={CachoBGMobile}
                  alt="Cacho de banana adaptado mobile"
                  className="relative object-contain w-full max-w-xs md:max-w-md lg:max-w-screen-sm max-h-full mt-[-15vh] md:mt-[-20vh] mx-auto"
                />
              )}
            </div>
            <h2 className="block lg:hidden  text-center text-2xl font-bold uppercase py-10">
              A Nossa Missão
            </h2>
            <div
              className={`flex flex-col items-center justify-center gap-10 pb-20 basis-5/6 ${
                inView1 ? "slide-in-right visible" : "invisible"
              }`}
            >
              <div className="flex flex-col gap-10 items-center lg:flex-row w-full">
                <img
                  src={QualitySticker}
                  alt="Qualidade"
                  className="max-w-[150px]"
                />
                <div className="flex flex-col gap-3 items-center lg:items-start">
                  <h3 className="text-xl font-medium uppercase">Qualidade</h3>
                  <p className="text-[#707070] text-justify">
                    Investigação e desenvolvimento de técnicas agrícolas que
                    visem a melhoria da qualidade do produto e a sua
                    valorização, preservando a tipicidade e características
                    próprias, ao nível do sabor, doçura, tamanho, etc.
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-10 items-center lg:flex-row lg:ml-20 ">
                <img
                  src={CertifiedSticker}
                  alt="Qualidade"
                  className="max-w-[150px]"
                />
                <div className="flex flex-col gap-3 items-center lg:items-start">
                  <h3 className="text-xl font-medium uppercase">
                    Certificação
                  </h3>
                  <p className="text-[#707070] text-justify">
                    Apoio técnico e científico nas certificações da Banana da
                    Madeira que visem a valorização do produto, tais como:
                    <ul className="pl-5">
                      <li className="list-disc">
                        Certificação Global G.A.P.® da produção da Banana da
                        Madeira;
                      </li>
                      <li className="list-disc">
                        Certificação de Segurança Alimentar.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-10 items-center lg:flex-row lg:ml-20">
                <img
                  src={TeachSticker}
                  alt="Qualidade"
                  className="max-w-[150px]"
                />
                <div className="flex flex-col gap-3 items-center lg:items-start">
                  <h3 className="text-xl font-medium uppercase">Formação</h3>
                  <p className="text-[#707070] text-justify">
                    Ações de formação e workshops para dar a conhecer o seu
                    trabalho de investigação técnica e científica e demonstrar a
                    aplicação prática das técnicas agrícolas e acompanhar a
                    respetiva aplicação nas explorações agrícolas;
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-10 items-center lg:flex-row w-full">
                <img
                  src={PromoSticker}
                  alt="Qualidade"
                  className="max-w-[150px]"
                />
                <div className="flex flex-col gap-3 items-center lg:items-start">
                  <h3 className="text-xl font-medium uppercase">Promoção</h3>
                  <p className="text-[#707070] text-justify">
                    Promover e gerar interesse pelo setor agrícola e em
                    particular pela cultura da banana.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </TextureBackground>
      <CalltoAction>
        <Wrapper full>
          <div className="flex items-center h-full min-h-[280px]" ref={ref2}>
            <div
              className={`flex basis-1/2 items-center  mx-10 ${
                inView2 ? "slide-in-left" : "invisible"
              }`}
            >
              <h3 className="text-[#FFF4CF] text-xl md:text-2xl xl:text-3xl font-semibold leading-7 xl:leading-10 uppercase">
                Trabalhamos com qualidade e rigor, dando valor à produção e aos
                nossos produtores
              </h3>
            </div>
            <div className="mr-[-50px] md:mr-0 flex basis-1/2 items-center h-full py-10">
              <img
                src={CallToAction}
                alt="Imagem representativa da banana"
                className={`w-full ${inView2 ? "slide-in-right" : "invisible"}`}
              />
            </div>
          </div>
        </Wrapper>
      </CalltoAction>
      {closePopup && bottom && (
        <div className="fixed bottom-0 left-0 z-[20] w-screen min-h-[150px] slide-in-up">
          <img
            src={PopUp}
            alt=""
            className="absolute w-full h-full object-cover 2xl:object-contain 2xl:h-auto z-[-1] "
          />
          <Wrapper className={"w-full h-full"}>
            <div className="flex flex-col md:flex-row w-full h-full items-center gap-2 p-5 mt-5">
              <div className="flex flex-col md:flex-row gap-5 items-start basis-10/12">
                <span className="bg-[#FAFAFA80] py-1 px-3 rounded">
                  {bottomKey}
                </span>
                <div className="flex flex-col gap-3 w-10/12">
                  <h4 className="text-white text-xl font-semibold">
                    {bottom.title
                      ? bottom.title
                      : bottom.name
                      ? bottom.name
                      : ""}
                  </h4>
                  <p
                    style={{
                      display: "-webkit-box",
                      "-webkit-line-clamp": "2",
                      "-webkit-box-orient": "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    className="text-white font-semibold"
                  >
                    {bottom.body
                      ? bottom.body
                      : bottom.description
                      ? bottom.description
                      : bottom.annex_about
                      ? bottom.annex_about
                      : ""}
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-between items-end gap-5 ml-auto text-white basis-2/12">
                <div
                  className="absolute top-5 right-5 md:relative md:top-auto md:right-auto cursor-pointer"
                  onClick={() => setClosePopup(false)}
                >
                  <CloseIcon fontSize="large" />
                </div>
                <Link to={keyToUrl(bottomKey)}>
                  Saber mais <ArrowForwardIosIcon />
                </Link>
              </div>
            </div>
          </Wrapper>
        </div>
      )}
    </>
  );
}

export default Home;
