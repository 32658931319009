import React from "react";
import Wrapper from "../../../resources/components/general/Wrapper";
import HeroBg from "../../../resources/images/producers/practices_hero.png";
import {
  GreenSection,
  SubPagesHero,
  TextureBackground,
} from "../styles/producers_styles";

import { ReactComponent as BeetleLeaf } from "../../../resources/images/producers/beetle_leaf.svg";
import FarmImg from "../../../resources/images/producers/farm_img.png";
import InsectImg from "../../../resources/images/producers/insect_img.png";
import { ReactComponent as LeafLeft } from "../../../resources/images/producers/leaf_left_bg.svg";
import { ReactComponent as LeafRight } from "../../../resources/images/producers/leaf_right_bg.svg";

function Practices() {
  return (
    <>
      <TextureBackground>
        <SubPagesHero herobg={HeroBg} secondary>
          <Wrapper closeFull>
            <div className="relative min-h-[60vh] flex flex-col justify-center items-start ">
              <h1
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
                className="text-5xl text-white font-bold text-center z-[1] leading-[66px]"
              >
                Boas práticas agrícolas
              </h1>
            </div>
          </Wrapper>
        </SubPagesHero>
      </TextureBackground>
      <TextureBackground className="py-20">
        <Wrapper className={"relative"} closeFull>
          <LeafLeft className="absolute -left-40 top-0" />
          <h2 className="text-2xl text-[#172726] font-semibold uppercase text-center mb-10">
            No cultivo
          </h2>
          <div className="flex flex-col lg:flex-row lg:flex-nowrap items-start relative z-[1] gap-5">
            <div className="basis-full md:basis-3/4">
              <ul>
                <li className="list-disc max-w-screen-sm">
                  Adotar operações e técnicas de cultivo e de transporte, em
                  condições de higiene e de segurança, mantendo sempre a
                  integridade do produto, a sua qualidade, de acordo com as
                  normas aplicáveis e a sustentabilidade da cultura.
                </li>
              </ul>
            </div>
            <img src={FarmImg} alt="" />
            {/* <LeafBanana className="absolute left-1/6 z-[-1] hidden md:block opacity-50" /> */}
          </div>
          <LeafRight className="absolute -right-20 -bottom-20 md:-top-20 hidden lg:block" />
        </Wrapper>
      </TextureBackground>
      <GreenSection>
        <Wrapper closeFull>
          <h2 className="text-2xl text-[#172726] font-semibold uppercase text-center mb-10">
            Com as pragas/doenças
          </h2>
          <div className="flex flex-col lg:flex-row-reverse items-start relative z-[1] gap-5">
            <div className="basis-full md:basis-3/4">
              <ul>
                <li className="list-disc">
                  Controlar pragas/doenças, avaliando primeiro o seu nível de
                  ataque na exploração e, se justificável, selecionar e aplicar
                  produto fitofarmacêutico, tendo sempre presente a minimização
                  dos seus impactos no homem e no ambiente.
                </li>
              </ul>
            </div>
            <img src={InsectImg} alt="" />
            <BeetleLeaf className="absolute bottom-10 right-1/6 hidden lg:block z-[-1]" />
          </div>
        </Wrapper>
      </GreenSection>
    </>
  );
}
export default Practices;
