import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import axios from "axios";
import moment from "moment";
import "moment/locale/pt";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../resources/components/general/BackButton";
import EventCard from "../../resources/components/general/EventCard";
import Wrapper from "../../resources/components/general/Wrapper";
import GreenBottom from "../../resources/images/home/divider_green_bottom.svg";
import GreenTop from "../../resources/images/home/divider_green_top.svg";
import { ReactComponent as LeafBG } from "../../resources/images/news/palm_bg.svg";
import { TextureBackground } from "./styles/news_style";
import axiosConfig from "../../providers/axiosConfig";

const OpenStreetMap = ({ location }) => {
  const [coords, setCoords] = useState(null);

  async function getLocationCoords(locationName) {
    const url = `https://nominatim.openstreetmap.org/search?q=${locationName}&format=json`;
    await axios(url).then((res) => {
      setCoords([
        parseFloat(res.data[0].lon),
        parseFloat(res.data[0].lat),
        `${res.data[0].boundingbox[2]},${res.data[0].boundingbox[0]},${res.data[0].boundingbox[3]},${res.data[0].boundingbox[1]}`,
      ]);
    });
  }

  useEffect(() => {
    getLocationCoords(location);
  }, [location]);

  let url = "";
  if (coords) {
    url = `https://www.openstreetmap.org/export/embed.html?bbox=${coords[2]}&layer=mapnik&marker=${coords[1]},${coords[0]}`;
  }

  return (
    <div className="w-full h-96">
      {coords && (
        <iframe
          title="OpenStreetMap"
          src={url}
          width="100%"
          height="100%"
        ></iframe>
      )}
    </div>
  );
};

function EventDetails() {
  const { state } = useLocation();
  const item = state?.item;
  // const events = state?.events_data;

  const [events, setEvents] = useState();
  const [tempEvents, setTempEvents] = useState();

  const searchParams = new URLSearchParams({
    q: item?.location,
  });
  const url = `https://www.google.com/maps/search/?${searchParams.toString()}`;

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // let date = "15/02/2023";
  // let time = "11:00:00";
  // let location = "Praia da Madalena do mar";

  // const searchParamsSchedule = new URLSearchParams({
  //   date,
  //   time,
  //   location,
  // });

  let scheduleUrl = "";

  // if (isMobile) {
  //   scheduleUrl = `calshow:${searchParamsSchedule.toString()}`;
  // } else {
  //   scheduleUrl = `https://www.google.com/calendar/render?action=TEMPLATE&${searchParamsSchedule.toString()}`;
  // }

  useEffect(() => {
    // setLoading(true);
    axiosConfig.get(`/events`).then((res) => {
      setEvents(res.data);
      // setLoading(false);
    });
    // .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (events) {
      let aux = events
        .filter((event) => event.id !== item.id)
        .sort(() => Math.random() - 0.5)
        .slice(0, 4);

      setTempEvents(aux);
    }
  }, [events, item]);

  return (
    <>
      <div className="min-h-[70vh] w-full relative overflow-hidden z-[1]">
        <img
          src={item?.photo_url}
          alt=""
          className="absolute object-cover w-full h-full"
        />
        <div className="bg-gradient-to-t from-[transparent] to-[transparent] z-[0] absolute w-full h-full" />
        <Wrapper closeFull>
          <div className="flex flex-col h-full justify-between mt-[100px]">
            <BackButton className="text-white top-40" />
            <div className="flex flex-col gap-3 absolute bottom-40 text-white">
              <h2 className="font-semibold uppercase text-3xl md:text-5xl lg:text-6xl w-10/12">
                {/* {item?.name} */}
              </h2>
            </div>
          </div>
        </Wrapper>
      </div>
      <div className="bg-[#1C6C5E] text-white py-5 relative z-[2]">
        <img
          src={GreenTop}
          alt=""
          className="absolute w-full h-full top-[-50px] z-[-1] object-cover"
        />
        <Wrapper closeFull>
          <div className="flex flex-col w-full">
            <h3 className="text-2xl uppercase font-semibold">{item?.name}</h3>
            <div className="flex flex-col md:flex-row items-start mt-10">
              {item && item["event-dates"].length > 0 && (
                <div className="flex flex-col md:border-r border-r-white md:px-5">
                  <div className="flex gap-5 items-center">
                    <span className="bg-[#C5DBA4] rounded w-10 h-10 flex items-center justify-center self-start">
                      <a href={scheduleUrl} target="_blank" rel="noreferrer">
                        <CalendarTodayOutlinedIcon htmlColor="#1C6C5E" />
                      </a>
                    </span>
                    <h3 className="uppercase text-2xl text-white font-semibold">
                      Data e Hora
                    </h3>
                  </div>
                  <div className="ml-[60px] mt-5">
                    {item["event-dates"].map((item, index) => {
                      let date_time = moment(item.date_time);
                      date_time.locale("pt");
                      let date = date_time.format("dddd, DD [de] MMMM YYYY");

                      return (
                        <div key={index}>
                          <span
                            className="text-white uppercase font-prompt text-base"
                            // href={scheduleUrl}
                            // target="_blank"
                            // rel="noreferrer"
                          >
                            {date}
                          </span>
                        </div>
                      );
                    })}
                    <p className="text-white font-prompt text-base">
                      {item?.start_time}
                    </p>
                  </div>
                </div>
              )}
              <div
                className={`flex flex-col mt-5 md:mt-0  ${
                  item["event-dates"].length > 0 && "md:pl-24"
                }`}
              >
                <div className="flex gap-5 items-center">
                  <span className="bg-[#C5DBA4] rounded w-10 h-10 flex items-center justify-center self-start">
                    <LocationOnOutlinedIcon htmlColor="#1C6C5E" />
                  </span>
                  <h3 className="uppercase text-2xl text-white font-semibold">
                    Localização
                  </h3>
                </div>
                <div className="ml-[60px] mt-5">
                  <a
                    className="text-white uppercase"
                    href={url}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <span className="font-prompt text-base">
                      {item?.location}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            {item?.annex_url &&
              item?.annex_name &&
              !item?.annex_url.includes("api.gesba.pt") && (
                <a
                  href={item?.annex_url}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 self-end"
                >
                  <FileCopyIcon /> {item?.annex_name}
                </a>
              )}
          </div>
        </Wrapper>
        <img
          src={GreenBottom}
          alt=""
          className="absolute w-full h-full bottom-[-50px] rotate-180  z-[-1] object-cover"
        />
      </div>
      <TextureBackground className="relative z-[1]">
        <Wrapper closeFull className={"relative z-[1]"}>
          <div className="flex flex-col lg:flex-row gap-10 py-40">
            <div className="basis-3/4">
              <h3 className="text-3xl font-semibold uppercase mb-5">
                localização no mapa
              </h3>

              {item?.location && <OpenStreetMap location={item?.location} />}

              <h3 className="text-3xl font-semibold uppercase my-5">
                Sobre este evento
              </h3>

              <p className="text-lg text-[#707070] text-justify whitespace-pre-line">
                {item?.annex_about ??
                  "Sem dados relativos a este evento de momento"}
              </p>
            </div>
            {/* Limite de 4 eventos */}
            <div className="flex flex-row md:flex-col basis-2/6 md:max-h-full flex-wrap md:flex-nowrap mg:gap-10">
              <h2 className="uppercase font-semibold mb-5">
                Eventos Relacionados
              </h2>
              {tempEvents &&
                tempEvents.map((item, index) => {
                  return (
                    <EventCard
                      secondary
                      data={item}
                      events={events}
                      related
                      key={index}
                    />
                  );
                })}
            </div>
          </div>
        </Wrapper>
        <LeafBG className="absolute bottom-0 right-0 opacity-40" />
      </TextureBackground>
    </>
  );
}

export default EventDetails;
