import React from "react";
import ScrollToTop from "./ScrollTop";
import NavBar from "./NavBar";
import ScrollTopButton from "./ScrollTopButton";
import Footer from "./Footer";

const Page = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <ScrollToTop />
      <NavBar />
      <div className="flex-grow">{children}</div>
      <ScrollTopButton />
      <Footer />
    </div>
  );
};

export default Page;
