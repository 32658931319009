import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "moment/locale/pt";

function EventCard({ secondary, big, data, events, related }) {
  const stringLimit = 150;
  const navigate = useNavigate();

  let aboutText =
    data?.annex_about?.length > stringLimit
      ? data?.annex_about.slice(0, stringLimit) + "..."
      : data?.annex_about;

  function getParsedDate(dateString) {
    const date = moment(dateString);
    date.locale("pt");
    const formattedDate = date.format("DD.MM.YYYY");

    return formattedDate;
  }

  return (
    <div
      onClick={() => {
        navigate(`/events/show/${data.id}`, {
          state: { item: data, events_data: events },
        });
      }}
      className={`${
        big
          ? "md:max-h-[210px] lg:basis-[calc(50%_-_20px)] mt-5"
          : "basis-full my-2"
      } flex flex-col w-full cursor-pointer`}
    >
      <div className="flex flex-col w-full">
        <p className="text-[#B1B1B1]">
          {data && data["event-dates"].length > 0
            ? getParsedDate(data["event-dates"][0].date_time)
            : getParsedDate(data?.created_at)}
        </p>
        <div className={`flex flex-col ${related ? "" : "md:flex-row"} gap-3`}>
          <img
            src={data?.photo_url}
            alt=""
            className={`${big ? "w-[270px] h-[210px]" : ""} cursor-pointer`}
            onClick={() =>
              navigate(`/events/show/${data.id}`, {
                state: { item: data, events_data: events },
              })
            }
          />
          <div className="flex flex-col justify-between w-full">
            <div>
              <p
                className={` ${
                  secondary ? "text-black" : "text-white"
                } text-lg font-bold`}
              >
                {data?.name}
              </p>
              <p
                className={`${
                  secondary ? "text-black" : "text-white"
                } font-normal`}
              >
                {aboutText}
              </p>
            </div>
            <Link
              to={`/events/show/${data?.id}`}
              state={{ item: data, events_data: events }}
              className={`${
                big ? "flex justify-start md:justify-end" : ""
              } text-[#F2BB36]`}
            >
              ver mais
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
