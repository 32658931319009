function Storage({ title, first, second, icon }) {
  return (
    <div className="bg-white rounded flex flex-col flex-grow font-prompt">
      <div className="bg-[#E8E7E3] py-5 items-center rounded-t border-2 border-white flex flex-grow flex-wrap sm:flex-nowrap flex-col md:flex-row">
        <div className="flex justify-center mt-[-50px] md:mt-[-20px] ml-[-20px] mb-[-20px]">
          {icon}
        </div>
        <div className="text-[#1C6C5E] mx-5 font-semibold uppercase flex flex-col gap-2 items-center flex-grow mt-8 md:mt-0">
          <p className="flex justify-center font-light text-center">
            Centros de Processamento
          </p>
          <p className="flex justify-center font-medium text-center">{title}</p>
        </div>
      </div>
      <div className="flex flex-grow ">
        <div className="flex flex-col items-center md:flex-row gap-3 md:gap-8 py-5 text-[#172726] mx-auto border-2 border-white">
          <div className="">
            <p>Segunda a Sexta</p>
          </div>
          <div className="flex gap-3">{first}</div>
          <div className="flex gap-3">{second}</div>
        </div>
      </div>
    </div>
  );
}

export default Storage;
