import emailjs from "@emailjs/browser";
import { Fax, LocationOn, Mail, Phone, PhoneIphone } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Map from "../../resources/components/contacts/Map";
import Reception from "../../resources/components/contacts/Reception";
import Storage from "../../resources/components/contacts/Storage";
import Wrapper from "../../resources/components/general/Wrapper";
import Facebook from "../../resources/images/announcements/facebook.png";
import Instagram from "../../resources/images/announcements/instagram.png";
import Website from "../../resources/images/announcements/website.png";
import ContactsWhiteBottom from "../../resources/images/contacts/ContactsWhiteBottom.svg";
import LightTextureBg from "../../resources/images/contacts/LightTextureBg.png";
import { ReactComponent as Leaf } from "../../resources/images/contacts/flying_leaf.svg";
import { ReactComponent as Help } from "../../resources/images/contacts/help.svg";
import { ReactComponent as StorageIcon } from "../../resources/images/contacts/storage_icon.svg";
import Logo from "../../resources/logo/logo_gesba_white_2.png";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { ContactsHero, TextureBackground } from "./styles/contactos_styles";

export const LightContactTextureBg = styled.div`
  width: 100%;
  background-image: url(${LightTextureBg});
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1;
    background-image: url(${ContactsWhiteBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

function Contacts() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const form = useRef();

  const onSubmit = (data) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_CONTACT,
        "template_yekgiu7",
        data,
        process.env.REACT_APP_EMAILJS_KEY_CONTACT
      )
      .then(
        () => {
          toast("Contacto enviado");
          form.current.reset();
        },
        () => {
          toast("Erro ao enviar contacto");
        }
      );
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="dark"
      />
      <TextureBackground className="overflow-hidden">
        <div className="pt-[100px] pb-[100px] relative mx-2">
          <h2 className="text-[#1C6C5E] text-3xl lg:text-6xl font-bold mt-5 text-center">
            Apoio Técnico ao Produtor
          </h2>
          <div className="bg-white rounded flex flex-col font-prompt w-fit mx-auto mt-20">
            <div className="bg-[#E8E7E3] py-5 rounded-t border-2 border-white flex flex-grow flex-wrap sm:flex-nowrap flex-col md:flex-row-reverse">
              <div className="w-full md:w-0 relative">
                <div className="absolute flex justify-center aspect-square bg-[#1C6C5E] rounded-full p-4 items-center border-4 border-[#E8E7E3] shadow-md top-[-70px] left-1/2 -translate-x-1/2">
                  <Help />
                </div>
              </div>
              <div className="text-[#172726] mx-5 uppercase flex flex-col gap-2 items-center flex-grow text-xl px-10 mt-7 md:mt-0">
                <p className="flex justify-center font-normal text-center">
                  Estrada Regional 101 - VE3, N.º 2 LUGAR DE BAIXO
                </p>
                <p className="flex justify-center font-medium text-center">
                  9360-592 PONTA DO SOL
                </p>
              </div>
            </div>
            <div className="flex flex-grow ">
              <div className="flex gap-5 px-2 py-5 text-[#172726] border-2 border-white w-full items-center flex-col md:flex-row">
                <div className="flex gap-3 basis-1/2 text-center items-center justify-center">
                  Tel:{" "}
                  <span className="font-bold">
                    <a
                      href="tel:+351291106070;"
                      target="_blank"
                      rel="noreferrer"
                    >
                      (+351) 291 106 070
                    </a>
                  </span>
                </div>
                <div className="hidden md:block w-px bg-[#000000]" />
                <div className="flex gap-3 basis-1/2 text-center items-center justify-center">
                  Email:{" "}
                  <span className="font-bold">
                    <a
                      href="mailto:apoio.tecnico@gesba.pt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      apoio.tecnico@gesba.pt
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="text-[#172726] flex gap-3 md:gap-10 px-2 py-5 justify-center border-2 border-white border-t-[#E8E7E3] rounded-b flex-wrap md:flex-nowrap">
              <div className="flex gap-2 my-auto">
                <p className="text-base">Segunda a Sexta</p>
                <p className="font-semibold flex items-center uppercase text-base">
                  08:30h às 17:30h
                </p>
              </div>
              <div className="flex gap-2 my-auto">
                <p className="text-base">Sábado e Domingo</p>
                <p className="font-semibold flex items-center uppercase text-base">
                  Encerrado
                </p>
              </div>
            </div>
          </div>
          <Leaf
            className="absolute md:top-20 md:right-0 z-[10] rotate-[30deg] hidden md:block"
            color="#1C6C5E"
          />
          <Leaf
            className="absolute bottom-10 -left-28 z-[10] rotate-180 scale-50 hidden md:block"
            color="#1C6C5E"
          />
        </div>
      </TextureBackground>
      <ContactsHero>
        <Wrapper className={"relative"}>
          <div className="pt-[20px]">
            <h2 className="text-white text-3xl lg:text-6xl font-bold mt-5 text-center">
              Horário de Funcionamento
            </h2>
          </div>
          <div className="flex flex-col flex-wrap xl:flex-nowrap justify-center lg:flex-row gap-10 xl:gap-20 text-white mt-20 pb-10">
            <div className="flex flex-col gap-5 lg:gap-10 flex-grow w-full lg:max-w-screen-md">
              <div className="flex flex-col flex-grow">
                <Storage
                  title="Santa Rita | Madalena do Mar | Ponta de sol"
                  first={
                    <>
                      <p>Manhãs</p>
                      <span className="font-semibold flex items-center uppercase">
                        08:00h - 12:00h
                      </span>
                    </>
                  }
                  second={
                    <>
                      <p>Tardes</p>
                      <span className="font-semibold flex items-center uppercase">
                        13:00h - 17:00h
                      </span>
                    </>
                  }
                  icon={<StorageIcon leftColor="#000" rightColor="#fff" />}
                />
              </div>

              <div className="flex flex-col">
                <Reception
                  title="Receção"
                  time2={
                    <>
                      <p className="text-base">Segunda a Sexta</p>
                      <p className="font-semibold flex items-center uppercase text-base">
                        09:00h às 18:00h
                      </p>
                    </>
                  }
                  time={
                    <>
                      <p className="text-base">Sábado e Domingo</p>
                      <p className="font-semibold flex items-center uppercase text-base">
                        Encerrado
                      </p>
                    </>
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 text-[#E8E7E3] pb-10">
            <h3 className="text-2xl uppercase md:text-3xl font-semibold border-b-2 border-b-[#FFFFFF] pb-3 mb-3 text-center md:text-left">
              Contactos dos Centros
            </h3>
            <div className="flex flex-col md:flex-row gap-5 justify-between">
              <div className="flex flex-col gap-8 items-center md:items-start">
                <h3 className="text-xl md:text-2xl text-center font-medium">
                  Centro de Santa Rita
                </h3>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3">
                    <Phone />
                    <p className="hover:text-[#C5DBA4] text-base">
                      <a
                        href="tel:(+351)291701051"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (+351) 291 701 051
                      </a>
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <Phone />
                    <p className="hover:text-[#C5DBA4] text-base">
                      <a
                        href="tel:(+351)291701052"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (+351) 291 701 052
                      </a>
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <PhoneIphone />
                    <p className="hover:text-[#C5DBA4] text-base">
                      <a
                        href="tel:(+351)925421911"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (+351) 925 421 911
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:hidden h-px bg-white" />
              <div className="flex flex-col gap-8 items-center md:items-start">
                <h3 className="text-xl md:text-2xl text-center font-medium">
                  Centro Ponta de Sol
                </h3>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3">
                    <Phone />
                    <p className="hover:text-[#C5DBA4] text-base">
                      <a
                        href="tel:(+351)291100310"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (+351) 291 100 310
                      </a>
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <Phone />
                    <p className="hover:text-[#C5DBA4] text-base">
                      <a
                        href="tel:(+351)291973166"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (+351) 291 973 166
                      </a>
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <PhoneIphone />
                    <p className="hover:text-[#C5DBA4] text-base">
                      <a
                        href="tel:(+351)966164219"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (+351) 966 164 219
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:hidden h-px bg-white" />
              <div className="flex flex-col gap-8 items-center md:items-start">
                <h3 className="text-xl md:text-2xl text-center font-medium">
                  Centro Madalena do Mar
                </h3>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3">
                    <Phone />
                    <p className="hover:text-[#C5DBA4] text-base">
                      <a
                        href="tel:(+351)291972693"
                        target="_blank"
                        rel="noreferrer"
                      >
                        (+351) 291 972 693
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Leaf
            className="absolute top-20 -left-10 z-[-1] rotate-[120deg] scale-50"
            color="#FAFAFA"
          />
        </Wrapper>
      </ContactsHero>

      <LightContactTextureBg className="py-20 mx-auto">
        <Wrapper>
          <h3 className="text-6xl font-bold mb-5 text-center hidden md:block">
            Entre em contacto
          </h3>
          <div className="p-5 bg-[#FAFAFA] rounded">
            <div className="flex flex-wrap md:flex-nowrap">
              <div className="flex flex-col basis-full md:basis-2/5 gap-5 items-center lg:items-start bg-[#1C6C5E] px-5 py-5 rounded">
                <h2 className="text-white text-2xl lg:text-5xl font-semibold">
                  GESBA
                </h2>
                <div className="flex gap-5 w-full text-white flex-grow">
                  <div className="flex flex-col w-full gap-10 relative">
                    <div className="flex flex-col gap-5 flex-grow">
                      <div className="flex gap-3">
                        <LocationOn />
                        <p className="hover:text-[#C5DBA4] text-base">
                          <a
                            href={"https://goo.gl/maps/oH8mEmSoZ6DGmToc7"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Rua de Santa Rita 56, 9000-238 Funchal
                          </a>
                        </p>
                      </div>
                      <div className="flex gap-3">
                        <Phone />
                        <p className="hover:text-[#C5DBA4] text-base">
                          <a
                            href="tel:(+351)291701050"
                            target="_blank"
                            rel="noreferrer"
                          >
                            (+351) 291 701 050
                          </a>
                        </p>
                      </div>
                      <div className="flex gap-3">
                        <Fax />
                        <p className="hover:text-[#C5DBA4] text-base">
                          <a
                            href="tel:+351291701059;fax=1;"
                            target="_blank"
                            rel="noreferrer"
                          >
                            (+351) 291 701 059
                          </a>
                        </p>
                      </div>
                      <div className="flex gap-3">
                        <Mail />
                        <p className="hover:text-[#C5DBA4] text-base">
                          <a
                            href="mailto:gesba@gesba.pt"
                            target="_blank"
                            rel="noreferrer"
                          >
                            gesba@gesba.pt
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-5 mt-10 items-center md:items-start">
                      <p className="text-lg uppercase font-semibold">
                        Localização
                      </p>
                      <div className="flex flex-col gap-5 w-full">
                        <div className="flex gap-3 justify-center md:justify-start">
                          <p className="hover:text-[#C5DBA4] text-base">
                            <a
                              href={"https://goo.gl/maps/pG9R2VXSng67tumF9"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Centro de Santa Rita
                            </a>
                          </p>
                        </div>
                        <div>
                          <Map />
                        </div>
                      </div>
                    </div>
                    <div className="absolute bottom-48 right-0 opacity-50 origin-right">
                      <img src={Logo} alt="Gesba logo" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex basis-full md:basis-3/5 justify-center">
                <div className="flex flex-col md:px-10 w-full mt-3 md:mt-0">
                  <form
                    ref={form}
                    className="flex flex-col gap-4"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="flex flex-col gap-2">
                      <p className="text-base font-semibold text-[#707070]">
                        Nome<span className="text-[#ED1D24]">*</span>
                      </p>
                      <input
                        placeholder="Inserir Nome"
                        className="h-14 py-2 px-5 rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                        {...register("name", { required: "Nome obrigatório" })}
                      />
                      {errors.name && (
                        <span className="text-[#ED1D24]">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-base font-semibold text-[#707070]">
                        Telefone / Telemóvel
                      </p>
                      <div className="flex flex-row w-full">
                        <div className="flex rounded-l-[4px] bg-[#F8FAFB] border-[#EDEDED] -mr-px border-[1px] flex-col items-center justify-center px-2">
                          +351
                        </div>
                        <Controller
                          name="telephone"
                          control={control}
                          rules={{
                            minLength: {
                              value: 9,
                              message: "Número de telefone inválido",
                            },
                            maxLength: {
                              value: 9,
                              message: "Número de telefone inválido",
                            },
                            pattern: {
                              value: /^9[1236][0-9]{7}|2[1-9]{1,2}[0-9]{7}$/,
                              message: "Número de telefone inválido",
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              sx={{
                                "& fieldset": {
                                  borderColor: "#e5e7eb",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                  outline: "none",
                                  boxShadow: "0 4px 3px rgb(0 0 0 / 0.1)",
                                  "& fieldset": {
                                    border: "1px solid #07146380",
                                  },
                                },
                                flexGrow: 1,
                              }}
                              className="h-14 bg-white rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                              displayEmpty
                              onChange={onChange}
                              value={value}
                              placeholder="291701050"
                            />
                          )}
                        />
                        {errors.telephone && (
                          <span className="text-[#ED1D24]">
                            {errors.telephone.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-base font-semibold text-[#707070]">
                        Email<span className="text-[#ED1D24]">*</span>
                      </p>
                      <input
                        placeholder="utilizador@exemplo.com"
                        className="h-14 py-2 px-5 rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                        {...register("email", {
                          required: "Email obrigatório",
                          pattern: {
                            // eslint-disable-next-line
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: "Email inválido",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="text-[#ED1D24]">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-base font-semibold text-[#707070]">
                        Título<span className="text-[#ED1D24]">*</span>
                      </p>
                      <input
                        placeholder="Inserir título"
                        className="h-14 py-2 px-5 rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                        {...register("title", {
                          required: "Título é obrigatório",
                        })}
                      />
                      {errors.title && (
                        <span className="text-[#ED1D24]">
                          {errors.title.message}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-base font-semibold text-[#707070]">
                        Observações<span className="text-[#ED1D24]">*</span>
                      </p>
                      <textarea
                        rows={4}
                        placeholder="Escreva aqui"
                        className="resize-none py-2 px-5 rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                        {...register("description", {
                          required: "Obrigatório",
                        })}
                      />
                      {errors.description && (
                        <span className="text-[#ED1D24]">
                          {errors.description.message}
                        </span>
                      )}
                    </div>
                    <div className="flex">
                      <button
                        type="submit"
                        className="flex flex-grow bg-[#1C6C5E] text-white uppercase justify-center py-3 rounded"
                      >
                        Enviar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </LightContactTextureBg>

      <TextureBackground className="pb-20">
        <Wrapper className={"relative"}>
          <h2 className="text-[#172726] text-2xl lg:text-5xl font-bold pt-[100px] flex justify-center">
            Visite as nossas redes
          </h2>
          <div className="flex flex-col justify-around gap-5 mt-10 md:flex-row items-center md:items-start relative z-[2] pb-10">
            <div className="flex flex-col items-center gap-5 mt-auto">
              <p className="uppercase font-semibold">banana da madeira</p>
              <div className="flex gap-5">
                <a
                  href={"https://www.facebook.com/bananadamadeira"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Facebook}
                    alt="banana da madeira facebook"
                    className="w-[80px] h-[80px]"
                  />
                </a>
                <a
                  href={"https://www.instagram.com/banana__da__madeira/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Instagram}
                    alt="banana da madeira instagram"
                    className="w-[80px] h-[80px]"
                  />
                </a>
              </div>
            </div>

            <div className="flex flex-col items-center gap-5 mt-auto">
              <p className="uppercase font-semibold">GESBA</p>
              <div className="flex flex-row gap-5">
                <a
                  href={"https://www.facebook.com/gesba.lda"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Facebook}
                    alt="facebook gesba"
                    className="w-[80px] h-[80px]"
                  />
                </a>
                <a
                  href={"https://www.instagram.com/gesba_lda/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Instagram}
                    alt="facebook instagram"
                    className="w-[80px] h-[80px]"
                  />
                </a>
              </div>
            </div>
            <div className="flex flex-col items-center gap-5 mt-auto">
              <p className="uppercase font-semibold text-center">
                bam <br />
                Centro da bANANA DA MADEIRA
              </p>
              <div className="flex gap-5">
                <a
                  href={"https://www.facebook.com/centrodabananadamadeira"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Facebook}
                    alt="bam facebook"
                    className="w-[80px] h-[80px]"
                  />
                </a>
                <a
                  href={"https://www.instagram.com/bamcentrodabananadamadeira/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Instagram}
                    alt="bam instagram"
                    className="w-[80px] h-[80px]"
                  />
                </a>
                <a
                  href={"https://bam-centrodabananadamadeira.pt/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Website}
                    alt="bam website"
                    className="w-[80px] h-[80px]"
                  />
                </a>
              </div>
            </div>
          </div>
          <Leaf
            className="absolute top-10 -right-10 z-0 rotate-45 scale-50 lg:scale-[60%]"
            color="#C5DBA4"
          />
          <Leaf
            className="absolute -bottom-40 -left-10 z-0 scale-75"
            color="#C5DBA4"
          />
        </Wrapper>
      </TextureBackground>
    </>
  );
}

export default Contacts;
