import styled from "styled-components";
import TextureBG from "../../../resources/images/home/bg_texture.png";
import PaintBottom from "../../../resources/images/home/divider_green_bottom.svg";
import PaintTop from "../../../resources/images/home/divider_green_top.svg";
import HeroBg from "../../../resources/images/home/hero_img.png";
import Divider from "../../../resources/images/home/page-divider.png";
export const HeroArea = styled("div")`
  width: 100%;
  /* height: 105vh; */
  max-height: 1080px;
  background-image: url(${HeroBg});
  background-size: cover;
`;
export const HeroCaption = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* position: relative; */
  /* translate: 0 -50%; */
`;
export const AlertCarousel = styled("div")`
  display: flex;
  scroll-snap-type: x mandatory;
  scroll-padding: 50%;
  max-width: calc(100% - 1.5rem);
  overflow-x: auto;

  @media (min-width: 1024px) {
    overflow-x: hidden;
  }
`;
export const StatsSection = styled("div")`
  position: relative;
  z-index: 1;
  width: 100%;
  background-image: url(${TextureBG});

  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: -50px;
    content: "";
    display: block;
    background-image: url(${Divider});
    background-size: auto;
    @media (min-width: 1480px) {
      background-size: cover;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    background-image: url(${PaintBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const TextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    background-image: url(${PaintBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    transform: rotate(180deg);
    background-image: url(${PaintTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const SimpleTextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;
`;

export const PartnersAction = styled("div")`
  background-color: #fafafa;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    transform: rotate(180deg);
    background-image: url(${PaintTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const CalltoAction = styled("div")`
  width: 100%;
  background-color: #1c6c5e;
  position: relative;
  /* height: 30vh; */
  max-height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  overflow-x: hidden;
  /* & img {
    position: absolute;
    width: 50%;
    right: 0;
  } */
  @media (min-width: 1024px) {
    height: 70vh;
  }
  /* @media (min-width: 1280px) {
    & img {
      width: unset;
    }
  } */

  /* &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    transform: rotate(180deg);
    background-image: url(${PaintBottom});
    background-size: cover;
    background-repeat: no-repeat;
  } */
`;
