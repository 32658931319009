import moment from "moment";
import React from "react";
import "moment/locale/pt";

function PaymentCard({ data, index }) {
  let start = moment(data?.start_date);
  let end = moment(data?.end_date);
  start.locale("pt");
  end.locale("pt");
  let diffInMonths = start.diff(end, "months");
  let diffInYears = start.diff(end, "years");

  return (
    <div className="flex flex-col rounded bg-white basis-full md:basis-[calc(50%_-_20px)]">
      <div
        className={`flex justify-between w-full ${
          index % 2 === 0 ? "bg-[#C5DBA4]" : "bg-[#F2BB36]"
        } p-5`}
      >
        <p className="uppercase">{data?.month}</p>
        {Math.abs(diffInYears) > 0 ? (
          <>
            <p>
              <span className="text-[#1C6C5E]">Dias</span>{" "}
              {start.format("D MMM YYYY")} a {end.format("D MMM YYYY")}
            </p>
          </>
        ) : Math.abs(diffInMonths) > 0 ? (
          <>
            <p>
              <span className="text-[#1C6C5E]">Dias</span>{" "}
              {start.format("D MMM")} a {end.format("D MMM")}
            </p>
          </>
        ) : (
          <>
            <p>
              <span className="text-[#1C6C5E]">Dias</span> {start.format("D")} a{" "}
              {end.format("D MMM")}
            </p>
          </>
        )}
      </div>
      <div className="pb-2 pt-5 flex flex-wrap p-5 gap-5 justify-between">
        <div className="flex gap-3 items-center">
          <p className="uppercase text-base font-semibold">Data de Pagamento</p>
          <p className="uppercase text-base font-semibold">
            {moment(data?.payment_date).format("D-MMM YYYY")}
          </p>
        </div>
        <div className="flex gap-3 items-center">
          <p className="uppercase text-base font-semibold">Data de fatura</p>
          <p className="uppercase text-base font-semibold">
            {moment(data?.invoice_date).format("D MMM")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PaymentCard;
