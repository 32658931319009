import styled from "styled-components";
import GreenBottom from "../../../resources/images/home/divider_green_bottom.svg";
import TextureBG from "../../../resources/images/home/bg_texture.png";
import DarkTextureBG from "../../../resources/images/home/bg_dark_texture.png";
import TextureTop from "../../../resources/images/about/TextureTop.png";
import TextureBottom from "../../../resources/images/about/TextureBottom.png";
export const ContactsHero = styled("div")`
  width: 100%;
  min-height: 50vh;
  position: relative;
  background-color: #1c6c5e;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    height: 150px;
    width: 100%;
    bottom: -50px;
    left: 0;
    background-image: url(${GreenBottom});
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    height: 150px;
    width: 100%;
    top: -50px;
    left: 0;
    background-image: url(${GreenBottom});
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    z-index: -1;
  }
`;

export const SecondaryTextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    background-image: url(${TextureTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const TextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;

  /* &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1;
    transform: rotate(180deg);
    background-image: url(${TextureTop});
    background-size: cover;
    background-repeat: no-repeat;
  } */
`;

export const DarkTextureBackground = styled("div")`
  width: 100%;
  background-image: url(${DarkTextureBG});
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1;
    transform: rotate(180deg);
    background-image: url(${TextureTop});
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::after {
    content: ${(props) => !props.noAfter && ""};
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1;
    background-image: url(${TextureBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
