import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../../providers/axiosConfig";
import Wrapper from "../../../resources/components/general/Wrapper";
import {
  ProdHero,
  TextureBackground,
  TopDivider,
  VideoCard,
} from "../styles/producers_styles";
import { CircularProgress } from "@mui/material";

function Media() {
  const navigate = useNavigate();
  const [promos, setPromos] = useState();
  // const [tutorials, setTutorials] = useState();
  const [loading, setLoading] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    const target = document.querySelector(e.target.getAttribute("href"));
    target.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/multimedias`)
      .then((res) => {
        let alldata = res.data;
        let promoData = alldata.filter(
          (item) => item.multimedia_category === "Vídeos promocionais"
        );
        // let tutorialData = alldata.filter(
        //   (item) => item.multimedia_category === "Tutoriais"
        // );
        setPromos(promoData);
        setLoading(false);
        // setTutorials(tutorialData);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <>
      <ProdHero>
        <Wrapper>
          <div className="relative min-h-[70vh]">
            <h1 className="text-5xl text-white font-bold absolute top-[50%] -translate-y-[-50%]">
              Multimédia
            </h1>
            <div className="flex  w-full gap-5 text-white absolute bottom-16 underline">
              {/* <p>TUTORIAIS</p> */}
              <a href="#promos" onClick={handleClick}>
                VÍDEOS PROMOCIONAIS
              </a>
            </div>
          </div>
        </Wrapper>
      </ProdHero>
      <TopDivider>
        <TextureBackground className="py-20" id="promos">
          <Wrapper>
            <h2 className="text-4xl font-semibold uppercase py-5">
              Vídeos Promocionais
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 gap-y-6">
              {loading ? (
                <CircularProgress
                  className="mx-auto"
                  sx={{ color: "#1C6C5E" }}
                />
              ) : promos && promos.length > 0 ? (
                promos.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(`/producers/media/show/${item.id}`, {
                          state: { item: item, promos: promos },
                        });
                      }}
                    >
                      <VideoCard src={item.multimedia_url} item={item} />
                    </div>
                  );
                })
              ) : (
                <>
                  <h3 className="text-2xl font-semibold uppercase py-5 opacity-90">
                    Sem vídeos promocionais de momento
                  </h3>
                </>
              )}
            </div>
            {/* <div className="flex w-full flex-row-reverse py-10">
              <VideoPagination count={5} />
            </div> */}
          </Wrapper>
        </TextureBackground>
      </TopDivider>
    </>
  );
}

export default Media;
