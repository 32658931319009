import React from "react";
import Wrapper from "../../../resources/components/general/Wrapper";
import HeroBg from "../../../resources/images/production/products_production.png";
import {
  GreenTextureBackground,
  ProductSection,
  SubPagesHero,
  TextureBackground,
} from "../styles/production_styles";

import ProdImg from "../../../resources/images/production/prod_int.png";
import ProdImg2 from "../../../resources/images/production/prod_bio.png";
import ProdImg3 from "../../../resources/images/production/prod_conv.png";
import ProdImg4 from "../../../resources/images/production/prod_gap.png";
import ProdImg5 from "../../../resources/images/production/prod_anona.png";
import ProdImg6 from "../../../resources/images/production/selo_anona.png";
import ProdImg7 from "../../../resources/images/production/prod_abacate.png";
function Products() {
  return (
    <>
      <TextureBackground>
        <SubPagesHero herobg={HeroBg} secondary>
          <Wrapper closeFull>
            <div className="relative min-h-[60vh] flex flex-col justify-center items-center ">
              <h1
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
                className="text-4xl md:text-6xl text-white font-bold text-center z-[1] leading-[66px] uppercase"
              >
                Produtos
              </h1>
            </div>
          </Wrapper>
        </SubPagesHero>
      </TextureBackground>

      <GreenTextureBackground className="py-20 relative z-[1]">
        <ProductSection>
          <Wrapper>
            <h2 className="text-[#172327] text-2xl font-semibold">
              PRODUTOS DISPONÍVEIS
            </h2>
            <h3 className="text-[#172327] text-xl uppercase font-medium mt-5">
              PRODUÇÃO DE BANANA
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 items-center justify-center md:justify-around pt-10">
              <div className="flex flex-col items-center">
                <img src={ProdImg} alt="" />
                <p className="uppercase text-xl text-[#172327] leading-8 font-bold">
                  Produção integrada
                </p>
              </div>
              <div className="flex flex-col items-center">
                <img src={ProdImg2} alt="" />
                <p className="uppercase text-xl text-[#172327] leading-8 font-bold">
                  Biológica
                </p>
              </div>
              <div className="flex flex-col items-center">
                <img src={ProdImg3} alt="" />
                <p className="uppercase text-xl text-[#172327] leading-8 font-bold">
                  Conversão
                </p>
              </div>
              <div className="flex flex-col items-center">
                <img src={ProdImg4} alt="" />
                <p className="uppercase text-xl text-[#172327] leading-8 font-bold">
                  Global G.A.P
                </p>
              </div>
            </div>
            <h3 className="text-[#172327] text-xl uppercase my-10 font-medium">
              PRODUÇÃO Diversificada
            </h3>
            <div className="flex flex-wrap gap-5 md:gap-20 items-start justify-center">
              <div className="flex flex-col items-center justify-start max-w-[250px] h-full">
                <img src={ProdImg7} alt="" className="h-[250px]" />
                <p className="uppercase font-bold ">Pera abacate</p>
                {/* <span className="text-center mt-3 text-[#707070]"></span> */}
              </div>
              <div className="flex flex-col items-center justify-start max-w-[250px] h-full">
                <div className="relative">
                  <img src={ProdImg5} alt="" className="h-[250px]" />
                  <img
                    src={ProdImg6}
                    alt=""
                    className="absolute top-[-20px] right-[-20px] scale-75 origin-top-right"
                  />
                </div>

                <p className="uppercase font-bold ">Anona da Madeira</p>
                <span className="text-center mt-3 text-[#707070]">
                  Fruta tropical exótica com D.O.P - Denominação de Origem
                  Protegida
                </span>
              </div>
            </div>
          </Wrapper>
        </ProductSection>
      </GreenTextureBackground>
    </>
  );
}
export default Products;
