import React from "react";
import styled from "styled-components";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import FooterBG from "../../images/home/footer_bg.svg";
import { ReactComponent as Dev } from "../../logo/developed.svg";
import { ReactComponent as Logo } from "../../logo/logo_gesba_full.svg";
import Wrapper from "./Wrapper";
import { Link } from "react-router-dom";
import moment from "moment";

const FooterWrapper = styled("div")`
  width: 100%;
  position: relative;
  background-color: #ffffff03;
  backdrop-filter: blur(5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  min-height: 20vh;
  z-index: 10;
  background-color: #172726;
  padding: 20px 0;

  &::before {
    background-image: url(${FooterBG});
    background-size: 140vw;
    background-position-x: center;
    background-repeat: no-repeat;
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    top: -30px;
    left: 0;
    position: absolute;
    z-index: -1;
  }
`;
function Footer() {
  return (
    <FooterWrapper>
      <Wrapper>
        <div className="flex flex-col h-full w-full justify-between gap-5">
          <div className="flex flex-col items-center md:flex-row first-letter:w-full justify-between">
            <Logo />
            <div className="flex-col gap-5 hidden md:flex">
              <div className=" hidden md:flex items-center gap-3 text-white justify-end">
                <a
                  href={"https://www.facebook.com/gesba.lda"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href={"https://www.instagram.com/gesba_lda/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </a>
                {/* <LinkedInIcon />
                <YouTubeIcon /> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full items-center justify-between text-white mt-auto">
            <div className="flex flex-col md:flex-row items-center gap-10 w-full justify-center">
              <div className="basis-4/5 flex justify-end flex-wrap flex-col lg:flex-row lg:flex-nowrap gap-5 lg:gap-10 text-center">
                <Link to="/terms" className="flex justify-center">
                  Termos de Serviço
                </Link>
                <div className="w-px bg-white hidden md:block" />
                <a
                  href="https://www.livroreclamacoes.pt/Inicio/"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className="flex justify-center"
                >
                  Elogios, Sugestões e Reclamações
                </a>
                <div className="w-px bg-white hidden md:block" />
                <Link to="/privacy" className="flex justify-center">
                  Política de Privacidade
                </Link>
                {/* TODO: DESCOMENTAR QUANDO ACEITE */}
                {/* <div className="w-px bg-white hidden md:block" /> */}
                {/* <Link to="/reporting-channel" className="flex justify-center">
                  Canal de Denúncias
                </Link> */}
              </div>
              <div className="basis-1/5 flex items-end lg:items-center justify-end">
                <a
                  href={process.env.REACT_APP_ADMIN_URL}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className="underline"
                >
                  Área Reservada
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-5 md:hidden">
              <div className=" flex items-center gap-3 text-white my-5 md:my-0 md:hidden">
                <a
                  href={"https://www.facebook.com/gesba.lda"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href={"https://www.instagram.com/gesba_lda/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </a>

                {/* <LinkedInIcon />
                <YouTubeIcon /> */}
              </div>
              {/* <a
                href={"https://www.vipaconnect.com"}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer"
              >
                <Dev />
              </a> */}
            </div>
          </div>
          <hr />
          <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-end">
            <div className="basis-full md:basis-3/5 flex justify-center md:justify-end">
              <p className="text-white text-center text-sm">
                © {moment().format("YYYY")} GESBA. Todos os Direitos Reservados
              </p>
            </div>
            <div className="basis-full md:basis-2/5 flex justify-center md:justify-end mt-2 md:mt-0">
              <span className="flex items-center text-white gap-5 text-sm">
                Desenvolvido por
                <a
                  href={"https://www.vipa.pt"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer max-w-[120px]"
                >
                  <Dev className="w-full" />
                </a>
              </span>
            </div>
          </div>
        </div>
      </Wrapper>
    </FooterWrapper>
  );
}

export default Footer;
