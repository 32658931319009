import styled from "@emotion/styled";
import React, { useState } from "react";
import NoSelect from "../../resources/images/production/no_selected_extra.png";
import SelectExtra from "../../resources/images/production/selected_extra.png";

const PriceTable = styled("table")`
  tr:last-child {
    td:nth-of-type(2) {
      border-bottom-left-radius: 5px;
    }
    td:last-child {
      border-bottom-right-radius: 5px;
    }
  }
`;

const HorizontalTableHeader = styled("th")`
  background-color: #fafafa;
  border-bottom: 1px solid #e8e7e3;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;

  :nth-of-type(2) {
    border-top-left-radius: 5px;
    /* box-shadow: 0px 5.44974px 24.5238px rgba(0, 0, 0, 0.1); */
  }

  :last-child {
    border-top-right-radius: 5px;
  }
  :nth-child(2),
  :nth-child(3) {
    border-right: 1px dashed #e8e7e3;
    box-shadow: 0px 5.44974px 24.5238px rgba(0, 0, 0, 0.1);
  }
`;

const VerticalTableHeader = styled("div")`
  color: #fff;
  height: 60px;
  /* box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.05); */
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const ValuePill = styled("div")`
  background-color: ${(props) => props.bgColor};
  width: 90px;
  height: 32px;
  padding: 4px;
  text-align: center;
  border-radius: 8px;
  color: #172327;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableData = styled("td")`
  background-color: #fafafa;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 0rem;

  :nth-child(3),
  :nth-child(4) {
    border-left: 1px dashed #e8e7e3;
  }
`;

function PriceTableList({ record, extras, index, reversed, season = "" }) {
  const [additions, setAdditions] = useState([]);

  const categoriesColors = [
    "bg-[#CD4547]",
    "bg-[#3C6D52]",
    "bg-[#26368C]",
    "bg-[#B1B1B1]",
  ];

  let parsedValues = extras;

  // let globalGap = additions.findIndex(
  //   (element) => element.name === "Referencial Global Gap"
  // );

  // let tempAdditions = additions.filter((element, index) => index !== globalGap);

  return (
    <div
      className={`flex flex-col mt-5 lg:flex-row lg:gap-10 ${
        reversed && "lg:flex-row-reverse"
      }`}
    >
      <div>
        <div className="flex justify-center">
          <PriceTable>
            <thead>
              <tr>
                <th></th>
                <th
                  colSpan={3}
                  className={`${
                    season === "summer" ? "bg-[#F2BB36]" : "bg-[#027C6E]"
                  } shadow-md rounded-t translate-y-1 relative z-[0]`}
                >
                  <h2
                    className={`2xl uppercase ${
                      season === "summer" ? "text-[#172327]" : "text-white"
                    }  text-center font-medium py-3 rounded`}
                  >
                    {record?.name}
                  </h2>
                </th>
              </tr>
              <tr>
                <th></th>
                {record?.columns?.map((column, columnIndex) => {
                  return (
                    <HorizontalTableHeader
                      key={columnIndex}
                      className={
                        "w-[100px] md:w-[150px] md:whitespace-nowrap p-2 md:p-5 relative z-[1] shadow-lg"
                      }
                    >
                      {column}
                    </HorizontalTableHeader>
                  );
                })}
              </tr>
            </thead>

            <tbody className="z-[-1] relative">
              {record?.rows?.map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    <th className="py-0 ">
                      <div className="bg-[#FAFAFA] p-px relative z-2">
                        <VerticalTableHeader
                          key={rowIndex}
                          className={`hidden md:flex md:flex-col p-2 items-center justify-center ${categoriesColors[rowIndex]}`}
                        >
                          <span className="text-sm text-center">{row}</span>
                        </VerticalTableHeader>
                        <VerticalTableHeader
                          key={rowIndex}
                          className={`md:hidden px-2 flex border-[#FAFAFA] border-4 ${categoriesColors[rowIndex]}`}
                        >
                          {rowIndex === 0
                            ? "A"
                            : rowIndex === 1
                            ? "B"
                            : rowIndex === 2
                            ? "C"
                            : "D"}
                        </VerticalTableHeader>
                      </div>
                    </th>

                    {record?.values[rowIndex]?.map((val, valIndex) => {
                      return (
                        <TableData
                          className="w-[100px] md:w-[150px]"
                          key={rowIndex + "-" + valIndex}
                        >
                          <ValuePill className="mx-auto" bgColor={"#E8E7E3"}>
                            <span>
                              {val === "0"
                                ? " - "
                                : additions
                                ? (
                                    Math.round(
                                      (parseFloat(val) +
                                        parseFloat(
                                          additions
                                            .map((el) => parseFloat(el.value))
                                            .reduce(
                                              (acc, curr) => acc + curr,
                                              0
                                            )
                                        ) +
                                        Number.EPSILON) *
                                        1000
                                    ) / 1000
                                  )
                                    .toLocaleString("pt", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 3,
                                    })
                                    .replace(/\.(\d)$/, ".00$1")
                                    .replace(/(\.[0-9]{2})[0-9]*$/, "$1")
                                    .replace(",", ".")
                                : val
                                    .toLocaleString("pt", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 3,
                                    })
                                    .replace(/\.(\d)$/, ".00$1")
                                    .replace(/(\.[0-9]{2})[0-9]*$/, "$1")
                                    .replace(",", ".")}{" "}
                            </span>
                            <span>€/Kg</span>
                          </ValuePill>
                        </TableData>
                      );
                    })}
                    <th className="py-0 ">
                      <div className="bg-[#FAFAFA] relative z-2">
                        <VerticalTableHeader
                          key={rowIndex}
                          className={`flex md:flex-col -mr-3 items-center justify-center ${categoriesColors[rowIndex]}`}
                        ></VerticalTableHeader>
                      </div>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </PriceTable>
        </div>

        <div className="flex flex-wrap gap-5 md:hidden m-4 lg:text-lg font-medium">
          {record?.rows?.map((row, rowIndex) => {
            return (
              <>
                <p className="text-base basis-1/3">
                  <span className="font-semibold">
                    {rowIndex === 0
                      ? "A"
                      : rowIndex === 1
                      ? "B"
                      : rowIndex === 2
                      ? "C"
                      : "D"}
                  </span>
                  - {row}
                </p>
              </>
            );
          })}
        </div>
      </div>

      {record?.price_table_extra && (
        <div className="flex flex-col my-4 mx-4 basis-full lg:basis-5/12">
          <h3 className="uppercase font-medium">Acréscimos à produção</h3>
          <div className="flex flex-col gap-5 justify-start mt-3">
            {parsedValues &&
              parsedValues.length > 0 &&
              parsedValues.map((item, index) => {
                let isSelected =
                  additions.filter(
                    (el) => el.name === item.name && el.value === item.value
                  ).length > 0;

                return (
                  <div
                    className="bg-[#FAFAFA] w-full rounded px-3 py-2 shadow border-2 border-white flex relative cursor-pointer max-w-xs"
                    onClick={() => {
                      if (isSelected) {
                        setAdditions(
                          additions.filter(
                            (elem) =>
                              elem.name !== item.name &&
                              elem.value !== item.value
                          )
                        );
                      } else {
                        if (item.name === "Entrega na estrada") {
                          let tempAdditions = [...additions, item];

                          setAdditions(
                            tempAdditions.filter(
                              (elem) => elem.name !== "Entrega no armazém"
                            )
                          );
                        } else if (item.name === "Entrega no armazém") {
                          let tempAdditions = [...additions, item];

                          setAdditions(
                            tempAdditions.filter(
                              (elem) => elem.name !== "Entrega na estrada"
                            )
                          );
                        } else {
                          setAdditions([...additions, item]);
                        }
                      }
                    }}
                  >
                    <img
                      src={isSelected ? SelectExtra : NoSelect}
                      alt=""
                      width={"60px"}
                      height={"60px"}
                      className={`absolute  ${
                        index % 2 === 0
                          ? "left-[-20px] top-[-10px]"
                          : "right-[-20px] md:left-[-20px] top-[-10px]"
                      }`}
                    />
                    <p
                      className={`font-semibold text-sm ${
                        index % 2 === 0 ? "ml-10" : "mr-10 md:ml-10"
                      }`}
                      key={index}
                    >
                      <span>{item.name}</span>{" "}
                      <span>
                        {(
                          Math.round(
                            (parseFloat(item.value) + Number.EPSILON) * 1000
                          ) / 1000
                        )
                          .toLocaleString("pt-PT", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 3,
                          })
                          .replace(/\.(\d)$/, ".00$1")
                          .replace(/(\.[0-9]{2})[0-9]*$/, "$1")}
                      </span>{" "}
                      € /Kg
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}

export default PriceTableList;
