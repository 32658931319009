import React, { useState, useEffect } from "react";
import Loading from "./resources/components/general/Loading";
import App from "./App"; 

const Main = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.getElementById("loadingVideo").addEventListener(
      "ended",
      () => {
        setLoading(false);
        document.body.style.overflow = "auto";
      },
      false
    );
  }, []);

  return (
    <>
      {loading && <Loading />}
      <App />
    </>
  );
};

export default Main;
