import React from "react";
import Wrapper from "../../../resources/components/general/Wrapper";
import { ReactComponent as Leaf } from "../../../resources/images/contacts/flying_leaf.svg";
import Capacity from "../../../resources/images/producers/capacity_supply.png";
import LeafSticker from "../../../resources/images/producers/LeafSticker.png";
import HeroBg from "../../../resources/images/producers/nurseriesBG.png";
import NurseriesPlants1 from "../../../resources/images/producers/nurseriesPlants1.png";
import NurseriesPlants2 from "../../../resources/images/producers/nurseriesPlants2.png";
import NurseriesPlants3 from "../../../resources/images/producers/nurseriesPlants3.png";
import Area from "../../../resources/images/producers/supply_area.png";
import {
  GreenSection,
  SubPagesHero,
  TextureBackground,
} from "../styles/producers_styles";

function Nurseries() {
  return (
    <>
      <TextureBackground>
        <SubPagesHero herobg={HeroBg}>
          <Wrapper closeFull>
            <div className="relative min-h-[60vh] flex flex-col justify-center items-center">
              <h1 className="text-4xl lg:text-5xl text-white font-bold text-center z-[1] leading-[66px]">
                Viveiros
              </h1>
            </div>
          </Wrapper>
        </SubPagesHero>
      </TextureBackground>
      <TextureBackground className="py-20">
        <Wrapper closeFull>
          <div className="flex flex-col md:flex-row items-start relative">
            <Leaf className="absolute -top-20 right-0 -z-[1]" color="#C5DBA4" />
            <Leaf
              className="absolute top-20 -left-40 -z-[1] rotate-[-135deg]"
              color="#C5DBA4"
            />
            <div className="basis-full flex flex-col items-center gap-5 md:gap-10">
              <h2 className="text-3xl text-[#172726] font-semibold text-center uppercase mb-3 w-full md:w-1/2 mx-auto">
                Plantas de bananeira
              </h2>
              <div className="flex flex-col lg:flex-row lg:flex-nowrap items-center relative z-[1] gap-20">
                <div className="basis-full md:basis-3/4 flex flex-col gap-3">
                  <p>
                    A GESBA, no BAM – Centro da Banana da Madeira, fornece
                    plantas de bananeira aos agricultores, de forma a permitir a
                    realização de novas plantações e renovação de bananais
                    existentes, com variedades que possuem melhores
                    características produtivas.
                  </p>
                </div>
                <div className="relative">
                  <img src={NurseriesPlants1} alt="" />
                  <img
                    src={LeafSticker}
                    alt=""
                    className="absolute -bottom-20 -left-20 scale-50"
                  />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row-reverse lg:flex-nowrap items-center relative z-[1] gap-20">
                <div className="basis-full md:basis-3/4 flex flex-col gap-3">
                  <p>
                    As plântulas de bananeiras produzidas em laboratório,
                    através do processo de micropropagação in vitro, apresentam
                    como principais vantagens a uniformidade de tamanho das
                    plantas e o facto de serem clones de bananeiras selecionadas
                    por possuírem melhores características produtivas, além de
                    estarem isentas de pragas e doenças.
                  </p>
                </div>
                <div className="relative">
                  <img src={NurseriesPlants2} alt="" />
                  <img
                    src={LeafSticker}
                    alt=""
                    className="absolute -bottom-20 -left-20 scale-50 -scale-x-50"
                  />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row lg:flex-nowrap items-center relative z-[1] gap-20">
                <div className="basis-full md:basis-3/4 flex flex-col gap-3">
                  <p>
                    Os viveiros de desenvolvimento recebem as plântulas e,
                    durante cerca de 4 meses, em condições próprias de
                    temperatura, humidade e radiação solar, procede-se ao seu
                    enraizamento e crescimento, até atingirem cerca de 50 cm de
                    altura e 7 a 9 folhas funcionais, passando depois para as
                    estufas de aclimatação, onde concluem o processo de
                    crescimento e fortificação das plantas para o posterior
                    entrega aos produtores.
                  </p>
                </div>
                <div className="relative">
                  <img src={NurseriesPlants3} alt="" />
                  <img
                    src={LeafSticker}
                    alt=""
                    className="absolute -bottom-20 -left-20 scale-50 "
                  />
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </TextureBackground>
      <GreenSection>
        <Wrapper closeFull>
          <div className="flex justify-center relative z-[1] gap-20 py-10 flex-wrap md:flex-nowrap">
            <div className="flex flex-col bg-white rounded border-[1px] border-white">
              <div className="bg-[#E8E7E3] pt-20 pb-5 px-10">
                <img
                  src={Capacity}
                  alt=""
                  className="-mt-40 scale-75 flex justify-center mx-auto"
                />
                <p className="uppercase font-semibold text-center">
                  Capacidade de fornecimento
                </p>
              </div>
              <div className="py-5 px-10">
                <p className="uppercase font-semibold text-center">
                  40 000 bananeiras/ano
                </p>
              </div>
            </div>
            <div className="flex flex-col bg-white rounded border-[1px] border-white">
              <div className="bg-[#1C6C5E] pt-20 pb-5 px-10">
                <img
                  src={Area}
                  alt=""
                  className="-mt-40 scale-75 flex justify-center mx-auto"
                />
                <p className="uppercase font-semibold text-center text-white">
                  área de produção de plantas (viveiros)
                </p>
              </div>
              <div className="py-5 px-10">
                <p className="uppercase font-semibold text-center">1100 M2</p>
              </div>
            </div>
            {/* <img src={InsectImg} alt="" />
            <BeetleLeaF className="absolute bottom-10 right-1/6 hidden lg:block z-[-1]" /> */}
          </div>
        </Wrapper>
      </GreenSection>
    </>
  );
}
export default Nurseries;
