import React from "react";

function Reception({ title, time2, time }) {
  return (
    <div className="bg-[#C5DBA4] rounded ">
      <div className="font-prompt bg-[#1C6C5E] px-5 py-2 flex justify-center border-2 border-white rounded-t">
        <p className="font-semibold text-lg">{title}</p>
      </div>
      <div className="text-[#172726] flex gap-3 md:gap-10 p-2 justify-center border-2 border-white border-t-0 rounded-b flex-wrap md:flex-nowrap">
        <div className="flex gap-2 my-auto">{time2}</div>
        <div className="flex gap-2 my-auto">{time}</div>
      </div>
    </div>
  );
}

export default Reception;
