import React, { useEffect, useState } from "react";
import Wrapper from "../../resources/components/general/Wrapper";
import { SimpleTextureBackground } from "../home/styles/home_styles";
import { DocumentHero } from "./styles/documents_style";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosConfig from "../../providers/axiosConfig";
import moment from "moment";
import "moment/locale/pt";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";

const DocumentList = ({ arr }) => {
  const [expanded, setExpanded] = useState();

  if (Array.isArray(arr)) {
    return arr.map((document, index) => {
      let submission_date = moment(document.submission_date);
      submission_date.locale("pt");
      let date = moment(document?.submission_date);
      date.locale("pt");
      let documentDate = date.format("YYYY");
      let documentName = document?.name;
      let documentNameTruncated =
        documentName?.length > 80
          ? documentName.slice(0, 80) + "..."
          : documentName;
      return (
        <div className="border-b py-4" key={index}>
          <div className="flex items-center justify-between">
            <div className="basis-1/5">
              <a
                href={document.doc_url}
                target="_blank"
                rel="noopener noreferrer"
                className="px-5 py-2 bg-[#E8E7E3] rounded text-[#707070] font-semibold cursor-pointer"
              >
                {documentDate}
              </a>
            </div>
            <div className="basis-3/5 ml-5 md:ml-0">
              <a
                href={document.doc_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#17232780] text-start cursor-pointer"
              >
                {documentNameTruncated}
              </a>
            </div>
            <div className="basis-1/5 flex justify-end">
              <a
                href={document.doc_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#0267B6] font-bold"
              >
                <FileDownloadIcon />
              </a>
            </div>
          </div>
        </div>
      );
    });
  } else {
    return Object.entries(arr)
      .filter(([key, value]) => Array.isArray(value))
      .map(([key, value], index) => {
        return (
          <Accordion
            id={`category-${index}`}
            key={index}
            expanded={expanded === key}
            onChange={() => {
              if (expanded === key) setExpanded(null);
              else setExpanded(key);

              setTimeout(() => {
                let el = document.getElementById(`category-${index}`);

                window.scrollTo({
                  top: el.offsetTop,
                  behavior: "smooth",
                });
              }, 100);

              setTimeout(() => {
                let el = document.getElementById(`category-${index}`);

                window.scrollTo({
                  top: el.offsetTop,
                  behavior: "smooth",
                });
              }, 250);
            }}
            sx={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <AccordionSummary
              sx={{
                "& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded":
                  { margin: 0 },
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <h2 className="uppercase text-[22px] text-[#707070]">{key}</h2>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                margin: 0,
                padding: 0,
              }}
            >
              <DocumentList arr={value} />
            </AccordionDetails>
          </Accordion>
        );
      });
  }
};

function Documents() {
  function handleClick(e) {
    e.preventDefault();
    const target = document.querySelector(e.target.getAttribute("href"));
    target.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  const [documents, setDocuments] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/documentation`)
      .then((res) => {
        setDocuments(res.data?.documents);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <>
      <DocumentHero className="min-h-[30vh] md:min-h-[40vh]">
        <Wrapper>
          <div className="pt-[100px] flex flex-col">
            <h3 className="text-4xl md:text-6xl text-white font-semibold">
              Documentação
            </h3>
            <div className="hidden md:grid md:grid-cols-3 lg:flex lg:flex-wrap gap-5 lg:gap-10 justify-center mt-20 items-center text-center">
              {loading ? (
                <CircularProgress className="mx-auto" sx={{ color: "#FFF" }} />
              ) : (
                documents &&
                Object.entries(documents).map((document, index) => {
                  return (
                    <a
                      href={`#${document[0]
                        ?.replace(/[^\w\s]/gi, "")
                        .replace(/\s+/g, "")}`}
                      className="uppercase text-white"
                      onClick={handleClick}
                      key={index}
                    >
                      {document[0]}
                    </a>
                  );
                })
              )}
            </div>
          </div>
        </Wrapper>
      </DocumentHero>
      <SimpleTextureBackground className="py-20 min-h-[40vh]">
        <Wrapper>
          <div className="flex flex-col gap-5">
            {loading ? (
              <CircularProgress className="mx-auto" sx={{ color: "#1C6C5E" }} />
            ) : documents ? (
              Object.entries(documents).map(([key, value], index) => {
                return (
                  <div
                    id={key?.replace(/[^\w\s]/gi, "").replace(/\s+/g, "")}
                    className="gap-5 flex flex-col pt-10"
                    key={index}
                  >
                    <h2 className="uppercase text-3xl text-[#707070] font-semibold">
                      {key}
                    </h2>
                    {value ? (
                      <DocumentList arr={value} />
                    ) : (
                      <h3 className="uppercase text-xl text-[#707070] font-medium">
                        Sem documentos disponíveis nesta categoria
                      </h3>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="my-10">
                <h3 className="text-2xl xl:text-4xl font-semibold text-center">
                  Brevemente disponível toda a documentação
                </h3>
              </div>
            )}
          </div>
        </Wrapper>
      </SimpleTextureBackground>
    </>
  );
}

export default Documents;
