import { useEffect, useState } from "react";
import Banana from "../../images/home/banana_stained.png";
const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;

    if (scrollTop > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = (e) => {
    document.getElementById("banana").classList.add("full-rotate");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      document.getElementById("banana").classList.remove("full-rotate");
    }, 1500);
  };

  window.addEventListener("scroll", handleScroll);

  const scrollTop2 = document.documentElement.scrollTop;
  useEffect(() => {
    if (scrollTop2 <= 9) {
      document.getElementById("banana").classList.remove("full-rotate");
    }
  }, [scrollTop2]);
  return (
    <button
      className={`fixed z-[10] scale-50 md:scale-75 origin-bottom-right right-8 bottom-8 p-2 rounded-full ${
        isVisible ? "opacity-100" : "hidden"
      }`}
      onClick={handleClick}
    >
      <img
        src={Banana}
        alt="Botão de redirecionamento para o topo"
        className="transition-transform "
        id="banana"
      />
    </button>
  );
};

export default ScrollTopButton;
