import React from "react";
import { useInView } from "react-intersection-observer";
import Wrapper from "../../../resources/components/general/Wrapper";
import PoliticImg from "../../../resources/images/about/politic_img.png";
import PoliticSimbolImg from "../../../resources/images/about/politic_simbol.png";
import AccidentImg from "../../../resources/images/producers/accident_img.png";
import BiteImg from "../../../resources/images/producers/bite_img.png";
import { ReactComponent as Car } from "../../../resources/images/producers/car.svg";
import CleanSticker from "../../../resources/images/producers/clean_sticker.png";
import ConsumeSticker from "../../../resources/images/producers/consume_sticker.png";
import DeliverySticker from "../../../resources/images/producers/delivery_sticker.png";
import DiseasesSticker from "../../../resources/images/producers/diseases_sticker.png";
import InjurySticker from "../../../resources/images/producers/injury_sticker.png";
import IntoxicationImg from "../../../resources/images/producers/intoxication_img.png";
import KnifeSticker from "../../../resources/images/producers/knife_sticker.png";
import { ReactComponent as LeafLeft } from "../../../resources/images/producers/leaf_left_bg.svg";
import { ReactComponent as LeafLeftYellow } from "../../../resources/images/producers/leaf_left_yellow.svg";
import { ReactComponent as LeafRight } from "../../../resources/images/producers/leaf_right_bg.svg";
import { ReactComponent as LeafRightYellow } from "../../../resources/images/producers/leaf_right_yellow.svg";
import RatSticker from "../../../resources/images/producers/rat_sticker.png";
import RulesHero from "../../../resources/images/producers/rules_hero.png";
import {
  GreenSection,
  HeroDivider,
  SecondaryTextureBackground,
  SubPagesHero,
  TextureBackground,
} from "../styles/producers_styles";

function Rules() {
  const { ref: ref4, inView: inView4 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <>
      <TextureBackground>
        <SubPagesHero herobg={RulesHero}>
          <Wrapper closeFull>
            <div className="relative min-h-[60vh] flex flex-col justify-center items-center">
              <h1
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
                className="text-4xl md:text-5xl text-white font-bold text-center z-[1] leading-10 md:leading-[66px]"
              >
                Regras de higiene e boas práticas de segurança alimentar na
                exploração
              </h1>
            </div>
          </Wrapper>
        </SubPagesHero>
      </TextureBackground>
      <TextureBackground className="py-10">
        <HeroDivider />
        <Wrapper right full className={"flex mx-auto"}>
          <div
            id="quality"
            className="flex flex-col items-center h-full gap-10 md:flex-row pb-20 overflow-hidden"
            ref={ref4}
          >
            <div
              className={`flex flex-col basis-1/2 ml-10 md:ml-20 mr-10 ${
                inView4 ? "slide-in-left visible" : "invisible"
              }`}
            >
              <h3 className="text-[#172726] text-2xl lg:text-4xl font-bold uppercase py-5">
                Política da Qualidade e Segurança Alimentar
              </h3>
              <p className="mb-5 text-justify">
                A banana da Madeira é um produto regional de excelência, base de
                um setor económico vital para o desenvolvimento da Madeira, e em
                particular para os produtores de banana.
                <br />A Gerência da GESBA estabelece os meios e os recursos
                necessários para promover a sua Missão, e define a sua Política
                da Qualidade e Segurança Alimentar, que visa desenvolver a
                melhoria da qualidade e o aumento da notoriedade de produto
                Banana da Madeira. Esta política é periodicamente revista e
                suportada em objetivos mensuráveis.
              </p>
              {/* <a
                href="/files/GESBA_Politica_qualidade_seguranca_alimentar.pdf"
                target="_blank"
                rel="noreferrer"
                className="flex gap-3"
              >
                <FileCopyIcon htmlColor="#17272633" />
                Política da Qualidade e Segurança Alimentar
              </a> */}
            </div>
            <div
              className={`relative basis-1/2 relative ${
                inView4 ? "slide-in-right visible" : "invisible"
              }`}
            >
              <img src={PoliticImg} alt="" />
              <img
                className="absolute bottom-0 left-0 md:-left-7 lg:-left-12 w-28 lg:w-44"
                src={PoliticSimbolImg}
                alt=""
              />
            </div>
          </div>
        </Wrapper>
        <Wrapper className={"relative pb-10"} closeFull>
          <LeafLeft className="absolute -left-20 top-0" />
          <h2 className="text-2xl lg:text-3xl text-[#172726] font-semibold uppercase text-center mb-10">
            Higiene e segurança alimentar
          </h2>
          <div className="flex flex-col items-start sm:flex-row gap-10 flex-wrap">
            <div className="basis-full sm:basis-[calc(50%_-_40px)] lg:basis-[calc(100%_/_4_-_40px)] flex flex-col items-center">
              <img src={CleanSticker} alt="" className="max-h-[150px]" />
              <h3 className="text-xl py-3 uppercase font-medium">
                Lavar as mãos
              </h3>
              <ul className="text-[#5E5E5E]">
                <li className="list-disc">
                  Imediatamente depois de ir à casa de banho.
                </li>
                <li className="list-disc">Antes e depois das refeições.</li>
                <li className="list-disc">
                  Após manusear qualquer produto que possa contaminar a banana.
                </li>
              </ul>
            </div>
            <div className="basis-full sm:basis-[calc(50%_-_40px)] lg:basis-[calc(100%_/_4_-_40px)] flex flex-col items-center">
              <img src={ConsumeSticker} alt="" className="max-h-[150px]" />
              <h3 className="text-xl py-3 uppercase font-medium">Consumo</h3>
              <ul className="text-[#5E5E5E]">
                <li className="list-disc">
                  É proibido comer, fumar e beber bebidas alcoólicas durante as
                  atividades de colheita e transporte de banana.
                </li>
              </ul>
            </div>
            <div className="basis-full sm:basis-[calc(50%_-_40px)] lg:basis-[calc(100%_/_4_-_40px)] flex flex-col items-center">
              <img src={DiseasesSticker} alt="" className="max-h-[150px]" />
              <h3 className="text-xl py-3 uppercase font-medium">Doenças</h3>
              <ul className="text-[#5E5E5E]">
                <li className="list-disc">
                  Pessoas portadoras de uma doença infecciosa ou outra doença
                  grave (febre, vómitos ou diarreia) têm de informar o
                  responsável da exploração.
                </li>
              </ul>
            </div>
            <div className="basis-full sm:basis-[calc(50%_-_40px)] lg:basis-[calc(100%_/_4_-_40px)] flex flex-col items-center">
              <img src={InjurySticker} alt="" className="max-h-[150px]" />
              <h3 className="text-xl py-3 uppercase font-medium">
                Cortes/Arranhões
              </h3>
              <ul className="text-[#5E5E5E]">
                <li className="list-disc">
                  Qualquer corte, arranhão ou outro problema deve ser protegido
                  com um penso rápido ou algo que impeça a contaminação da
                  banana.
                </li>
              </ul>
            </div>
          </div>
          <LeafRight className="absolute right-0 -bottom-20 md:-top-20" />
        </Wrapper>
      </TextureBackground>
      <TextureBackground>
        <GreenSection>
          <LeafLeftYellow className="absolute left-0 top-0  z-[-1] hidden md:block" />
          <Wrapper closeFull>
            <h2 className="text-2xl lg:text-3xl text-[#172726] font-semibold uppercase text-center mb-10">
              Segurança na colheita e operações de corte
            </h2>
            <div className="flex flex-col sm:flex-row gap-10 items-start">
              <div className="basis-full sm:basis-[calc(50%_-_20px)] lg:basis-[calc(100%_/_3_-_20px)] flex flex-col items-center">
                <img src={DeliverySticker} alt="" className="max-h-[150px]" />
                <h3 className="text-xl py-3 uppercase font-medium">
                  Postura no solo
                </h3>
                <ul className="text-[#5E5E5E]">
                  <li className="list-disc">
                    Em piso muito irregular e declive acentuado, o trabalhador
                    deve adotar uma postura adequada ao peso do cacho que irá
                    transportar.
                  </li>
                </ul>
              </div>
              <div className="basis-full sm:basis-[calc(50%_-_20px)] lg:basis-[calc(100%_/_3_-_20px)] flex flex-col items-center">
                <img src={KnifeSticker} alt="" className="max-h-[150px]" />
                <h3 className="text-xl py-3 uppercase font-medium">
                  Faca de corte
                </h3>
                <ul className="text-[#5E5E5E]">
                  <li className="list-disc">
                    Cada trabalhador é responsável pela sua faca de corte. Esta
                    deve ser manuseada com cuidado e desinfetada antes do corte.
                  </li>
                </ul>
              </div>
              <div className="basis-full sm:basis-[calc(50%_-_20px)] lg:basis-[calc(100%_/_3_-_20px)] flex flex-col items-center">
                <img src={RatSticker} alt="" className="max-h-[150px]" />
                <h3 className="text-xl py-3 uppercase font-medium">Roedores</h3>
                <ul className="text-[#5E5E5E]">
                  <li className="list-disc">
                    No momento do corte, inspecionar se o cacho apresenta
                    indícios de ataques de roedores. Se sim, rejeitar o cacho.
                  </li>
                </ul>
              </div>
            </div>
          </Wrapper>
          <LeafRightYellow className="absolute right-0 top-0 z-[-1]  hidden md:block" />
        </GreenSection>
      </TextureBackground>
      <SecondaryTextureBackground className="py-40">
        <Wrapper closeFull>
          <h2 className="text-2xl lg:text-3xl text-[#172726] font-semibold uppercase text-center mb-10">
            Normas para carga e transporte
          </h2>
          <div className="flex flex-col md:flex-row items-center">
            <div className="basis-full md:basis-3/4">
              <ul>
                <li className="list-disc">
                  Verificar se os cobertores, esponjas, sacos de bolhas de ar e
                  mantas plásticas estão limpos.
                </li>
                <li className="list-disc">
                  Limpar/lavar o veículo de transporte antes de transportar os
                  cachos de banana.
                </li>
                <li className="list-disc">
                  O veículo deve ter uma manutenção periódica, de modo a estar
                  em boas condições de circulação.
                </li>
                <li className="list-disc">
                  Proteger os cachos no seu transporte.
                </li>
              </ul>
            </div>
            <div className="w-full">
              <Car className="object-contain w-full" />
            </div>
          </div>
        </Wrapper>
      </SecondaryTextureBackground>
      <GreenSection className="py-40">
        <Wrapper closeFull>
          <h2 className="text-2xl lg:text-3xl text-[#172726] font-semibold uppercase text-center mb-10">
            pROCEDIMENTOs em caso de acidente
          </h2>
          <div className="flex flex-col md:flex-row items-center">
            <div className="basis-full md:basis-3/4">
              <ul>
                <li className="list-disc">
                  Contactar o auxílio adequado o mais rápido possível.
                </li>
                <li className="list-disc">
                  Se necessário, ligar para 112 (número nacional de socorro) ou
                  CIAV - 800 250 250 (Centro de Informação Antivenenos).
                </li>
                <li className="list-disc">
                  Informar o responsável da exploração.
                </li>
                <li className="list-disc">
                  Recolher informações acerca do estado dos acidentados.
                </li>
                <li className="list-disc">
                  Em caso de acidente de grandes dimensões, estabelecer um ponto
                  de encontro para todos, em local seguro nas imediações das
                  instalações ou parcela.
                </li>
              </ul>
            </div>
            <img src={AccidentImg} alt="" />
          </div>
        </Wrapper>
      </GreenSection>
      <TextureBackground className="py-[120px]">
        <Wrapper closeFull>
          <h2 className="text-2xl text-[#172726] font-semibold uppercase text-center mb-10">
            Procedimento em caso de mordedura ou picada
          </h2>
          <div className="flex flex-col md:flex-row-reverse items-center">
            <div className="basis-full md:basis-3/4">
              <ul>
                <li className="list-disc">
                  Desinfetar imediatamente a zona mordida ou picada.
                </li>
                <li className="list-disc">
                  Deslocar-se a um posto médico ou unidade hospitalar.
                </li>
                <li className="list-disc">
                  Estar atento a sinais ou sintomas de doença nos dias seguintes
                  à mordedura ou picada.
                </li>
              </ul>
            </div>
            <img src={BiteImg} alt="" />
          </div>
        </Wrapper>
      </TextureBackground>
      <GreenSection>
        <Wrapper>
          <h2 className="text-2xl text-[#172726] font-semibold uppercase text-center mb-10">
            Procedimento em caso de intoxicação ou envenenamento
          </h2>
          <div className="flex flex-col md:flex-row items-center">
            <div className="basis-full md:basis-3/4">
              <ul>
                <li className="list-disc">
                  Manter o olho aberto e lavar com água corrente ou utilizar
                  frasco de soro fisiológico.
                </li>
                <li className="list-disc">
                  Lavar com água durante 15 minutos.
                </li>
                <li className="list-disc">
                  Deixar a água escorrer no olho na direção do nariz para a
                  parte exterior da face.
                </li>
                <li className="list-disc">
                  Retirar imediatamente a roupa contaminada. Lavar muito bem com
                  água e sabão a zona afetada.
                </li>
              </ul>
            </div>
            <img src={IntoxicationImg} alt="" />
          </div>
        </Wrapper>
      </GreenSection>
    </>
  );
}
export default Rules;
