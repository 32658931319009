import React from "react";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

function InsuranceCard({ data }) {
  return (
    <div className="basis-full h-full md:basis-[calc(100%_/_2_-_20px)] bg-white rounded drop-shadow-[0px_4px_8px_rgba(0,0,0,0.04)] p-5 box-border">
      <div className="flex flex-col h-full justify-between gap-2">
        <div className="flex items-center justify-center gap-2 flex-wrap my-2">
          <h3 className="text-[#363950] text-xl basis-full">{data?.name}</h3>
          <p className="text-[#7F8695] border-t-[#7F8695] border-t-[2px] basis-full pt-2">
            {data?.description}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-lg text-[#363950] font-semibold">
            {data?.extra_info}
          </p>

          {data?.website_url && (
            <a
              className="text-right text-[#0267B6] text-md self-end"
              href={data?.website_url}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              clicar para mais informações <KeyboardArrowRightOutlinedIcon />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default InsuranceCard;
