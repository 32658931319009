import moment from "moment";
import React from "react";
import EventImg from "../../images/news/event_img.png";
import "moment/locale/pt";
import { useNavigate } from "react-router";

function NewCard({ data, news }) {
  const navigate = useNavigate();
  let title = data?.title;
  let truncated = title.length > 50 ? title.slice(0, 50) + "..." : title;

  function getParsedDate(dateString) {
    const date = moment(dateString);
    date.locale("pt");
    const formattedDate = date.format("DD.MM.YYYY");

    return formattedDate;
  }

  return (
    <div
      onClick={() => {
        navigate(`/news/show/${data.id}`, {
          state: { item: data, news: news },
        });
      }}
      className="flex flex-col w-full mb-5 cursor-pointer"
    >
      <div className="flex flex-col w-full">
        <p className="text-[#B1B1B1]">
          {data?.published_date
            ? getParsedDate(data?.published_date)
            : getParsedDate(data?.created_at)}
        </p>
        <div className="flex gap-3 flex-col md:flex-row">
          <img
            src={data?.photo_url ?? EventImg}
            alt="Imagem notícia"
            className="max-w-[150px] aspect-square w-full object-cover"
          />
          <div className="flex flex-col justify-between">
            <div>
              <p
                className="text-[#172327] text-base font-bold"
                style={{
                  display: "-webkit-box",
                  "-webkit-box-orient": "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {truncated}
              </p>
            </div>
            <div
              onClick={() => {
                navigate(`/news/show/${data.id}`, {
                  state: { item: data, news: news },
                });
              }}
              className="cursor-pointer"
            >
              <span className="text-[#F2BB36]">ver mais</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewCard;
