import styled from "styled-components";
import TextureBG from "../../../resources/images/home/bg_texture.png";
import BottomDivider from "../../../resources/images/news/divider_bottom.svg";
import EventBG from "../../../resources/images/news/event_bg.svg";

export const NewsHero = styled("div")`
  width: 100%;
  /* min-height: 80vh; */
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -40px;
    background-image: url(${BottomDivider});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    width: 100%;
    height: 100px;
    transform: rotate(180deg);
  }
  & .newscontainer {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -40px;
      background-image: url(${BottomDivider});
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      width: 100%;
      height: 100px;
      transform: rotate(0deg);
    }
  }
`;
export const EventSideBar = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 100px;
  height: 100%;
  padding: 100px 12px 12px 12px;
  flex-basis: 33.33%;
  position: relative;
  background-color: #172726;

  &::after {
    position: absolute;
    left: -25px;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100px;
    content: "";
    display: block;
    background-image: url(${EventBG});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const TextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;
`;
