import React, { useEffect, useState } from "react";
import axiosConfig from "../../providers/axiosConfig";
import BackButton from "../../resources/components/general/BackButton";
import EventCard from "../../resources/components/general/EventCard";
import Wrapper from "../../resources/components/general/Wrapper";
import Divider from "../../resources/images/home/page-divider.png";
import EventsHero from "../../resources/images/news/events_hero.png";
import { TextureBackground } from "./styles/news_style";
import moment from "moment";
import { CircularProgress } from "@mui/material";

function ListEvents() {
  const [events, setEvents] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/events`)
      .then((res) => {
        setEvents(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);
  return (
    <>
      <div className="min-h-[45vh] w-full relative overflow-hidden z-[1]">
        <img
          src={EventsHero}
          alt=""
          className="absolute object-cover w-full h-full"
        />
        <div className="bg-gradient-to-r from-[#000] to-[transparent] z-[0] absolute w-full h-full" />
        <Wrapper closeFull>
          <div className="flex flex-col h-full justify-between mt-[100px] gap-5">
            <BackButton className="text-white relative" />
            <div className="flex flex-col gap-3 text-white">
              <h2 className="font-semibold uppercase text-5xl w-10/12 z-10">
                Eventos
              </h2>
            </div>
          </div>
        </Wrapper>
      </div>

      <TextureBackground className="relative z-[1]">
        <img
          src={Divider}
          alt=""
          className="absolute top-[-30px] z-[-1] -rotate-180 w-full"
        />
        <Wrapper closeFull>
          <div className="flex flex-col md:flex-row gap-10 pb-40 flex-wrap">
            {loading ? (
              <CircularProgress className="mx-auto" sx={{ color: "#1C6C5E" }} />
            ) : events && events.length > 0 ? (
              events
                .sort((a, b) => {
                  if (
                    moment(a["event-dates"][0]?.date_time).isBefore(
                      moment(b["event-dates"][0]?.date_time)
                    )
                  )
                    return 1;
                  return -1;
                })
                .map((item, index) => {
                  return (
                    <EventCard
                      secondary
                      big
                      data={item}
                      key={index}
                      events={events}
                    />
                  );
                })
            ) : (
              <h2 className="font-medium uppercase text-3xl w-10/12">
                Sem eventos de momento
              </h2>
            )}
          </div>
        </Wrapper>
      </TextureBackground>
    </>
  );
}

export default ListEvents;
