import styled, { keyframes } from "styled-components";
import Spinner from "../../images/production/Spinner.png";
import { ReactComponent as Logo } from "../../images/production/banana_only_logo.svg";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Outer = styled.div`
  animation: ${rotate} 2s linear infinite;
`;

const Inner = styled.div`
  animation: ${pulse} 2s linear infinite;
`;

const SpinningLogo = () => {
  return (
    <div>
      <div className="relative w-[120px] h-[120px] lg:h-40 lg:w-40">
        <Outer>
          <img src={Spinner} alt="loading-banana" />
        </Outer>
        <Inner className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:scale-100 scale-75 origin-center">
          <Logo />
        </Inner>
      </div>
      <p className="text-center uppercase font-semibold mt-5">A carregar ...</p>
    </div>
  );
};

export default SpinningLogo;
