import { CircularProgress, Typography } from "@mui/material";
import "moment/locale/pt";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";
import HighlightsCard from "../../resources/components/general/HighlightsCard";
import NewCard from "../../resources/components/general/NewCard";
import NewsCard from "../../resources/components/general/NewsCard";
import Wrapper from "../../resources/components/general/Wrapper";
import NewsHeroBG from "../../resources/images/news/news_hero.jpg";
import { ReactComponent as PalmBg } from "../../resources/images/news/palm_bg.svg";
import { NewsHero, TextureBackground } from "./styles/news_style";

function News() {
  const navigate = useNavigate();
  const [mainNews, setMainNews] = useState();
  const [highlightNews, setHighlighNews] = useState();
  const [remainNews, setRemainNews] = useState();
  const [allNews, setAllNews] = useState();
  const [productHighlights, setProductHighlights] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/news`)
      .then((res) => {
        setAllNews(res.data);
        setMainNews(res.data[0]);
        setHighlighNews(res.data.slice(1, 4));
        setRemainNews(res.data.slice(4));
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    axiosConfig.get(`/productionInformation`).then((res) => {
      setProductHighlights(res.data?.product_highlights);
    });
  }, []);

  function getParsedDate(dateString) {
    const date = moment(dateString);
    date.locale("pt");
    const formattedDate = date.format("DD MMMM YYYY");

    return formattedDate;
  }

  return (
    <>
      <NewsHero
        className={`${
          productHighlights?.length > 1 ? "min-h-[80vh]" : "min-h-[50vh]"
        }`}
      >
        <div
          className={`absolute top-0 left-0 h-full w-full after:z-[-1] after:block after:absolute after:top-0 after:left-0 after:bg-gradient-to-r after:from-[#000] after:to-[transparent] after:w-full after:h-full`}
        >
          <img
            src={
              productHighlights?.length > 0
                ? productHighlights[0].photo_url
                : NewsHeroBG
            }
            alt="main card of highlights"
            className="absolute top-0 left-0 z-[-2] w-full h-full object-cover"
          />
        </div>
        {productHighlights?.length > 1 ? (
          <div className="absolute flex w-full h-full">
            <div className="newscontainer basis-full w-full h-[40vh] z-[-1] self-end bottom-0 bg-[#172726]"></div>
          </div>
        ) : (
          <div className="absolute flex w-full h-full">
            <div className="newscontainer basis-full w-full h-[10vh] z-[-1] self-end bottom-0 bg-[#172726]"></div>
          </div>
        )}
        <Wrapper closeFull>
          <div className="flex h-full">
            <div className="flex flex-col gap-5 pt-[100px] text-white basis-full">
              <h2 className="text-white text-3xl uppercase font-bold mt-10">
                Avisos
              </h2>
              <div className="flex flex-col lg:flex-row w-full md:w-1/2 gap-10">
                <div className="basis-full flex-grow flex flex-col gap-4 z-[1] mb-10">
                  <p className="text-[#B1B1B1]">
                    {productHighlights?.length > 0 &&
                    productHighlights[0]?.published_date
                      ? getParsedDate(productHighlights[0]?.published_date)
                      : getParsedDate(productHighlights[0]?.created_at)}
                  </p>
                  <h3 className="font-bold uppercase text-2xl">
                    {productHighlights?.length > 0
                      ? productHighlights[0]?.name
                      : ""}
                  </h3>
                  <div className="flex flex-col md:flex-row gap-5 items-end justify-between">
                    <p className="mt-3">
                      <Typography className="break-words whitespace-pre-line overflow-hidden text-ellipsis">
                        {productHighlights?.length > 0
                          ? productHighlights[0]?.description
                          : ""}
                      </Typography>
                    </p>

                    {productHighlights?.length > 0 && productHighlights[0] && (
                      <Link
                        to={`/highlights/show/${productHighlights[0].id}`}
                        state={{ item: productHighlights[0], news: allNews }}
                        className="bg-[#89A164] text-white rounded py-2 px-4 whitespace-nowrap cursor-pointer"
                      >
                        ver mais
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:flex-row mt-5 gap-5">
                {loading ? (
                  <CircularProgress
                    className="mx-auto"
                    sx={{ color: "#FFF" }}
                  />
                ) : productHighlights && productHighlights.length > 0 ? (
                  productHighlights.map((item, index) => {
                    if (index !== 0) {
                      return (
                        <HighlightsCard
                          key={index}
                          data={item}
                          news={allNews}
                        />
                      );
                    }
                    return null;
                  })
                ) : (
                  <h3 className="text-lg font-medium uppercase mb-10">
                    Sem avisos em destaque
                  </h3>
                )}
              </div>
            </div>
          </div>
        </Wrapper>
      </NewsHero>
      <TextureBackground>
        <Wrapper closeFull>
          <div className="flex h-full">
            <div className="flex flex-col gap-5 pt-[100px] text-black basis-full">
              <h2 className="text-black text-3xl uppercase font-bold mt-10 border-b-2 border-black">
                Notícias
              </h2>
              {loading ? (
                <CircularProgress
                  className="mx-auto"
                  sx={{ color: "#1C6C5E" }}
                />
              ) : mainNews ? (
                <div className="flex flex-col lg:flex-row w-full gap-10">
                  <div className="basis-full flex-grow flex flex-col gap-4 z-[1] mb-10">
                    <img src={mainNews?.photo_url ?? NewsHeroBG} alt="" />
                    <p className="text-[#B1B1B1]">
                      {mainNews?.published_date
                        ? getParsedDate(mainNews?.published_date)
                        : getParsedDate(mainNews?.created_at)}
                    </p>
                    <h3
                      onClick={() => {
                        navigate(`/news/show/${mainNews.id}`, {
                          state: { item: mainNews, news: allNews },
                        });
                      }}
                      className="font-bold uppercase text-2xl cursor-pointer"
                    >
                      {mainNews?.title}
                    </h3>
                    <div className="flex flex-col md:flex-row gap-5 items-end justify-between">
                      <p className="mt-3">
                        <Typography className="break-words whitespace-pre-line overflow-hidden text-ellipsis">
                          {mainNews?.lead}
                        </Typography>
                      </p>

                      {mainNews && (
                        <Link
                          to={`/news/show/${mainNews.id}`}
                          state={{ item: mainNews, news: allNews }}
                          className="bg-[#F2BB36] text-white rounded py-2 px-4 whitespace-nowrap cursor-pointer"
                        >
                          ver mais
                        </Link>
                      )}
                    </div>
                  </div>

                  <div className="basis-1/3 flex flex-col ">
                    <h2 className="uppercase font-semibold mb-5 text-xl">
                      Principais destaques
                    </h2>
                    <div className="grid grid-cols-2 lg:grid-cols-1 gap-5 justify-center md:justify-around items-start">
                      {highlightNews &&
                        highlightNews.map((item, index) => {
                          return (
                            <div
                              onClick={() => {
                                navigate(`/news/show/${item.id}`, {
                                  state: { item: item, news: allNews },
                                });
                              }}
                              className="cursor-pointer"
                              key={index}
                            >
                              <NewCard data={item} news={allNews} />
                              {/* <NewsCard data={item} /> */}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              ) : (
                <h3 className="text-lg font-medium uppercase mb-10">
                  Sem notícias em destaque
                </h3>
              )}
            </div>
          </div>
        </Wrapper>
        {remainNews && remainNews.length > 0 && (
          <Wrapper closeFull className={" relative z-[1] py-10"}>
            <div>
              <p className="text-lg uppercase font-semibold whitespace-nowrap flex my-3">
                Mais Destaques
                <span className="border-b-2 border-b-black w-full"></span>
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              {remainNews &&
                remainNews.map((item, index) => {
                  let filtered = allNews.filter(
                    (info) => info.news_category === item.news_category
                  );
                  let randomItems = filtered
                    .sort(() => 0.5 - Math.random())
                    .slice(0, 3);

                  return (
                    <div
                      onClick={() => {
                        navigate(`/news/show/${mainNews.id}`, {
                          state: {
                            item: item,
                            related: randomItems,
                            news: allNews,
                          },
                        });
                      }}
                      className="cursor-pointer"
                      key={index}
                    >
                      <NewsCard data={item} />
                    </div>
                  );
                })}
            </div>
            {/* <div className="pb-20 flex flex-col gap-3">
            <a href="#" className="text-[#1C6C5E]">
              Mostrar mais resultados
            </a>
            <div className="h-[1px] w-full bg-[#D9D9D9]" />
          </div> */}
          </Wrapper>
        )}
        <PalmBg className="absolute -bottom-16 right-0 z-[0]" />
      </TextureBackground>
    </>
  );
}

export default News;
