import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function BananaType({ color, kg, label1, label2, percentage }) {
  return (
    <div className="flex flex-row gap-3 basis-1/4 items-center shadow-md">
      <div className={`basis-3/12 h-[70px] gap-2 min-w-[80px]`}>
        <div
          style={{ backgroundColor: color }}
          className={`flex flex-col justify-center ${
            label2 && "pt-5"
          } items-center  h-[70px] `}
        >
          <span className="text-[#FAFAFA] font-semibold text-center">
            {label1}
          </span>
          <span className="text-[#172726] font-medium">{label2}</span>
        </div>
      </div>
      <div className="flex items-center h-[70px] basis-9/12 flex-grow">
        <div className="w-16 h-16 p-1">
          <CircularProgressbarWithChildren
            value={percentage}
            strokeWidth={12}
            maxValue={100}
            styles={buildStyles({
              rotation: 0.5,
              textSize: "16px",
              pathColor: color,
              trailColor: "#E7E7E7",
            })}
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {Math.round(percentage)}%
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className="font-semibold text-[#172726] uppercase text-center">
          {new Intl.NumberFormat("pt", {
            style: "unit",
            unit: "kilogram",
          }).format(kg)}
        </div>
      </div>
      <div className="p-[1px] h-[70px]">
        <div
          style={{ backgroundColor: color }}
          className={`w-2 h-[70px]`}
        ></div>
      </div>
    </div>
  );
}

export default BananaType;
