import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";

const BackButton = ({ className }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex text-xl items-center absolute cursor-pointer ${className}`}
      onClick={() => navigate(-1)}
      style={{ textShadow: "1px 1px grey" }}
    >
      <KeyboardArrowLeftIcon fontSize="large" /> Retroceder
    </div>
  );
};
export default BackButton;
