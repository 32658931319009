import React from "react";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import moment from "moment";

function AnnouncementCard({ additionalInfo, contract, data }) {
  let date = moment(data?.end_date);
  let finished_date_day = date.format("DD");
  let finished_date_rest = date.format("MMMM YYYY");

  return (
    <div className="basis-full h-full md:basis-[calc(100%_/_2_-_20px)] bg-white rounded drop-shadow-[0px_4px_8px_rgba(0,0,0,0.04)] p-5 box-border flex flex-col justify-between">
      <div className="flex flex-col items-start gap-2 w-full">
        <h3 className="text-[#363950] text-xl basis-1/3">{data?.name}</h3>
        <p className="text-[#7F8695] border-t-[#7F8695] border-t-[2px] basis-2/3 w-full">
          {data?.title}
        </p>
      </div>
      <p className="text-lg text-[#363950]">{additionalInfo}</p>
      {contract ? (
        <div className="flex flex-col md:flex-row justify-between mt-3">
          <div className="flex flex-col">
            <div className="flex">
              <p className="text-[#7F8695]">
                <span className="text-2xl font-semibold text-black whitespace-nowrap">
                  <b className="text-3xl font-semibold"> {finished_date_day}</b>{" "}
                  {finished_date_rest}
                </span>
              </p>
            </div>
            <p className="text-[#7F8695]">
              {data?.country} , {data?.district}
            </p>
          </div>
          {data?.website_url && (
            <a
              className="text-right text-[#0267B6] text-md mt-5 self-end"
              href={data?.website_url}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              clicar para mais informações <KeyboardArrowRightOutlinedIcon />
            </a>
          )}
        </div>
      ) : (
        <>
          {data?.website_url && (
            <a
              className="text-right text-[#0267B6] text-md mt-5 self-end"
              href={data?.website_url}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              clicar para mais informações <KeyboardArrowRightOutlinedIcon />
            </a>
          )}
        </>
      )}
    </div>
  );
}

export default AnnouncementCard;
