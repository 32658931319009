import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const Card = styled("div")`
  /* min-width: calc(100%); */
  display: flex;
  flex-direction: column;

  color: #fff;
  border-right: ${(props) => props.divider && "1px solid #E8E7E380"};
  padding: 0 30px;
  scroll-snap-align: start;
`;
function AlertCard({ divider, type, data }) {
  const stringLimit = 80;
  const navigate = useNavigate();
  
  return (
    <Card
      onClick={() => {
        if (type === "Notícias") {
          navigate(`/news/show/${data.id}`, {
            state: { item: data },
          });
        } else if (type === "Eventos") {
          navigate(`/events/show/${data.id}`, {
            state: { item: data },
          });
        }
      }}
      divider={divider}
      className="min-w-full md:min-w-[50%] lg:min-w-[25%] justify-start cursor-pointer"
    >
      <div className="box-border p-2 text-center bg-[#C5DBA4] text-[#172327] rounded whitespace-nowrap max-w-[200px]">
        {type}
      </div>
      <p className="pt-2 font-semibold">
        {data?.title
          ? data?.title?.slice(0, stringLimit) + "..."
          : data?.name?.slice(0, stringLimit) + "..."}
      </p>
      <p className="pb-2">
        {data?.body
          ? data?.body?.slice(0, stringLimit) + "..."
          : data?.annex_about
          ? data?.annex_about?.slice(0, stringLimit) + "..."
          : data?.description
          ? data?.description?.slice(0, stringLimit) + "..."
          : ""}
      </p>
    </Card>
  );
}

export default AlertCard;
