import React from "react";
import WeatherTypes from "../../data/weatherType.json";
import WindSpeeds from "../../data/windSpeed.json";
import Wind from "../../images/home/wind.png";
import { ReactComponent as WarningIcon } from "../../images/home/WarningIcon.svg";

function WeatherCard({ data, uv, warnings }) {
  const date = new Date(data.forecastDate);
  const dayOfWeek = date.toLocaleDateString("pt-PT", { weekday: "long" });
  const dateFormatted = date.toLocaleDateString("pt-PT", {
    day: "numeric",
    month: "long",
  });

  let rainWarnings = warnings?.filter(
    (record) =>
      record.awarenessTypeName === "Precipitação" &&
      record.awarenessLevelID !== "green"
  );

  let windWarnings = warnings?.filter(
    (record) =>
      record.awarenessTypeName === "Vento" &&
      record.awarenessLevelID !== "green"
  );

  let hotWarnings = warnings?.filter(
    (record) =>
      record.awarenessTypeName === "Tempo Quente" &&
      record.awarenessLevelID !== "green"
  );

  let weatherType = data.idWeatherType;
  let weatherStatus = WeatherTypes.find(
    (record) => record.idWeatherType === data.idWeatherType
  );

  let windStatus = WindSpeeds.find(
    (record) => record.classWindSpeed === data.classWindSpeed
  );

  let parsedWeatherType =
    data.idWeatherType < 10 ? `0${weatherType}` : weatherType;

  let uvPredictions = uv?.filter(
    (record) =>
      record.globalIdLocal === 2310300 && record.data === data.forecastDate
  );

  function handleUvPredictionDescription() {
    let uvPredictionDescription;

    if (uvPredictions?.length > 0) {
      switch (true) {
        case uvPredictions[0]?.iUv >= 11:
          uvPredictionDescription = "Extremo";
          break;
        case uvPredictions[0]?.iUv >= 8:
          uvPredictionDescription = "Muito elevado";
          break;
        case uvPredictions[0]?.iUv >= 6:
          uvPredictionDescription = "Elevado";
          break;
        case uvPredictions[0]?.iUv >= 3:
          uvPredictionDescription = "Moderado";
          break;
        case uvPredictions[0]?.iUv >= 1:
          uvPredictionDescription = "Baixo";
          break;
        default:
          break;
      }
    }

    return uvPredictionDescription;
  }

  let windClass;
  switch (data?.predWindDir) {
    case "N":
      windClass = "";
      break;
    case "NE":
      windClass = "rotate-45";
      break;
    case "NO":
      windClass = "-rotate-45";
      break;
    case "S":
      windClass = "rotate-180";
      break;
    case "SE":
      windClass = "rotate-[135deg]";
      break;
    case "SW":
      windClass = "rotate-[-135deg]";
      break;
    case "E":
      windClass = "rotate-90";
      break;
    case "O":
      windClass = "-rotate-90";
      break;
    default:
      break;
  }

  return (
    <div className="border-white border-2 rounded-md xl:flex xl:flex-col xl:flex-grow grid grid-cols-3 relative">
      <div className="p-2 flex flex-col justify-center items-center">
        <p className="text-center font-bold uppercase xl:text-xl text-base">
          {dateFormatted}
        </p>
        <p className="text-center uppercase xl:text-lg text-xs">{dayOfWeek}</p>
      </div>
      <div className="flex flex-col xl:flex-row w-full xl:border-y-2 border-white border-x-2 xl:border-x-0">
        <div className="basis-1/2 bg-[#0267B6] flex items-center justify-center">
          <p className="text-center font-bold xl:text-2xl text-base">
            {Math.round(data?.tMin)}º
          </p>
        </div>
        <div className="basis-1/2 bg-[#F2BB36] flex items-center justify-center">
          <p className="text-center font-bold xl:text-2xl text-base">
            {Math.round(data?.tMax)}º
          </p>
        </div>
      </div>
      <div className="bg-[#FFFFFF30] xl:border-b-white xl:border-b-2 p-1 xl:p-2 xl:flex-grow xl:flex xl:flex-col xl:justify-center">
        <img
          src={`/files/meteo/w_ic_d_${parsedWeatherType}anim.svg`}
          alt=""
          className="mx-auto h-[40px] w-[40px] xl:h-[100px] xl:w-[100px]"
        />
        <p className="uppercase text-center font-bold xl:text-xl md:text-sm text-xs">
          {weatherStatus.descWeatherTypePT}
        </p>
      </div>
      <div
        className={`p-1 xl:p-2 border-t-2 xl:border-t-0 ${
          windWarnings?.length > 0
            ? new Date(windWarnings[0].endTime) > date
              ? windWarnings[0].awarenessLevelID === "yellow"
                ? "bg-[#FFC25F80]"
                : windWarnings[0].awarenessLevelID === "orange"
                ? "bg-[#E8750F80]"
                : windWarnings[0].awarenessLevelID === "red"
                ? "bg-[#FF494980]"
                : ""
              : ""
            : ""
        }`}
      >
        <p className="uppercase xl:text-start text-center md:text-base text-xs xl:font-bold">
          Velocidade do vento
        </p>
        <div className="flex xl:justify-between justify-around items-center">
          <p className="uppercase xl:basis-1/2 font-bold xl:text-xl text-sm">
            {windStatus?.descClassWindSpeedDailyPT}
          </p>
          <div>
            {windWarnings &&
              windWarnings.length > 0 &&
              new Date(windWarnings[0].endTime) > date && (
                <WarningIcon
                  title={windWarnings[0].text}
                  fill={`${
                    windWarnings?.length > 0 &&
                    windWarnings[0].awarenessLevelID === "yellow"
                      ? "#F2BB36"
                      : windWarnings[0].awarenessLevelID === "orange"
                      ? "#FF9D00"
                      : windWarnings[0].awarenessLevelID === "red"
                      ? "#FF4949"
                      : ""
                  }`}
                />
              )}
          </div>
          <div style={{ position: "relative" }}>
            <img src={Wind} alt="" className={`${windClass} w-[35px] `} />
            <p
              className="uppercase text-black font-extrabold text-xs"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {data?.predWindDir}
            </p>
          </div>
        </div>
      </div>

      <div
        className={`xl:border-y-white p-1 xl:p-2 border-t-2 border-x-2 xl:border-x-0 ${
          hotWarnings?.length > 0
            ? new Date(hotWarnings[0].endTime) > date
              ? hotWarnings[0].awarenessLevelID === "yellow"
                ? "bg-[#FFC25F80]"
                : hotWarnings[0].awarenessLevelID === "orange"
                ? "bg-[#E8750F80]"
                : hotWarnings[0].awarenessLevelID === "red"
                ? "bg-[#FF494980]"
                : ""
              : ""
            : ""
        }`}
      >
        <div className="flex xl:justify-between justify-around items-center">
          <p className="uppercase xl:text-start text-center md:text-base text-xs font-bold">
            índice UV
          </p>
          {hotWarnings &&
            hotWarnings.length > 0 &&
            new Date(hotWarnings[0].endTime) > date && (
              <WarningIcon
                fill={`${
                  hotWarnings?.length > 0 &&
                  hotWarnings[0].awarenessLevelID === "yellow"
                    ? "#F2BB36"
                    : hotWarnings[0].awarenessLevelID === "orange"
                    ? "#FF9D00"
                    : hotWarnings[0].awarenessLevelID === "red"
                    ? "#FF4949"
                    : ""
                }`}
              />
            )}
        </div>

        <div className="flex py-1 flex-wrap xl:flex-nowrap xl:justify-start md:justify-around justify-center">
          <p className="uppercase xl:basis-1/2 basis-full font-bold text-sm xl:text-xl xl:text-start text-center">
            UV{" "}
            {uvPredictions && uvPredictions[0]?.iUv
              ? Math.floor(uvPredictions[0]?.iUv)
              : "-"}
          </p>

          <p className="uppercase xl:basis-1/2 basis-full text-xs xl:text-xl text-center">
            {handleUvPredictionDescription()}
          </p>
        </div>
      </div>

      <div
        className={`p-1 xl:p-2 border-t-2 ${
          rainWarnings?.length > 0
            ? new Date(rainWarnings[0].endTime) > date
              ? rainWarnings[0].awarenessLevelID === "yellow"
                ? "bg-[#FFC25F80]"
                : rainWarnings[0].awarenessLevelID === "orange"
                ? "bg-[#E8750F80]"
                : rainWarnings[0].awarenessLevelID === "red"
                ? "bg-[#FF494980]"
                : ""
              : ""
            : ""
        }`}
      >
        <div className="flex xl:justify-between flex-col lg:flex-row justify-around items-center text-center md:text-left">
          <div>
            <p className="uppercase xl:text-start text-center md:text-base text-xs font-bold">
              Precipitação
            </p>

            <div className="flex xl:justify-start justify-center py-1">
              <p className="uppercase xl:basis-1/2 font-bold xl:text-xl text-sm flex-grow">
                {data?.precipitaProb}
                {" %"}
              </p>
            </div>
          </div>
          <div className="mx-auto md:mr-5">
            {rainWarnings &&
              rainWarnings.length > 0 &&
              new Date(rainWarnings[0].endTime) > date && (
                <WarningIcon
                  fill={`${
                    rainWarnings?.length > 0 &&
                    rainWarnings[0].awarenessLevelID === "yellow"
                      ? "#F2BB36"
                      : rainWarnings[0].awarenessLevelID === "orange"
                      ? "#FF9D00"
                      : rainWarnings[0].awarenessLevelID === "red"
                      ? "#FF4949"
                      : ""
                  }`}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeatherCard;
