import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../resources/components/general/BackButton";
import Wrapper from "../../../resources/components/general/Wrapper";
import { ReactComponent as LeafBG } from "../../../resources/images/news/palm_bg.svg";
import VideoThumb from "../../../resources/images/producers/video_1.png";
import { VideoCard } from "../styles/producers_styles";
import { TextureBackground } from "./styles/media_style";

function ShowMedia() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const item = state?.item;
  const promos = state?.promos;
  const related = promos?.filter((promo) => promo.id !== item?.id).slice(0, 3);

  // const url = item?.multimedia_url;

  return (
    <>
      <TextureBackground className="min-h-[70vh] w-full relative overflow-hidden z-[1]">
        <Wrapper closeFull>
          <div className="flex flex-col h-full justify-between mt-[100px]">
            {/* <div className="flex justify-center w-full h-full"> */}
            <div className="relative py-10">
              <BackButton className={"top-0"} />
            </div>
            <div
              className="relative py-10 w-full mx-auto h-auto"
              //   style={{ paddingBottom: "56.25%" }}
            >
              <video className="w-full h-auto" controls={true} playsInline>
                <source
                  src={item.multimedia_url}
                  type="video/mp4"
                  style={{ pointerEvents: "none" }}
                />
              </video>
            </div>

            <div className="flex flex-col gap-3 pt-5 pb-10">
              <h2 className="font-semibold uppercase text-2xl w-10/12">
                {item?.name}
              </h2>
              <p className="text-lg font-medium mb-10 text-justify">
                {item?.description}
              </p>
            </div>
          </div>
        </Wrapper>
      </TextureBackground>

      <TextureBackground className="relative z-[1]">
        <Wrapper closeFull>
          <div className="flex flex-col py-10">
            {related && related.length > 0 && (
              <h2 className="uppercase font-semibold mb-5">Relacionados</h2>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 overflow-auto w-full gap-10 justify-items-center align-items-center">
              {related ? (
                related.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(`/producers/media/show/${item.id}`, {
                          state: { item: item, promos: promos },
                        });
                      }}
                    >
                      <VideoCard src={VideoThumb} item={item} />
                    </div>
                  );
                })
              ) : (
                <></>
              )}

              {/* <VideoCard src={VideoThumb} />
              <VideoCard src={VideoThumb} /> */}
            </div>
          </div>
        </Wrapper>
        <LeafBG className="absolute bottom-0 right-0 opacity-40 origin-bottom-right scale-75" />
      </TextureBackground>
    </>
  );
}

export default ShowMedia;
