import React from "react";
import { ReactComponent as LeafBG } from "../../resources/images/news/palm_bg.svg";
import { TextureBackground } from "../producers/media/styles/media_style";
import Wrapper from "../../resources/components/general/Wrapper";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

const CustomAccordionDetails = styled(AccordionDetails)`
  > p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #3b3d4b;
    line-height: 27px;
  }
`;

const CustomSummary = styled(AccordionSummary)`
  color: #1c6c5e;
  font-size: 18px;
  font-weight: 600;
`;

const CustomSubTitle = styled("div")`
  font-weight: 400;
  h3 {
    color: #1c6c5e;
    font-size: 24px;
  }
  p {
    color: #3b3d4b;
    font-size: 18px;
    line-height: 30px;
  }
`;
const CustomAccordion = styled(Accordion)`
  box-shadow: none;
  border-radius: 8px;
  &::before {
    background-color: transparent;
  }
`;

function Privacy() {
  const navigate = useNavigate();

  return (
    <>
      <TextureBackground className="min-h-[70vh] w-full relative overflow-hidden z-[1] font-prompt">
        <Wrapper closeFull>
          <div className="flex flex-col md:flex-row h-full gap-10 md:gap-20 justify-between mt-[100px] md:mt-[150px]">
            <div className="w-full flex flex-col gap-3 md:gap-10 pt-5">
              <p
                className="font-semibold uppercase text-sm w-10/12 text-[#1c6c5e] md:whitespace-nowrap cursor-pointer"
                onClick={() => navigate("/privacy")}
              >
                Política de Privacidade
              </p>
              <p
                className="font-semibold uppercase text-sm w-10/12 text-[#1c6c5e]  md:whitespace-nowrap opacity-50 cursor-pointer"
                onClick={() => navigate("/terms")}
              >
                Termos de serviços
              </p>
            </div>
            <div className="flex flex-col gap-3 pt-5 pb-10">
              <h2 className="font-semibold uppercase text-4xl w-10/12 text-[#1c6c5e]">
                Política de Privacidade
              </h2>
              <p className="font-semibold uppercase text-sm w-10/12 text-[#1c6c5e]">
                Atualizado a 7 de março de 2023
              </p>

              <CustomSubTitle className="mt-5 flex flex-col gap-5">
                <h3 className="font-normal text-2xl w-10/12">
                  GESBA - Declaração de compromisso com a privacidade e proteção
                  de dados pessoais
                </h3>
                <p className="text-lg font-medium mb-10">
                  A proteção da privacidade e dos dados pessoais é uma
                  prioridade para a GESBA – Empresa de Gestão do Sector da
                  Banana, Lda., e um claro compromisso assumido por nós. Esta
                  política diz respeito às práticas de privacidade no âmbito do
                  exercício da atividade da GESBA incluindo os portais, sítios
                  online e outros serviços geridos e utilizados pela mesma. Os
                  dados pessoais que nos forneça durante a interação com os
                  vários serviços serão tratados com as garantias de segurança e
                  confidencialidade exigidas pelo enquadramento legal relativo à
                  proteção de dados pessoais.
                </p>
              </CustomSubTitle>
              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Política Geral de Segurança e Privacidade</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    A proteção da privacidade e dos dados pessoais é uma
                    prioridade para a GESBA – Empresa de Gestão do Sector da
                    Banana, Lda., e um claro compromisso assumido por nós.
                  </p>
                  <p>
                    Esta política diz respeito às práticas de privacidade no
                    âmbito do exercício da atividade da GESBA incluindo os
                    portais, sítios online e outros serviços geridos e
                    utilizados pela mesma. Os dados pessoais que nos forneça
                    durante a interação com os vários serviços serão tratados
                    com as garantias de segurança e confidencialidade exigidas
                    pelo enquadramento legal relativo à proteção de dados
                    pessoais.
                  </p>
                  <p>
                    Os portais, sítios e aplicações sob a responsabilidade e
                    utilizados pela GESBA, no âmbito dos serviços
                    disponibilizados, contêm links para outros sítios sob a
                    responsabilidade de entidades terceiras. A política de
                    privacidade da GESBA aplica-se exclusivamente aos sítios e
                    serviços sob sua responsabilidade. Quando acede a sítios de
                    outras entidades, ainda que a partir dos sítios sob
                    responsabilidade da GESBA, deverá ler as respetivas
                    políticas de privacidade.
                  </p>
                  <p>
                    O acesso e a utilização dos serviços, portais e sítios, sob
                    a responsabilidade da GESBA, devem ser livres por parte do
                    utilizador presumindo a GESBA que este leu, compreendeu e
                    aceitou os Termos e Condições da sua utilização
                  </p>
                  <p>
                    Quaisquer tentativas de alterar a informação, ou qualquer
                    outra ação que possa causar dano e pôr em risco a
                    integridade do sistema, são estritamente proibidas de acordo
                    com a legislação em vigor. O utilizador obriga-se a cumprir
                    escrupulosamente a legislação aplicável, nomeadamente, em
                    matéria de criminalidade informática e de direitos de
                    propriedade intelectual, sendo exclusivamente responsável
                    pela infração destes normativos
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Encarregado de Proteção de Dados</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    A GESBA designou um Encarregado de Proteção de Dados que
                    poderá ser contactado através do endereço eletrónico{" "}
                    <a
                      href="mailto:protecaodedados@gesba.pt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      protecaodedados@gesba.pt
                    </a>{" "}
                    ou por carta registada com aviso de receção para Rua de
                    Santa Rita, n.º 56, 9000-238, Funchal.
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Privacidade</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    A GESBA respeita o seu direito à privacidade. Os dados
                    solicitados destinam-se à prestação do serviço por si
                    solicitado, não sendo recolhidos dados pessoais que não
                    sejam necessários à prestação do serviço ou sem o seu
                    consentimento.
                  </p>
                  <p>
                    A interação com os serviços, portais e sítios da GESBA não
                    exige informação do utilizador, salvo quando, para
                    propósitos expressamente identificados, o registo seja
                    declarado necessário e seja por aquele autorizado de acordo
                    com os termos seguintes
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Responsável pelo Tratamento</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    A GESBA enquanto responsável pelo tratamento de dados:
                    <ul className="list-disc ml-5 my-5 flex flex-col gap-5">
                      <li>
                        Assegura que o tratamento dos seus dados pessoais é
                        efetuado no âmbito das finalidades para as quais os
                        mesmos foram recolhidos ou para finalidades compatíveis
                        com aquelas;
                      </li>
                      <li>
                        Recolhe, utiliza e conserva apenas os dados pessoais
                        necessários para a finalidade em causa;
                      </li>
                      <li>
                        Não procede a qualquer transmissão de dados pessoais
                        para fins comerciais ou de publicidade;
                      </li>
                      <li>
                        Trata os dados pessoais para fins legalmente previstos
                        ou para a prossecução de serviços a seu pedido.
                      </li>
                    </ul>
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Tipologia de Dados Pessoais que recolhemos</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    Os dados pessoais sujeitos a tratamento por parte da GESBA
                    são de natureza diversa atenta a prestação de serviços
                    promovidos nas plataformas eletrónicas e restantes serviços
                    sob a sua gestão.
                  </p>
                  <p>
                    De acordo com o tipo de serviços prestados podem ser
                    recolhidos: o endereço IP; localização via GPS; o nome; o
                    género; a idade; a data e local de nascimento, o número de
                    identificação fiscal; o número de identificação civil; o
                    número de segurança social; o número de documento de
                    identificação; o IBAN; o NIFAP (número de identificação do
                    beneficiário no sistema de informação do IFAP); o estado
                    civil; a morada; a nacionalidade; o endereço eletrónico; e o
                    número de telemóvel.
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Segurança de Dados Pessoais</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    A GESBA, na prossecução das suas atividades, utiliza um
                    conjunto de tecnologias e procedimentos de segurança
                    adequados à proteção dos seus dados pessoais, protegendo o
                    acesso ou divulgação não autorizados, nomeadamente:
                    <ul className="list-disc ml-5 my-5 flex flex-col gap-5">
                      <li>
                        Medidas de segurança física, como seja, o controlo de
                        acessos de funcionários, colaboradores e visitantes às
                        instalações da sede e, sobre as instalações do Data
                        center, mecanismos muito restritos de combate à
                        intrusão, extinção de incêndios, monitorização de
                        equipamentos 24×7 e alojamento de equipamentos em
                        bastidores dedicados;
                      </li>
                      <li>
                        Medidas de segurança lógica, na componente de acessos a
                        sistemas e postos de trabalho através de mecanismos de
                        gestão de identidades, autenticação e privilégios; na
                        componente de rede o uso de firewalls e sistemas de
                        deteção de intrusão, segregando redes e ambientes
                        aplicacionais, bem como, cifragem de informação através
                        de canais de comunicação seguros
                      </li>
                    </ul>
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Acesso e controlo dos seus Dados Pessoais</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    Possibilitamos, a seu pedido, o acesso, a retificação e o
                    apagamento dos dados pessoais. Tem também o direito de se
                    opor ao processamento dos seus dados pessoais.
                  </p>
                  <p>
                    Caso a utilização dos seus dados pessoais seja baseada no
                    consentimento, tem o direito de o retirar, sem comprometer a
                    validade do tratamento de dados efetuado até esse momento.
                  </p>
                  <p>
                    Através do encarregado de proteção de dados, que poderá ser
                    contactado através do endereço eletrónico{" "}
                    <a
                      href="mailto:protecaodedados@gesba.pt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      protecaodedados@gesba.pt
                    </a>{" "}
                    ou por carta registada com aviso de receção para Rua de
                    Santa Rita, n.º 56, 9000-238, Funchal pode solicitar:
                    <ul className="list-disc ml-5 my-5 flex flex-col gap-5">
                      <li>Informação acerca dos seus dados;</li>
                      <li>Informação acerca do processamento;</li>
                      <li>A finalidade subjacente ao tratamento;</li>
                      <li>A correção, ou atualização, de dados</li>
                      <li>Os seus dados num formato aberto;</li>
                      <li>
                        O apagamento dos dados, sem prejuízo da legislação
                        aplicável ao tratamento em causa.
                      </li>
                    </ul>
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Arquivo de Dados Pessoais</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    Os seus dados pessoais são conservados pela GESBA pelo
                    período necessário ao tratamento, de acordo com a finalidade
                    respetiva.
                  </p>
                  <p>
                    Os períodos de conservação podem alterar-se quando o
                    interesse público associado, motivos históricos, científicos
                    ou estatísticos o justifiquem, comprometendo-se a GESBA a
                    adotar as adequadas medidas de conservação e segurança.
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Exoneração de Responsabilidade</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    A GESBA não poderá ser responsabilizada por quaisquer
                    prejuízos ou danos em sede de responsabilidade civil
                    (incluindo, mas sem que a estes estejam limitados, danos
                    emergentes, lucros cessantes e danos morais, causados direta
                    ou indiretamente), que surjam em consequência da utilização,
                    correta ou incorreta dos seus Serviços, sítios eletrónicos e
                    respetivos conteúdos por parte do utilizador, incluindo o
                    acesso indevido ao computador e sistema informático do
                    utilizador por terceiros.
                  </p>
                  <p>
                    A informação divulgada deve ser encarada na sua vertente
                    informativa.
                  </p>
                  <p>
                    Nos serviços, sítios e portais geridos pela GESBA existem
                    conteúdos e serviços disponibilizados por entidades
                    terceiras, cabendo a sua atualização a essas entidades.
                    Deste modo, a GESBA não poderá ser responsabilizada no que
                    respeita à completa exatidão e atualidade de qualquer
                    informação constante dos seus sítios.
                  </p>
                  <p>
                    Os sítios eletrónicos podem conter ligações para sítios
                    operados por entidades terceiras sobre os quais não tem
                    controlo e pelos quais não assume qualquer responsabilidade.
                  </p>
                  <p>
                    A visualização de disposições legais neste sítio, não
                    dispensa a consulta das normas legais em vigor, aprovadas
                    oficialmente, publicadas nas edições e suportes originais
                    (nomeadamente o Diário da República ou o Jornal Oficial da
                    União Europeia).
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Alteração à política de segurança e privacidade</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    Os presentes Termos e Condições, que deverá ler atentamente
                    poderão ser alterados considerando-se que as alterações
                    entram em vigor a partir da data da sua publicitação neste
                    sítio, fazendo-se expressa referência à data de atualização.
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>
              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Lei aplicável e foro competente</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    Os presentes Termos e Condições são regidos e interpretados
                    de acordo com a lei portuguesa. <br />É competente o
                    tribunal da área de Lisboa, com exclusão de qualquer outro,
                    para dirimir quaisquer conflitos que resultem da
                    interpretação e aplicação dos presentes Termos e Condições
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>
              <div className="my-5 flex flex-col gap-5">
                <h3 className="font-normal text-2xl w-10/12">
                  Política de Cookies
                </h3>
              </div>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Cookies e Tecnologias Semelhantes</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    A GESBA utiliza nos seus sítios eletrónicos cookies e outras
                    tecnologias semelhantes que permitem melhorar o desempenho e
                    a experiência de navegação dos seus Utilizadores,
                    aumentando, por um lado, a rapidez e eficiência de resposta
                    e, por outro, eliminando a necessidade de introduzir
                    repetidamente as mesmas informações. A colocação de cookies
                    ajuda os sítios eletrónicos a reconhecer o dispositivo do
                    Utilizador na visita seguinte sendo com frequência
                    imprescindível para o funcionamento do mesmo.
                  </p>
                  <p>
                    Os cookies usados pela GESBA não recolhem informações
                    pessoais que permitam identificar o Utilizador, guardando
                    apenas informações genéricas, designadamente a forma ou
                    região/cidade de acesso, entre outros.
                  </p>
                  <p>
                    Os cookies utilizados nos sítios e portais da GESBA são os
                    seguintes:
                    <ul className="list-disc ml-5 my-5 flex flex-col gap-5">
                      <li>
                        Essenciais – necessários à navegação no sítio eletrónico
                        ou portais da GESBA, viabilizando ou otimizando as
                        funcionalidades oferecidas pelos websites e melhorar a
                        experiência de navegação do utilizador, garantindo o
                        acesso a áreas privadas do mesmo, de modo a permitir a
                        realização de serviços, designadamente, cookies de
                        sessão.
                      </li>
                      <li>
                        Analíticos – necessários à recolha de informação para
                        análise estatística da navegação e utilização do sítio
                        eletrónico, nomeadamente, Google.
                      </li>
                      <li>De terceiros</li>
                      <li>
                        Facebook, Twitter, YouTube, Instagram, Google Play, App
                        Store e outros websites geridos por terceiros, podem
                        instalar cookies para fins de partilha de conteúdos. O
                        número e o estatuto destes cookies podem depender da
                        forma de utilização das plataformas em questão antes ou
                        durante uma visita a este website. É útil verificar qual
                        a política de gestão dos cookies desses websites. A
                        informação gerada pela utilização dos cookies pode ser
                        enviada para terceiros para análise de tendências,
                        rastreio da navegação e desempenho dos mesmos, sem,
                        contudo, identificar qualquer pessoa.
                      </li>
                    </ul>
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Como controlar o uso de Cookies?</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    Os utilizadores podem impedir o uso de cookies nas
                    configurações do navegador. Se limitar esta opção, no
                    entanto, a utilização de alguns recursos ou o desempenho de
                    determinadas ferramentas pode ficar condicionada.
                  </p>
                  <p>
                    É ainda possível usar extensões dos navegadores (browser
                    add-on’s) para desativar a utilização de algumas funções. A
                    Google disponibiliza uma extensão para Google Analytics que
                    permite desativar a sua utilização. Está disponível em{" "}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://tools.google.com/dlpage/gaoptout/
                    </a>
                    <br />
                    <br />
                    Como gerir os Cookies nos diversos navegadores:
                    <ul className="ml-5 my-5 flex flex-col gap-5">
                      <li>
                        <span className="font-semibold">Google Chrome:</span>
                        <p className="text-base">
                          Por defeito, são permitidos todos os cookies, mas pode
                          ajustar esta definição. Siga estes passos: Clique no
                          menu do Chrome na barra de ferramentas do navegador.
                        </p>
                        <ol className="list-decimal ml-10">
                          <li>Definições;</li>
                          <li>Mostrar definições avançadas;</li>
                          <li>
                            Em “Privacidade”, clique em Definições de conteúdo;
                          </li>
                          <li>
                            Selecione “Bloquear definição dos dados por parte
                            dos sites”
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span className="font-semibold">
                          Para alterar as definições de cookies, siga estes
                          passos:
                        </span>
                        <ol className="list-decimal ml-10">
                          <li>Eliminar cookies;</li>
                          <li>Bloquear cookies por predefinição;</li>
                          <li>Permitir cookies por predefinição;</li>
                          <li>
                            Manter cookies e dados do website por predefinição
                            até sair do navegador;
                          </li>
                          <li>
                            Criar exceções para cookies de websites ou domínios
                            específicos.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span className="font-semibold">
                          Internet Explorer:
                        </span>
                        <ol className="list-decimal ml-10">
                          <li>
                            Selecione o menu “Ferramentas” e a opção “Opções de
                            Internet”;
                          </li>
                          <li>Separador “Privacidade”;</li>
                          <li>
                            Mova a barra para o topo, onde surgirá a mensagem
                            “Bloquear todos os cookies”.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span className="font-semibold">Mozilla Firefox:</span>
                        <ol className="list-decimal ml-10">
                          <li>Selecione o menu “Ferramentas”;</li>
                          <li>Selecione “Opções”;</li>
                          <li>
                            “Privacidade”, que encontra no painel superior;
                          </li>
                          <li>
                            Na secção cookies desative a opção “Aceitar cookies
                            dos sites”. Clique em “OK” para guardar as
                            alterações e fechar.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span className="font-semibold">Safari:</span>
                        <ol className="list-decimal ml-10">
                          <li>Selecione “Editar”;</li>
                          <li>. Clique em “Preferências”;</li>
                          <li>
                            No painel superior, selecione o ícone “Privacidade”;
                          </li>
                          <li>
                            Na secção “Aceitar cookies”, selecione “Nunca”.
                          </li>
                        </ol>
                      </li>
                    </ul>
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>
              <a
                href="/files/Politica_Geral_de_Seguranca_e_Privacidade.pdf"
                target="_blank"
                rel="noreferrer"
                className="font-semibold uppercase text-sm w-10/12 text-[#1c6c5e] my-5 md:my-10"
              >
                Consultar documento original
              </a>
            </div>
          </div>
        </Wrapper>

        <LeafBG className="absolute bottom-0 right-0 opacity-40 origin-bottom-right scale-75" />
      </TextureBackground>
    </>
  );
}

export default Privacy;
