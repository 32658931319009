import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import axiosConfig from "../../../providers/axiosConfig";
import AnnouncementCard from "../../../resources/components/general/AnnouncementCard";
import Wrapper from "../../../resources/components/general/Wrapper.js";
import Acin from "../../../resources/images/announcements/acingov.png";
import Govgo from "../../../resources/images/announcements/govgo.png";
import {
  AnnounceHero,
  AnnounceSection,
  TextureBackgroundLight,
} from "../styles/production_styles";
import { CircularProgress } from "@mui/material";

function Announcements() {
  const [announcements, setAnnouncements] = useState();
  const [loading, setLoading] = useState(false);

  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/announcements`)
      .then((res) => {
        setLoading(false);
        setAnnouncements(res.data);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <>
      <AnnounceHero />
      <AnnounceSection className="py-10">
        <Wrapper className={"overflow-hidden"}>
          <h2 className="text-white text-6xl font-bold mb-10">Anúncios</h2>
          <h2 className="text-white text-4xl uppercase font-semibold mb-5">
            Contratação Pública
          </h2>
          {!loading &&
            (announcements ? (
              <h2 className="text-white text-2xl uppercase font-medium">
                Publicado recentemente
              </h2>
            ) : (
              <h2 className="text-white text-2xl uppercase font-medium">
                Sem anúncios recentes publicados
              </h2>
            ))}
          <div
            className="grid grid-cols-1 md:grid-cols-2 my-5 gap-5"
            ref={ref1}
          >
            {loading ? (
              <CircularProgress
                className="mx-auto block"
                sx={{ color: "#FFF" }}
              />
            ) : (
              announcements &&
              announcements.map((item, index) => {
                if (index % 2 !== 0) {
                  return (
                    <div
                      className={`${
                        inView1 ? "slide-in-right visible" : "invisible"
                      }`}
                    >
                      <AnnouncementCard contract data={item} />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`${
                        inView1 ? "slide-in-left visible" : "invisible"
                      }`}
                    >
                      <AnnouncementCard contract data={item} />
                    </div>
                  );
                }
              })
            )}
          </div>
        </Wrapper>
      </AnnounceSection>

      <TextureBackgroundLight className="py-20">
        <Wrapper>
          <h2 className="text-3xl text-center uppercase w-full sm:w-1/2 mx-auto font-semibold">
            consulte mais procedimentos públicos através das seguintes
            plataformas
          </h2>
          <div
            className="flex flex-col justify-center gap-10 md:gap-40 mt-10 md:flex-row items-center md:items-start"
            ref={ref2}
          >
            <div className="flex flex-col items-center gap-5">
              <p className="uppercase font-semibold">GovGo</p>
              <a
                href="https://www.govgo.pt/"
                target={"blank"}
                rel="noopener noreferrer"
              >
                <img
                  src={Govgo}
                  alt=""
                  className={`w-[200px] h-[200px]  ${
                    inView2 ? "zoom-in" : "invisible"
                  }`}
                />
              </a>
            </div>

            {/* <div className="flex flex-col items-center gap-5">
              <p className="uppercase font-semibold">Base Gov</p>
              <a
                href="https://www.base.gov.pt/"
                target={"blank"}
                rel="noopener noreferrer"
                className="cursor-pointer"
              >
                <img
                  src={Basegov}
                  alt=""
                  className={`w-[200px] h-[200px]  ${
                    inView2 ? "zoom-in" : "invisible"
                  }`}
                />
              </a>
            </div> */}

            <div className="flex flex-col items-center gap-5">
              <p className="uppercase font-semibold">Acin Gov</p>
              <a
                href="https://www.acingov.pt/"
                target={"blank"}
                rel="noopener noreferrer"
              >
                <img
                  src={Acin}
                  alt=""
                  className={`w-[200px] h-[200px]  ${
                    inView2 ? "zoom-in" : "invisible"
                  }`}
                />
              </a>
            </div>
          </div>
        </Wrapper>
      </TextureBackgroundLight>
    </>
  );
}

export default Announcements;
