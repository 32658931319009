import styled from "styled-components";
import FooterBG from "../../../resources/images/home/footer_bg.svg";
export const DocumentHero = styled("div")`
  width: 100%;
  position: relative;
  background-color: #ffffff03;
  backdrop-filter: blur(5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  /* min-height: 40vh; */
  z-index: 10;
  background-color: #172726;
  padding: 20px 0;

  &::before {
    background-image: url(${FooterBG});
    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    display: block;
    width: 100vw;
    height: 100%;
    bottom: -30px;
    left: -10%;
    position: absolute;
    z-index: -1;
    transform: rotate(180deg);

    @media (min-width: 768px) {
      left: -20%;
    }
  }
`;
