import React from "react";
import Wrapper from "../../../resources/components/general/Wrapper";
import {
  GreenSection,
  SubPagesHero,
  TextureBackground,
} from "../styles/producers_styles";
import HeroBg from "../../../resources/images/producers/pests_hero.png";

import PestsImg from "../../../resources/images/producers/pests_img.png";
import PestsImg2 from "../../../resources/images/producers/pests_img2.png";
import PestsImg3 from "../../../resources/images/producers/pests_img3.png";
import PestsImg4 from "../../../resources/images/producers/pests_img4.png";
import PestsImg5 from "../../../resources/images/producers/pests_img5.png";
import ShieldSticker from "../../../resources/images/producers/shield_sticker.png";
import EyeSticker from "../../../resources/images/producers/eye_sticker.png";
import LeafSticker from "../../../resources/images/producers/leaf_sticker.png";
import { ReactComponent as LeafDecoration } from "../../../resources/images/producers/leaf_decoration.svg";
import { ReactComponent as LeafAntLeft } from "../../../resources/images/producers/leaf_ant_left_bg.svg";
import { ReactComponent as LeafAntRight } from "../../../resources/images/producers/leaf_ant_right_bg.svg";
import { ReactComponent as LeafLeftYellow } from "../../../resources/images/producers/leaf_left_yellow.svg";
import { ReactComponent as LeafRightYellow } from "../../../resources/images/producers/leaf_right_yellow.svg";

function Pests() {
  return (
    <>
      <TextureBackground>
        <SubPagesHero herobg={HeroBg}>
          <Wrapper closeFull>
            <div className="relative min-h-[60vh] flex flex-col justify-center items-start ">
              <h1
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
                className="text-5xl text-white font-bold text-left z-[1] leading-[66px]"
              >
                Pragas, doenças e infestantes
                <br /> da cultura da banana
              </h1>
            </div>
          </Wrapper>
        </SubPagesHero>
      </TextureBackground>
      <TextureBackground className="py-20 z-[1]">
        <Wrapper closeFull>
          <LeafAntLeft className="absolute left-0 z-[-1] hidden lg:block opacity-30" />
          <LeafAntRight className="absolute right-0 z-[-1] hidden lg:block opacity-30" />
          <p className="w-9/12 md:w-1/2 mx-auto text-center text-[#5E5E5E] pb-10">
            Uma correta identificação da praga/doença irá permitir melhor
            controlo fitossanitário da sua exploração. Para qualquer
            esclarecimento, consulte os serviços de apoio técnico.
          </p>
          <ul className="flex flex-col sm:flex-row gap-10 items-center sm:items-start">
            <div className="basis-full sm:basis-[calc(50%_-_20px)] lg:basis-[calc(100%_/_3_-_20px)] flex flex-col items-center">
              <img src={EyeSticker} alt="" className="max-h-[150px]" />
              <div className="text-[#172726] mt-5">
                <li className="text-center font-bold text-lg">
                  1. Monitorização e prevenção.
                </li>
              </div>
            </div>
            <div className="basis-full sm:basis-[calc(50%_-_20px)] lg:basis-[calc(100%_/_3_-_20px)] flex flex-col items-center">
              <img src={ShieldSticker} alt="" className="max-h-[150px]" />

              <div className="text-[#172726] mt-5">
                <li className="text-center font-bold text-lg">
                  2. Medidas de proteção.
                </li>
              </div>
            </div>
            <div className="basis-full sm:basis-[calc(50%_-_20px)] lg:basis-[calc(100%_/_3_-_20px)] flex flex-col items-center">
              <img src={LeafSticker} alt="" className="max-h-[150px]" />
              <div className="text-[#172726] mt-5 ">
                <li className="text-center font-bold text-lg">
                  3. Boas práticas fitosanitárias.
                </li>
              </div>
            </div>
          </ul>
          <LeafDecoration className="mx-auto my-10" />
          <div className="text-center">
            <p className="text-[#5E5E5E]  mb-3">
              Nota sobre os fitofarmacêuticos:
            </p>
            <p className="text-[#5E5E5E] w-1/2 mx-auto mb-5">
              Aplicar apenas produtos fitofarmacêuticos homologados/autorizados
              em Portugal e que constem da lista de produtos permitidos em
              proteção integrada da cultura.
            </p>
            <p className="text-[#5E5E5E] w-1/2 mx-auto mb-5 ">
              Registar sempre a aplicação dos produtos fitofarmacêuticos e as
              práticas culturais no caderno de campo e/ou na sua agenda (Ponto
              11. Registo das aplicações de produtos fitofarmacêuticos).
            </p>
            <a
              href="/documents"
              className="underline text-[#89A164] font-light"
            >
              Descarregar lista de fitofarmacêuticos
            </a>
          </div>
        </Wrapper>
      </TextureBackground>
      <GreenSection className="relative z-[1]">
        <LeafLeftYellow className="absolute left-0 z-[-1] hidden lg:block" />
        <LeafRightYellow className="absolute right-0 z-[-1] hidden lg:block" />
        <Wrapper closeFull>
          <div className="flex flex-col lg:flex-row-reverse items-center gap-10">
            <div className="basis-full lg:basis-3/4">
              <h2 className="text-2xl text-[#172726] uppercase font-semibold">
                Nome comum: Tripes(cinzeiro)
              </h2>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Nome científico: frankliniella occidentalis
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  Inseto de cor castanho-escuro, com o corpo delgado e achatado.
                </li>
                <li className="list-disc">
                  Em adulto mede cerca de 1mm de comprimento.
                </li>
                <li className="list-disc">
                  Os ovos são colocados na superfície dos frutos, nas brácteas
                  e/ou nas folhas.
                </li>
                <li className="list-disc">
                  O ciclo de vida é de cerca de 13 dias (o desenvolvimento é
                  diretamente influenciado pela temperatura).
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                problemas/Danos na cultura:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  Danos na superficie dos frutos em desenvolvimento, sob a forma
                  de pontuações negras com relevo ao tato e de manchas com uma
                  aparência prateada característica.
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Como prevenir:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  A retirada de restos florais (pistilos) e a remoção do
                  “coração” reduzem a população destes insetos.
                </li>
              </ul>
            </div>
            <img src={PestsImg} alt="" />
          </div>
        </Wrapper>
      </GreenSection>
      <TextureBackground className="py-36">
        <Wrapper closeFull>
          <div className="flex flex-col lg:flex-row items-center gap-10">
            <div className="basis-full lg:basis-3/4">
              <h2 className="text-2xl text-[#172726] uppercase font-semibold">
                Nome comum: aranhiço (cinzeiro)
              </h2>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Nome científico: Tetraychus urticae
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">Ácaro de cor amarela/alaranjada.</li>
                <li className="list-disc">
                  Na forma adulta, mede cerca de 0,5 mm de comprimento.
                </li>
                <li className="list-disc">
                  Alta capacidade reprodutiva. As fêmeas colocam até 100 ovos na
                  superfície dos frutos e/ou nas folhas.
                </li>
                <li className="list-disc">
                  O ciclo de vida fica completo em cerca de 14 dias (o
                  desenvolvimento é diretamente influenciado pela temperatura).
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                problemas/Danos na cultura:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  Os primeiros sintomas visíveis são pequenas manchas
                  esbranquiçadas. Quando as manchas se fundem, os frutos
                  apresentam uma coloração prateada e por vezes assumem também
                  um tom amarelo ou acastanhado.
                </li>
                <li className="list-disc">
                  As temperaturas altas e o tempo seco levam ao aumento da
                  população.
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Como prevenir:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  A limpeza e a boa manutenção dos bananais previne a propagação
                  desta praga.
                </li>
              </ul>
            </div>
            <img src={PestsImg2} alt="" />
          </div>
        </Wrapper>
      </TextureBackground>
      <GreenSection className="relative z-[1]">
        <LeafLeftYellow className="absolute left-0 z-[-1] hidden lg:block" />
        <LeafRightYellow className="absolute right-0 z-[-1] hidden lg:block" />
        <Wrapper closeFull>
          <div className="flex flex-col lg:flex-row-reverse items-center gap-10">
            <div className="basis-full lg:basis-3/4">
              <h2 className="text-2xl text-[#172726] uppercase font-semibold">
                Nome comum: Gorgulho
              </h2>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Nome científico: Cosmopolites sordidus
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">Escaravelho de cor preta.</li>
                <li className="list-disc">
                  Na forma adulta mede aproximadamente 11 mm de comprimento.
                </li>
                <li className="list-disc">
                  As fêmeas colocam os ovos em torno do rizoma (“raiz”), e após
                  um período de 7 a 10 dias eclodem as larvas que apresentam
                  coloração branca.
                </li>
                <li className="list-disc">
                  As larvas são responsáveis pelos danos causados na planta, ao
                  construírem galerias no rizoma.
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                problemas/Danos na cultura:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  Praga que afeta a cultura da bananeira, de forma bastante
                  expressiva. As galerias feitas pelas larvas enfraquecem a
                  planta, tornando-a mais suscetível ao tombamento. As plantas
                  infestadas apresentam um fraco desenvolvimento do pseudocaule
                  (“tronco”), um amarelecimento precoce das folhas e uma redução
                  no peso do cacho.
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Como prevenir:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  A colocação de armadilhas para captura destes insetos
                  controlam a sua população.
                </li>
              </ul>
            </div>
            <img src={PestsImg3} alt="" />
          </div>
        </Wrapper>
      </GreenSection>
      <TextureBackground className="py-36">
        <Wrapper closeFull>
          <div className="flex flex-col lg:flex-row items-center gap-10">
            <div className="basis-full lg:basis-3/4">
              <h2 className="text-2xl text-[#172726] uppercase font-semibold">
                Nome comum: cochonilha
              </h2>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Nome científico: Dysmicoccus grassii
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  Pequenos insetos brancos, com cerca de 2 a 3 milímetros
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                problemas/Danos na cultura:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  Esta praga enfraquece a planta, bem como deixa marcas na
                  casca, pelo que as bananas não têm valor comercial.
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Como prevenir:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  Para evitar esta praga, deve-se proceder à eliminação das
                  folhas velhas que tapam o engaço. Ter também em atenção a
                  presença de formigas nas bananeiras, que são um indicador da
                  existência de cochonilha.
                </li>
              </ul>
            </div>
            <img src={PestsImg4} alt="" />
          </div>
        </Wrapper>
      </TextureBackground>
      <GreenSection className="relative z-[1]">
        <LeafLeftYellow className="absolute left-0 z-[-1] hidden lg:block" />
        <LeafRightYellow className="absolute right-0 z-[-1] hidden lg:block" />
        <Wrapper closeFull>
          <div className="flex flex-col lg:flex-row-reverse items-center gap-10">
            <div className="basis-full lg:basis-3/4">
              <h2 className="text-2xl text-[#172726] uppercase font-semibold">
                Nome comum: Mal do panamá
              </h2>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Nome científico: Usarium oxysporum f. sp. cubense
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  É uma doença causada por um fungo que habita no solo: Fusarium
                  oxysporum f. sp. cubense, e que apresenta grande capacidade de
                  propagação e de resistência.
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                problemas/Danos na cultura:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  Progressivo amarelecimento das folhas mais velhas para as mais
                  novas, começando pelos bordos do limbo foliar, e evoluindo no
                  sentido da nervura principal. Posteriormente, as folhas
                  murcham, secam e quebram junto ao pseudocaule. Através de um
                  corte transversal do pseudocaule das plantas, observa-se uma
                  descoloração pardo-avermelhada (pontos descoloridos ou uma
                  área periférica das bainhas manchada, com centro sem sintomas)
                  provocada pela presença do fungo. Em fases mais avançadas, os
                  sintomas de descoloração vascular podem ser observados também
                  na nervura principal das folhas.
                </li>
              </ul>
              <h2 className="text-2xl text-[#172726] uppercase font-semibold my-3">
                Como prevenir:
              </h2>
              <ul className="text-[#5E5E5E] ml-5">
                <li className="list-disc">
                  Não existe nenhum produto fitossanitário que permita controlar
                  ou erradicar a doença. Para evitar deverá plantar variedades
                  de bananeiras certificadas. Uma forma de minimizar é dar
                  preferência/proporcionar solos com teores mais elevados de
                  matéria orgânica, o que aumenta a concorrência entre os
                  microrganismos que habitam no solo, dificultando a ação e a
                  sobrevivência do fungo. Outro meio de controlo é evitar a
                  utilização de ferramentas sem qualquer desinfeção entre
                  plantas contaminadas e sãs.
                </li>
              </ul>
            </div>
            <img src={PestsImg5} alt="" />
          </div>
        </Wrapper>
      </GreenSection>
    </>
  );
}
export default Pests;
