import React from "react";
import Wrapper from "../../../resources/components/general/Wrapper";
import { SubPagesHero, TextureBackground } from "../styles/producers_styles";
import HeroBg from "../../../resources/images/producers/conditions_hero.png";

import PlantSticker from "../../../resources/images/producers/plant_sticker.png";
import MedSticker from "../../../resources/images/producers/med_sticker.png";
import DogSticker from "../../../resources/images/producers/dog_sticker.png";
import { ReactComponent as LeafLeftGreen } from "../../../resources/images/producers/leaf_left_green.svg";
import { ReactComponent as LeafRightGreen } from "../../../resources/images/producers/leaf_right_green.svg";

function Conditions() {
  return (
    <>
      <TextureBackground>
        <SubPagesHero herobg={HeroBg} secondary>
          <Wrapper closeFull>
            <div className="relative min-h-[60vh] flex flex-col justify-center items-start ">
              <h1
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
                className="text-4xl md:text-5xl text-white font-bold text-center z-[1] leading-[66px]"
              >
                Condicionalidade
              </h1>
            </div>
          </Wrapper>
        </SubPagesHero>
      </TextureBackground>
      <TextureBackground className="py-20 relative z-[1]">
        <LeafLeftGreen className="absolute left-0 top-0 z-[-1] invisible md:visible" />
        <Wrapper closeFull>
          <h2 className="text-2xl text-[#172726] font-semibold uppercase text-center mb-3">
            Caro bananicultor,
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-center mb-10">
            <div className="basis-1/2">
              <p className="text-center text-[#5E5E5E]">
                Para beneficiar das ajudas candidatas no Pedido Único (PU), fica
                obrigado a cumprir as normas de Boas Condições Agrícolas e
                Ambientais e a respeitar os Requisitos Legais de Gestão das
                explorações agrícolas.
              </p>
            </div>
          </div>
          <ul className="flex flex-col sm:flex-row gap-10 items-center sm:items-start">
            <div className="basis-full sm:basis-[calc(50%_-_20px)] lg:basis-[calc(100%_/_3_-_20px)] flex flex-col items-center">
              <img src={PlantSticker} alt="" className="max-h-[150px]" />
              <div className="text-[#172726] py-5 text-lg">
                <li className="text-center font-bold">
                  1. Ambiente, alterações climáticas e boas condições agrícolas das
                  terras.
                </li>
              </div>
            </div>
            <div className="basis-full sm:basis-[calc(50%_-_20px)] lg:basis-[calc(100%_/_3_-_20px)] flex flex-col items-center">
              <img src={MedSticker} alt="" className="max-h-[150px]" />
              <div className="text-[#172726] py-5 text-lg">
                <li className="text-center font-bold">
                  2. Saúde Pública, saúde animal e fitossanidade.
                </li>
              </div>
            </div>
            <div className="basis-full sm:basis-[calc(50%_-_20px)] lg:basis-[calc(100%_/_3_-_20px)] flex flex-col items-center">
              <img src={DogSticker} alt="" className="max-h-[150px]" />
              <div className="text-[#172726] py-5 text-lg">
                <li className="text-center font-bold">
                  3. Bem-estar dos animais.
                </li>
              </div>
            </div>
          </ul>
          <div className="flex flex-col md:flex-row items-center justify-center my-10">
            <div className="basis-1/2">
              <p className="text-center text-[#000]">
                Para mais informações{" "}
                <a
                  href="https://www.ifap.pt/portal/web/guest/condicionalidade-geral"
                  rel="noopener noreferrer"
                  target={"_blank"}
                  className="font-semibold text-[#89A164] cursor-pointer"
                >
                  clique aqui
                </a>
              </p>
            </div>
          </div>
        </Wrapper>
        <LeafRightGreen className="absolute right-0 top-0 z-[-1] invisible md:visible" />
      </TextureBackground>
    </>
  );
}
export default Conditions;
