import moment from "moment";
import React from "react";
import NewsImg from "../../images/news/news_img.png";
import "moment/locale/pt";
import { useNavigate } from "react-router-dom";

function HighlightsCard({ grid, data, news }) {
  function getParsedDate(dateString) {
    const date = moment(dateString);
    date.locale("pt");
    const formattedDate = date.format("DD.MM.YYYY");

    return formattedDate;
  }
  const navigate = useNavigate();

  return (
    <div
      className={`${
        grid
          ? " md:w-[calc(100%_/_3_-_20px)] sm:basis-auto sm:w-[calc(100%_/_2_-_20px)]"
          : "md:basis-1/2"
      } bg-white box-border z-[1] p-3 basis-full rounded flex flex-col w-full items-start gap-2 h-full`}
    >
      <div className="flex w-full justify-between">
        <p className="text-[#B1B1B1]">
          {data?.published_date
            ? getParsedDate(data?.published_date)
            : getParsedDate(data?.created_at)}
        </p>
      </div>
      <div className="flex flex-col justify-between flex-grow">
        <div className="w-full max-h-[300px] overflow-hidden relative z-[0] after:block after:z-[-1] after:absolute after:bg-gradient-to-t after:from-[#000] after:to-[transparent] after:w-full after:h-1/2 after:bottom-0 after:left-0">
          <img
            src={data?.photo_url ?? NewsImg}
            alt=""
            className="w-full h-[200px] object-cover relative z-[-1]"
          />
          <h3 className="text-white text-lg absolute w-full bottom-4 left-0 px-4 ">
            {data?.name}
          </h3>
        </div>
        <div className="flex flex-col gap-5 mt-4 flex-grow">
          <p className="text-[#172327]">{data?.description}</p>
          <div
            className="z-[0] px-3 py-1 rounded text-white bg-[#89A164] flex items-center self-end max-w-[88px] cursor-pointer"
            onClick={() => {
              navigate(`/highlights/show/${data.id}`, {
                state: { item: data, news: news },
              });
            }}
          >
            ver mais
          </div>
        </div>
      </div>
    </div>
  );
}
export default HighlightsCard;
