import React from "react";
import Chart from "react-apexcharts";

const LineGraph = ({ dates, series }) => {
  const options = {
    colors: ["#CD4547", "#3C6D52", "#26368C", "#B1B1B1"],
    chart: {
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: dates,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toLocaleString("pt");
        },
        style: {
          fontSize: "16px",
          fontWeight: 600,
        },
      },
    },
    legend: {
      fontSize: "16px",
      fontWeight: "600",
      itemMargin: {
        horizontal: 40,
        vertical: 0,
      },
    },
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <h1 className="text-xl font-bold mb-4 uppercase">
        Evolução da quantidade
      </h1>
      <Chart
        options={options}
        series={series}
        type="area"
        height={400}
        className="w-10/12"
      />
    </div>
  );
};

export default LineGraph;
