import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../../resources/components/general/BackButton";
import NewCard from "../../resources/components/general/NewCard";
import Wrapper from "../../resources/components/general/Wrapper";
import Divider from "../../resources/images/home/page-divider.png";
import NewsHero from "../../resources/images/news/news_hero.png";
import { ReactComponent as LeafBG } from "../../resources/images/news/palm_bg.svg";
import { TextureBackground } from "./styles/news_style";
import "moment/locale/pt";
import axiosConfig from "../../providers/axiosConfig";

function NewsDetails() {
  // const [loading, setLoading] = useState(false);
  const [randomItems, setRandomItems] = useState();
  const [allNews, setAllNews] = useState();
  const { state } = useLocation();
  const item = state?.item;
  // const allNews = state?.news;

  function getParsedDate(dateString) {
    const date = moment(dateString);
    date.locale("pt");
    const formattedDate = date.format("DD MMM YYYY");

    return formattedDate;
  }

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    // setLoading(true);
    axiosConfig.get(`/news`).then((res) => {
      setAllNews(res.data);
      // setLoading(false);
    });
    // .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    let filtered = allNews?.filter(
      (info) =>
        info.news_category_id === item.news_category_id && info.id !== item.id
    );
    setRandomItems(filtered?.sort(() => 0.5 - Math.random()).slice(0, 3));
  }, [item, allNews]);

  return (
    <>
      <div className="w-full relative overflow-hidden z-[1] pb-10">
        <img
          src={item?.photo_url ?? NewsHero}
          alt=""
          className="absolute object-cover w-full h-full"
        />
        <Wrapper closeFull>
          <div className="flex flex-col h-full justify-between mt-[100px] mb-[100px] gap-52">
            <BackButton className={"text-white relative"} />
          </div>
        </Wrapper>
      </div>

      <TextureBackground className="relative z-[1] pb-10">
        <img
          src={Divider}
          alt=""
          className="absolute top-[-15px] z-[-1] -rotate-180 w-full"
        />
        <Wrapper closeFull>
          <div className="flex flex-col gap-3 z-30 py-0 md:py-10">
            <h3 className="uppercase font-medium text-black">
              {item?.news_category}
            </h3>
            <h2 className="font-semibold uppercase text-3xl w-10/12 text-black">
              {item?.title}
            </h2>
          </div>
          <div className="flex flex-col md:flex-row gap-20">
            <div className="basis-3/4">
              <div className="border-b border-b-[#D9D9D9] mb-3">
                <p>{item?.author}</p>
                <p className="mb-3">
                  {item?.published_date
                    ? getParsedDate(item?.published_date)
                    : getParsedDate(item?.created_at)}
                </p>
              </div>
              <p className="text-xl font-medium mb-10 text-justify">
                {isJsonString(item?.lead)
                  ? JSON.parse(item?.lead)
                      .split(/[\r\n]+/)
                      .map((paragraph, index) => {
                        return (
                          <p key={index} className="mb-4">
                            {paragraph}
                          </p>
                        );
                      })
                  : item?.lead}
              </p>

              <p className="text-lg text-[#707070] text-justify whitespace-pre-line">
                {isJsonString(item?.body)
                  ? JSON.parse(item?.body)
                      .split(/[\r\n]+/)
                      .map((paragraph, index) => {
                        return (
                          <p key={index} className="mb-4">
                            {paragraph}
                          </p>
                        );
                      })
                  : item?.body}
              </p>
            </div>
            <div className="flex flex-col basis-2/6 overflow-auto">
              <h2 className="uppercase font-semibold mb-5">
                Notícias Relacionadas
              </h2>
              {randomItems &&
                randomItems.length > 0 &&
                randomItems.map((item, index) => {
                  return <NewCard data={item} news={allNews} key={index} />;
                })}
            </div>
          </div>
        </Wrapper>
        <LeafBG className="absolute z-[-1] bottom-0 right-0 opacity-40 origin-bottom-right scale-75" />
      </TextureBackground>
    </>
  );
}

export default NewsDetails;
