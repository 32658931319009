import React from "react";
import Wrapper from "../../../resources/components/general/Wrapper";
import {
  GreenSection,
  SubPagesHero,
  TextureBackground,
} from "../styles/producers_styles";
import HeroBg from "../../../resources/images/producers/correctives_hero.png";

import FertilizerSticker from "../../../resources/images/producers/fertilizer_sticker.png";
import CorrectivesImg from "../../../resources/images/producers/correctives_img.png";
import { ReactComponent as BananaTree } from "../../../resources/images/producers/banana_tree_green.svg";
import { ReactComponent as LeafYellow } from "../../../resources/images/producers/leaf_right_yellow.svg";
function Correctives() {
  return (
    <>
      <TextureBackground>
        <SubPagesHero herobg={HeroBg}>
          <Wrapper closeFull>
            <div className="relative min-h-[60vh] flex flex-col justify-center items-start ">
              <h1
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
                className="text-5xl text-white font-bold text-center z-[1] leading-[66px]"
              >
                Corretivos do solo
              </h1>
            </div>
          </Wrapper>
        </SubPagesHero>
      </TextureBackground>
      <TextureBackground className="py-20 z-[1]">
        <BananaTree className="absolute left-0 top-0 z-[-1] hidden lg:block opacity-50" />
        <BananaTree className="absolute right-0 top-0 z-[-1] hidden lg:block opacity-50" />
        <Wrapper closeFull>
          <div className="flex flex-col md:flex-row items-start">
            <div className="basis-full flex flex-col items-center">
              <img src={FertilizerSticker} alt="" />
              <p className="text-[#5E5E5E] w-full md:w-1/2 text-center">
                Os corretivos de solo são compostos pouco solúveis, com baixo
                custo, mas bastante eficazes. Contribuem para corrigir o pH dos
                solos (índice que mede a acidez, neutralidade ou a
                alcalinidade).
              </p>
            </div>
          </div>
        </Wrapper>
      </TextureBackground>
      <GreenSection>
        <Wrapper className={"relative z-[1] "} closeFull>
          <LeafYellow className="absolute right-0  z-[-1]" />
          <div className="flex flex-col lg:flex-row-reverse items-center gap-5">
            <div className="basis-full lg:basis-3/4">
              <h2 className="text-2xl text-[#172726] font-semibold uppercase mb-3">
                Cultura da bananeira e propriedades
              </h2>
              <div className="text-lg">
                <ul className="text-[#5E5E5E] mb-5 pl-5">
                  <li className="list-disc">
                    A cultura da bananeira desenvolve-se preferencialmente em
                    solos com pH compreendido entre 6,0 e 6.5.
                  </li>
                  <li className="list-disc">
                    Antes de aplicar um corretivo, deve primeiro fazer análises
                    de solo (intervalo mínimo de 4 anos).
                  </li>
                  <li className="list-disc">
                    De acordo com os resultados obtidos é selecionado o
                    corretivo de solo mais adequado e calculada a quantidade a
                    aplicar.
                  </li>
                </ul>
                <p className="text-[#5E5E5E] font-semibold">
                  Se o pH do solo é superior a 7,0 (alcalino):
                </p>
                <ul className="text-[#5E5E5E] mb-5 pl-5">
                  <li className="list-disc">
                    Incorporar corretivos que reduzam a alcalinidade do solo
                    (ex: enxofre)
                  </li>
                </ul>
                <p className="text-[#5E5E5E] font-semibold">
                  Se o pH do solo é inferior a 7,0 (ácido):
                </p>
                <ul className="text-[#5E5E5E] mb-5 pl-5">
                  <li className="list-disc">
                    Incorporar corretivos que neutralizem (diminuam ou eliminem)
                    a acidez do solo (exemplo: calcário).
                  </li>
                </ul>
              </div>
            </div>
            <img src={CorrectivesImg} alt="" />
          </div>
        </Wrapper>
      </GreenSection>
    </>
  );
}
export default Correctives;
