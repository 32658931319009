import React from "react";
import { ReactComponent as LeafBG } from "../../resources/images/news/palm_bg.svg";
import { TextureBackground } from "../producers/media/styles/media_style";
import Wrapper from "../../resources/components/general/Wrapper";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import Ipma from "../../resources/images/home/ipma.png";

const CustomAccordionDetails = styled(AccordionDetails)`
  > p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #3b3d4b;
    line-height: 27px;
  }
`;

const CustomSummary = styled(AccordionSummary)`
  color: #1c6c5e;
  font-size: 18px;
  font-weight: 600;
`;

const CustomSubTitle = styled("div")`
  font-weight: 400;
  h3 {
    color: #1c6c5e;
    font-size: 24px;
  }
  p {
    color: #3b3d4b;
    font-size: 18px;
    line-height: 30px;
  }
`;
const CustomAccordion = styled(Accordion)`
  box-shadow: none;
  border-radius: 8px;
  &::before {
    background-color: transparent;
  }
`;

function Terms() {
  const navigate = useNavigate();

  return (
    <>
      <TextureBackground className="min-h-[70vh] w-full relative overflow-hidden z-[1] font-prompt">
        <Wrapper closeFull>
          <div className="flex flex-col md:flex-row h-full gap-10 md:gap-20 justify-between mt-[100px] md:mt-[150px]">
            <div className="w-full flex flex-col gap-3 md:gap-10 pt-5">
              <p
                className="font-semibold uppercase text-sm w-10/12 text-[#1c6c5e] md:whitespace-nowrap opacity-50 cursor-pointer"
                onClick={() => navigate("/privacy")}
              >
                Política de Privacidade
              </p>
              <p
                className="font-semibold uppercase text-sm w-10/12 text-[#1c6c5e]  md:whitespace-nowrap cursor-pointer"
                onClick={() => navigate("/terms")}
              >
                Termos de serviços
              </p>
            </div>
            <div className="flex flex-col gap-3 pt-5 pb-10">
              <h2 className="font-semibold uppercase text-4xl w-10/12 text-[#1c6c5e]">
                Termos de Serviço
              </h2>
              <p className="font-semibold uppercase text-sm w-10/12 text-[#1c6c5e]">
                Atualizado a 7 de março de 2023
              </p>

              <CustomSubTitle className="mt-5 flex flex-col gap-5">
                <h3 className="font-normal text-2xl w-10/12">Geral</h3>
                <p className="text-lg font-medium">
                  Ao aceder ou utilizar o website do WWW.GESBA.PT, o Cliente
                  reconhece que leu, compreendeu e está de acordo em vincular-se
                  aos presentes Termos de Utilização, bem como em agir em
                  conformidade com as normas legais aplicáveis. Se o Cliente não
                  concordar com os presentes Termos de Utilização, não deverá
                  utilizar este website
                </p>
                <p className="text-lg font-medium">
                  Em qualquer altura e sem aviso prévio, a empresa GESBA –
                  Empresa de Gestão do Sector da Banana, Lda., pode rever os
                  presentes Termos de Utilização e qualquer outra informação
                  constante deste website.
                </p>
              </CustomSubTitle>

              <CustomAccordion className="z-[1] mt-5">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Uso de Licença</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    O conteúdo deste website, incluindo marcas, desenhos,
                    logótipos, texto, imagens, materiais áudio e de vídeo, é
                    propriedade da GESBA – Empresa de Gestão do Sector da
                    Banana, Lda., a menos que indicado de outro modo através de
                    uma referência à sua fonte, e está protegido nos termos
                    gerais de direito e pela legislação nacional e internacional
                    em matéria de proteção da propriedade intelectual. Não é
                    permitido exibir, reproduzir, distribuir, modificar,
                    transmitir ou usar o conteúdo deste website de forma alguma,
                    para qualquer finalidade pública ou comercial, sem o prévio
                    e expresso consentimento por escrito da GESBA – Empresa de
                    Gestão do Sector da Banana, Lda.
                  </p>
                  <p>
                    No entanto, o utilizador pode imprimir qualquer excerto de
                    material contido neste website desde que o mesmo não seja
                    alterado de forma alguma, seja para uso pessoal e
                    não-comercial, contemplando a indicação da fonte e do
                    titular dos direitos de autor, e desde que outra indicação
                    de propriedade não seja removida.
                  </p>
                  <p>
                    As informações e software do website da GESBA – Empresa de
                    Gestão do Sector da Banana, Lda., estão protegidos por
                    direitos de autor. É concedida ao Cliente uma licença para
                    visualizar, temporária e individualmente, o website da GESBA
                    – Empresa de Gestão do Sector da Banana, Lda. A visualização
                    individual e temporária do website da GESBA – Empresa de
                    Gestão do Sector da Banana, Lda é de natureza não comercial
                    (por exemplo, o Cliente não poderá vender o conteúdo ao qual
                    acede neste ou através deste website). É proibida qualquer
                    outra utilização deste website
                  </p>
                  <p>
                    A licença para a visualização, temporária e individual, do
                    website da GESBA – Empresa de Gestão do Sector da Banana,
                    Lda., está sujeita ao cumprimento das seguintes obrigações
                    pelo Cliente:{" "}
                    <ol className="list-decimal ml-7">
                      <li>
                        Não modificar, remover ou copiar as informações,
                        materiais e software contido no website da GESBA –
                        Empresa de Gestão do Sector da Banana, Lda;
                      </li>
                      <li>
                        Garantia de que a utilização do website é feita apenas
                        para a visualização autorizada do Cliente e garantia de
                        que cumpre a licença;
                      </li>
                      <li>
                        Não realizar qualquer engenharia inversa, descompilar,
                        desmontar, analisar ou aceder ou derivar o código-fonte
                        do software contido no website da GESBA – Empresa de
                        Gestão do Sector da Banana, Lda., nos termos da lei;
                      </li>
                      <li>
                        Não sublicenciar, ceder ou transferir a licença de
                        visualização individual e temporária para outro servidor
                        ou website.
                      </li>
                    </ol>
                  </p>
                  <p>
                    Exceto em situações que abrangem o direito de autor descrito
                    anteriormente, a empresa detentora da GESBA – Empresa de
                    Gestão do Sector da Banana, Lda., não concede ao Cliente
                    quaisquer outras licenças ou direitos explícitos ou
                    implícitos sobre direitos de propriedade intelectual. O
                    Cliente não pode reproduzir o conteúdo deste website noutro
                    website ou em qualquer outro suporte.
                  </p>
                  <p>
                    O incumprimento por parte do Cliente destes Termos de
                    Utilização ou de qualquer termos constantes deste website
                    resultará na cessação automática de todos os direitos que
                    lhe tenham sido concedidos, sem aviso prévio, pelo que o
                    Cliente terá de destruir imediatamente todas as cópias de
                    materiais descarregados que estejam na sua posse ou sob a
                    sua guarda ou controlo.
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Exclusão de responsabilidade</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    Periodicamente, o website da GESBA – Empresa de Gestão do
                    Sector da Banana, Lda., poderá conter imprecisões técnicas
                    ou erros de tipografia, pelo que a empresa detentora da
                    GESBA – Empresa de Gestão do Sector da Banana, Lda., não
                    garante a exatidão de qualquer informação publicada neste
                    website.
                  </p>
                  <p>
                    Se qualquer termo dos presentes Termos de Utilização for
                    considerado, de qualquer forma, inválido por uma autoridade
                    judicial competente, a validade dos restantes Termos de
                    Utilização permanece inalterada, desde que tal vício não
                    afete materialmente os direitos das partes ao abrigo dos
                    presentes
                  </p>

                  <h5 className="text-[#1C6C5E] font-medium">IPMA</h5>

                  <div className="flex justify-between items-center mb-5">
                    <p className="text-[#3b3d4b] leading-[27px] text-base">
                      O website da GESBA utiliza dados metereológicos do{" "}
                      <a
                        href={"https://www.ipma.pt/pt/index.html/"}
                        target={"_blank"}
                        rel="noopener noreferrer"
                        className=""
                      >
                        IPMA
                      </a>
                      , sobre os quais o mesmo não tem qualquer controlo e pelos
                      quais não assume qualquer responsabilidade. Todo o
                      conteúdo fornecido pelo{" "}
                      <a
                        href={"https://www.ipma.pt/pt/index.html/"}
                        target={"_blank"}
                        rel="noopener noreferrer"
                        className=""
                      >
                        IPMA
                      </a>{" "}
                      é pertencente ao mesmo.
                    </p>
                    <a
                      href={"https://www.ipma.pt/pt/index.html/"}
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="basis-1/12"
                    >
                      <img src={Ipma} alt="ipma" />
                    </a>
                  </div>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomSubTitle className="mt-5 flex flex-col gap-5">
                <h3 className="font-normal text-2xl w-10/12">
                  Termos de Utilização
                </h3>
              </CustomSubTitle>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Informações confidenciais</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    A empresa detentora do website da GESBA – Empresa de Gestão
                    do Sector da Banana, Lda., não pretende receber quaisquer
                    informações confidenciais ou que sejam propriedade do
                    Cliente através deste website. O Cliente deverá ter em
                    atenção que quaisquer informações ou materiais enviados para
                    a GESBA – Empresa de Gestão do Sector da Banana, Lda., serão
                    considerados não confidenciais.
                  </p>
                  <p>
                    As informações de identificação pessoal que o Cliente possa
                    fornecer à GESBA – Empresa de Gestão do Sector da Banana,
                    Lda., são tratadas em conformidade com a nossa Política de
                    Privacidade. Consulte o separador denominado{" "}
                    <span className="underline">“Política Privacidade”</span>{" "}
                    para obter informações relativas à nossa Política de
                    Privacidade.
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Renúncia da Garantia</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    A utilização deste website é da inteira responsabilidade do
                    Cliente. Todas as informações e software são
                    disponibilizados “no estado em que se encontram” sem
                    quaisquer garantias. A empresa detentora da GESBA – Empresa
                    de Gestão do Sector da Banana, Lda., rejeita expressamente e
                    até ao limite máximo permitido por lei, todas as garantias
                    ou declarações, explícitas, implícitas, incluindo, mas não
                    se limitando, às garantias de comercialização, adequação a
                    um determinado fim e não infração de direitos de propriedade
                    intelectual. A empresa detentora da GESBA – Empresa de
                    Gestão do Sector da Banana, Lda., não garante, nomeadamente,
                    o funcionamento ininterrupto, atempado, seguro e isento de
                    erros deste website.
                  </p>
                  <p>
                    O Cliente está ciente e aceita que, se descarregar ou
                    obtiver, de qualquer outra forma, materiais, informações ou
                    software, a partir deste website, o faz a seu critério
                    exclusivo e inteira responsabilidade, sendo o único
                    responsável por quaisquer danos que possam surgir, incluindo
                    a perda de dados ou danos ocorridos no sistema informático.
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Limitação de Responsabilidade</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    Até ao limite máximo permitido pela lei aplicável, em caso
                    algum a empresa detentora da GESBA – Empresa de Gestão do
                    Sector da Banana, Lda., será responsável perante qualquer
                    terceiro por quaisquer danos diretos ou indiretos, incluindo
                    lucros cessantes, relacionados com ou decorrentes deste
                    website ou da sua utilização, ou de qualquer website,
                    referenciado ou acedido através deste website (se
                    aplicável).
                  </p>
                  <p>
                    Esta limitação de responsabilidade aplica-se a todos os
                    fundamentos de ação, quer sejam um contrato, uma garantia,
                    factos ilícitos ou quaisquer outros fundamentos
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <CustomAccordion className="z-[1]">
                <CustomSummary
                  expandIcon={<ExpandMoreIcon style={{ fill: "#1C6C5E" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>Lei aplicável</h3>
                </CustomSummary>
                <CustomAccordionDetails>
                  <p>
                    Estes Termos de Utilização são regidos e interpretados de
                    acordo com a Lei portuguesa. Todos os litígios serão
                    sujeitos à jurisdição exclusiva do tribunal da área de
                    Lisboa, com exclusão de qualquer outro, para dirimir
                    quaisquer conflitos que resultem da interpretação e
                    aplicação dos presentes Termos de Utilização.
                  </p>
                </CustomAccordionDetails>
              </CustomAccordion>

              <a
                href="/files/Termos_e_condicoes_Gesba.pdf"
                target="_blank"
                rel="noreferrer"
                className="font-semibold uppercase text-sm w-10/12 text-[#1c6c5e] my-5 md:my-10"
              >
                Consultar documento original
              </a>
            </div>
          </div>
        </Wrapper>

        <LeafBG className="absolute bottom-0 right-0 opacity-40 origin-bottom-right scale-75" />
      </TextureBackground>
    </>
  );
}

export default Terms;
