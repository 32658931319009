import styled from "styled-components";
import PaintTop from "../../../resources/images/about/PaintTop.svg";
import SummerTop from "../../../resources/images/about/SummerTop.png";
import TextureBG from "../../../resources/images/home/bg_texture.png";
import GreenBottom from "../../../resources/images/home/divider_green_bottom.svg";
import GreenTop from "../../../resources/images/home/divider_green_top.svg";
import Hero_Bottom_Banner from "../../../resources/images/producers/rules_hero_overlay.png";
import AnnHeroBg from "../../../resources/images/production/announce_hero.png";
import DarkBottom from "../../../resources/images/production/darkgreen_bottom.svg";
import DarkTop from "../../../resources/images/production/darkgreen_top.svg";
import HeroBg from "../../../resources/images/production/prod_hero.png";

export const ProdHero = styled("div")`
  width: 100%;
  min-height: 50vh;
  background-image: url(${HeroBg});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;
export const AnnounceHero = styled("div")`
  width: 100%;
  min-height: 50vh;
  background-image: url(${AnnHeroBg});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;
export const ProdHeroCaption = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
`;
export const TextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;
`;

export const ProdTextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 0;
    transform: rotate(180deg);
    background-image: url(${SummerTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const SummerSeasonBackground = styled("div")`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff4cf;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    background-image: url(${SummerTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const WinterSeasonBackground = styled("div")`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #e8e7e3;
  position: relative;
  z-index: 0;
`;

export const GreenTextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;
`;

export const TextureBackgroundLight = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;
  /* z-index: 0; */

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e8e7e3;
    opacity: 0.5;
    z-index: -1;
  }
`;
export const ProdData = styled("div")`
  width: 100%;
  background-color: transparent;
  position: relative;
  min-height: 50vh;
  z-index: 1;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    background-image: url(${PaintTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const PaymentSection = styled("div")`
  width: 100%;
  background-color: #1c6c5e;
  position: relative;
  padding: 100px 0;
  z-index: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: -1;
    background-image: url(${GreenTop});
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -70px;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: -1;
    background-image: url(${GreenBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const AnnounceSection = styled("div")`
  width: 100%;
  height: 100%;
  background-color: #172726;
  min-height: 50vh;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    background-image: url(${DarkTop});
    top: -70px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100px;
    background-image: url(${DarkBottom});
    bottom: -50px;
    left: 0;
    transform: rotate(180deg);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  }
`;

export const ProductSection = styled("div")`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  position: relative;
  z-index: 1;
`;
export const SubPagesHero = styled("div")`
  width: 100%;
  min-height: 70vh;
  position: relative;
  mask-image: url(${Hero_Bottom_Banner});
  mask-position: 35% 100%;
  mask-repeat: no-repeat;
  z-index: 2;
  &::before {
    content: "";
    background: url(${(props) => props.herobg});
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -50px;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
  }
  &::after {
    content: "";
    background: ${(props) =>
      props.secondary
        ? "linear-gradient(346.31deg, #C5DBA4 28.31%, #C5DBA4 28.31%, rgba(197, 219, 164, 0) 69.06%);"
        : "linear-gradient(0deg,#c5dba4 0%, rgba(197, 219, 164, 0) 100%)"};
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
  }

  @media (min-width: 1024px) {
    mask-size: 150%;
  }
`;
