import React from "react";

function Map() {
  return (
    <div style={{ height: "200px" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3359.1483383199015!2d-16.957854448949195!3d32.65549649738509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xc605f9f9829ce59%3A0x3f81e6aba392d6f9!2sGesba%20-%20Empresa%20De%20Gest%C3%A3o%20Do%20Sector%20Da%20Banana%20Lda!5e0!3m2!1sen!2spt!4v1678037469904!5m2!1sen!2spt"
        width="100%"
        height="100%"
        title="Google embed map"
        style={{ border: 0, borderRadius: "5px" }}
        allowfullscreen
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;
