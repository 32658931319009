import styled from "@emotion/styled";
import { ExpandLess } from "@mui/icons-material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { MenuItem, Select } from "@mui/material";
import {
  LocalizationProvider,
  MonthCalendar,
  YearCalendar,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/pt";
import { useEffect, useState } from "react";
import AnimatedNumbers from "react-animated-numbers";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import axiosConfig from "../../providers/axiosConfig";
import PaymentCard from "../../resources/components/general/PaymentCard";
import SpinningLogo from "../../resources/components/general/SpinningLogo";
import Wrapper from "../../resources/components/general/Wrapper";
import { ReactComponent as Leaf } from "./../../resources/images/contacts/flying_leaf.svg";
import { ReactComponent as CloudIcon } from "./../../resources/images/production/cloud.svg";
import { ReactComponent as SunIcon } from "./../../resources/images/production/sun.svg";
import BananaType from "./BananaType";
import LineGraph from "./LineGraph";
import PriceTableList from "./PriceTableList";
import {
  PaymentSection,
  ProdData,
  ProdHero,
  ProdHeroCaption,
  ProdTextureBackground,
  SummerSeasonBackground,
  WinterSeasonBackground,
} from "./styles/production_styles";

const SeasonIconContainer = styled.div`
  svg {
    filter: drop-shadow(1px 3px 2px rgb(0 0 0 / 0.4));
  }
`;

const TableTitle = styled("h1")`
  color: #1c6c5e;
  font-size: 25px;
  font-weight: 500;
  line-height: 38px;
`;

const OverflowDiv = styled("div")`
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff4cfaa;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fff4cf;
  }
`;

function Production() {
  const [pricing, setPricing] = useState();
  const [productionPayments, setProductionPayments] = useState();
  const [yearInfo, setYearInfo] = useState();
  const [totalValue, setTotalValue] = useState();
  // const [variationValue, setVariationValue] = useState();
  const [centersData, setCentersData] = useState();
  const [toogleCenters, setToogleCenters] = useState(true);
  const [productionLink, setProductionLink] = useState();

  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const centerColors = [
    "bg-[#F2BB36]",
    "bg-[#89A164]",
    "bg-[#172726] text-white",
    "bg-[#FBD7B3]",
  ];

  const categoriesColors = [
    "bg-[#CD4547] text-white",
    "bg-[#3C6D52] text-white",
    "bg-[#26368C] text-white",
    "bg-[#B1B1B1]",
  ];

  const graphColors = ["#F2BB36", "#89A164", "#172726", "#FBD7B3"];
  const categoriesGraphColors = ["#CD4547", "#3C6D52", "#26368C", "#B1B1B1"];
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [dates, setDates] = useState([]);
  const [series, setSeries] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    axiosConfig.get(`/productionInformation`).then((res) => {
      setPricing(res.data?.pricings);
      setProductionPayments(res.data?.payment_productions);
      setProductionLink(res.data?.productionLink);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!moment(startDate).isSame(moment(endDate))) {
      setLoading2(true);
      let start = moment(startDate).format("YYYY/MM/DD");
      let end = moment(endDate).format("YYYY/MM/DD");

      axiosConfig
        .get(`/banana-acquisition/total?start_date=${start}&end_date=${end}`)
        .then((res) => {
          setTotalValue(res.data?.total);
          setYearInfo(res.data);
          if (!Array.isArray(res.data.period)) {
            setDates(Object.values(res?.data?.period));
          } else {
            setDates(res.data.period);
          }
          setSeries(res?.data?.series);
          setLoading2(false);
        });

      axiosConfig
        .get(
          `/banana-acquisitions?filter={"start_date":"${start}","end_date":"${end}"}`
        )
        .then((res) => {
          setCentersData(res.data);
          setLoading2(false);
        });
    }
  }, [startDate, endDate]);

  function handleToogleCenter(annual = false) {
    if (annual) {
      setToogleCenters(false);
    } else {
      setToogleCenters(true);
    }
  }

  useEffect(() => {
    if (!loading && !loading2 && centersData && yearInfo) {
      let hash = window.location.hash;

      if (hash) {
        let doc = document.getElementById(hash.substring(1));
        window.scrollTo({
          behavior: "smooth",
          top: doc.offsetTop,
        });
      }
    }
  }, [loading, loading2, centersData, yearInfo]);

  return (
    <>
      <ProdHero>
        <ProdHeroCaption>
          <h1
            style={{
              textShadow: "5px 5px 10px rgba(0,0,0,0.3)",
            }}
            className="text-5xl text-white font-bold uppercase"
          >
            Gesba em números
          </h1>
        </ProdHeroCaption>
        <Wrapper>
          <div className="absolute bottom-12 left-[50%] translate-x-[-50%] w-full"></div>
        </Wrapper>
      </ProdHero>
      <ProdTextureBackground>
        <ProdData className="py-20" id="acquisitions">
          <Wrapper>
            <h3 className="text-[#172726] text-2xl font-bold uppercase py-5">
              Produção de banana
            </h3>
            <div className="flex w-full justify-center md:justify-between py-2 px-5 flex-wrap md:flex-nowrap gap-5 ">
              <div className="flex border-8 bg-[#D9D9D9] border-[#D9D9D9] rounded-lg">
                <div className="flex bg-white rounded-lg font-prompt">
                  <p
                    className={`cursor-pointer px-5 py-3 basis-1/2 ${
                      toogleCenters
                        ? "text-[#172726] font-semibold shadow-xl rounded-lg"
                        : "text-[#E8E7E3] font-semibold opacity-80"
                    }`}
                    onClick={() => handleToogleCenter()}
                  >
                    Centros
                  </p>
                  <p
                    className={`cursor-pointer px-5 py-3 basis-1/2 ${
                      toogleCenters
                        ? "text-[#E8E7E3] font-semibold opacity-80"
                        : "text-[#172726] font-semibold shadow-xl rounded-lg"
                    }`}
                    onClick={() => handleToogleCenter(true)}
                  >
                    Categorias
                  </p>
                </div>
              </div>
              <OverflowDiv className="flex px-5 justify-between flex-col-reverse lg:flex-row gap-4">
                <div className="flex gap-3 flex-col md:flex-row w-fit md:w-auto items-end md:items-center">
                  <div className="items-center flex gap-1">
                    <DateFilter
                      months={months}
                      setStartDate={setStartDate}
                      startDate={startDate}
                      setEndDate={setEndDate}
                      endDate={endDate}
                    />
                  </div>
                </div>
              </OverflowDiv>
            </div>

            {loading2 ? (
              <div className="my-10">
                <div className="w-fit mx-auto">
                  <SpinningLogo />
                </div>
              </div>
            ) : (
              <>
                {toogleCenters ? (
                  <div className="flex flex-col gap-5 justify-center my-5 md:flex-row md:flex-wrap lg:flex-nowrap">
                    {centersData &&
                      centersData?.map((record, index) => {
                        return (
                          <div
                            className="flex justify-between gap-7 basis-full md:basis-2/5 lg:basis-1/4"
                            key={index}
                          >
                            <div className="flex flex-col justify-between flex-grow bg-white shadow-sm rounded-b">
                              <div className="flex flex-col items-center gap-3 flex-grow">
                                <div className="flex flex-col gap-4 items-center px-8 py-3 flex-grow">
                                  <div>
                                    <p className="text-xl font-semibold uppercase text-center flex-grow">
                                      {record?.collection_center?.name}
                                    </p>
                                    <p className="text-lg font-medium uppercase text-center flex-grow">
                                      {record?.collection_center?.description}
                                    </p>
                                  </div>
                                  <span className="text-[#172327] font-medium text-[15px]">
                                    Total
                                  </span>
                                  <div className="max-h-20 w-36">
                                    <CircularProgressbarWithChildren
                                      value={record?.percentage?.total}
                                      strokeWidth={12}
                                      maxValue={100}
                                      circleRatio={0.5}
                                      styles={buildStyles({
                                        rotation: 0.75,
                                        textSize: "16px",
                                        pathColor: graphColors[index],
                                        trailColor: "#E7E7E7",
                                      })}
                                    >
                                      <div
                                        style={{
                                          fontSize: 20,
                                          marginTop: -25,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {(
                                          Math.round(
                                            (record?.percentage?.total +
                                              Number.EPSILON) *
                                              100
                                          ) / 100
                                        )
                                          .toLocaleString("pt-PT", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                          .replace(/\.(\d)$/, ".0$1")
                                          .replace(
                                            /(\.[0-9]{2})[0-9]*$/,
                                            "$1"
                                          )}{" "}
                                        %
                                      </div>
                                    </CircularProgressbarWithChildren>
                                  </div>
                                </div>

                                <div
                                  className={`${centerColors[index]} w-full text-center font-semibold text-xl py-2`}
                                >
                                  <div className="flex gap-2 justify-center">
                                    <span>
                                      <AnimatedNumbers
                                        locale="pt"
                                        includeComma={true}
                                        animateToNumber={record?.total?.total}
                                        fontStyle={{ fontSize: 32 }}
                                        configs={(number, index) => {
                                          return {
                                            mass: 1,
                                            tension: 230 * (index + 1),
                                            friction: 140,
                                          };
                                        }}
                                      />
                                    </span>

                                    <span>Kg</span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <BananaType
                                  color="#CD4547"
                                  kg={record?.total.quantity_extra}
                                  label1="EXTRA"
                                  label2=""
                                  percentage={record?.percentage.quantity_extra}
                                />
                                <BananaType
                                  color="#3C6D52"
                                  kg={record?.total.quantity_cat1}
                                  label1="CAT.I"
                                  label2=""
                                  percentage={record?.percentage.quantity_cat1}
                                />
                                <BananaType
                                  color="#26368C"
                                  kg={record?.total.quantity_cat2}
                                  label1="CAT.II"
                                  label2=""
                                  percentage={record?.percentage.quantity_cat2}
                                />
                                <BananaType
                                  color="#B1B1B1"
                                  kg={record.total.quantity_berries_cat1}
                                  label1="BAGOS"
                                  label2="CAT.I"
                                  percentage={
                                    record.percentage.quantity_berries_cat1
                                  }
                                />
                              </div>
                              {/* <div className="flex flex-col justify-center mx-auto py-2 gap-1">
                                <div
                                  className="text-sm text-center"
                                  style={{
                                    color:
                                      record?.variation?.total > 0
                                        ? "#1C6C5E"
                                        : "#FF4949",
                                  }}
                                >
                                  {new Intl.NumberFormat("pt").format(
                                    record?.variation?.total
                                  )}{" "}
                                  KG (
                                  <span>
                                    {(
                                      Math.round(
                                        (record?.variation_percentage?.total +
                                          Number.EPSILON) *
                                          100
                                      ) / 100
                                    )
                                      .toLocaleString("pt-PT", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                      .replace(/\.(\d)$/, ".0$1")
                                      .replace(
                                        /(\.[0-9]{2})[0-9]*$/,
                                        "$1"
                                      )}{" "}
                                    %
                                  </span>
                                  ) *
                                </div>
                              </div> */}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div className="flex flex-col gap-5 justify-center my-5 md:flex-row md:flex-wrap lg:flex-nowrap">
                    {Object.keys(yearInfo).map((key, index) => {
                      const category = (key) => {
                        switch (key) {
                          case "quantity_extra":
                            return "EXTRA";
                          case "quantity_cat1":
                            return "Categoria I";
                          case "quantity_cat2":
                            return "Categoria II";
                          case "quantity_berries_cat1":
                            return "Bagos CAT.I";
                          default:
                            return "EXTRA";
                        }
                      };

                      if (
                        key !== "total" &&
                        key !== "variation" &&
                        key !== "variation_percentage" &&
                        key !== "period" &&
                        key !== "series"
                      )
                        return (
                          <div
                            className="flex justify-between gap-7 basis-full md:basis-2/5 lg:basis-1/4"
                            key={index}
                          >
                            <div className="flex flex-col justify-between flex-grow bg-white shadow-sm rounded-b">
                              <div className="flex flex-col items-center gap-3 flex-grow">
                                <div className="flex flex-col gap-4 items-center px-8 py-3 flex-grow">
                                  <span className="text-xl font-semibold uppercase text-center flex-grow">
                                    {category(key)}
                                  </span>
                                  <span className="text-[#172327] font-medium text-[15px]">
                                    Total
                                  </span>
                                  <div className="max-h-20 w-36">
                                    <CircularProgressbarWithChildren
                                      value={yearInfo[key]}
                                      strokeWidth={12}
                                      maxValue={
                                        yearInfo.total > 0 ? yearInfo.total : 1
                                      }
                                      circleRatio={0.5}
                                      styles={buildStyles({
                                        rotation: 0.75,
                                        textSize: "16px",
                                        pathColor: categoriesGraphColors[index],
                                        trailColor: "#E7E7E7",
                                      })}
                                    >
                                      <div
                                        style={{
                                          fontSize: 20,
                                          marginTop: -25,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {yearInfo.total > 0
                                          ? Math.round(
                                              ((yearInfo[key] /
                                                yearInfo?.total) *
                                                100 +
                                                Number.EPSILON) *
                                                100
                                            ) / 100
                                          : 0}{" "}
                                        %
                                      </div>
                                    </CircularProgressbarWithChildren>
                                  </div>
                                </div>

                                <div
                                  className={`${categoriesColors[index]} w-full text-center font-semibold text-xl py-2`}
                                >
                                  <div className="flex gap-2 justify-center">
                                    <span>
                                      <AnimatedNumbers
                                        locale="pt"
                                        includeComma={true}
                                        animateToNumber={yearInfo[key]}
                                        fontStyle={{ fontSize: 32 }}
                                        configs={(number, index) => {
                                          return {
                                            mass: 1,
                                            tension: 230 * (index + 1),
                                            friction: 140,
                                          };
                                        }}
                                      />
                                    </span>

                                    <span>Kg</span>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="flex flex-col justify-center mx-auto py-2 gap-1"> */}
                              {/* <span className="uppercase font-semibold text-sm">
                                  Variação {selectedYear - 1} - {selectedYear}
                                </span> */}
                              {/* <div
                                  className="text-sm text-center"
                                  style={{
                                    color:
                                      yearInfo?.variation[key] > 0
                                        ? "#1C6C5E"
                                        : "#FF4949",
                                  }}
                                >
                                  {new Intl.NumberFormat("pt").format(
                                    yearInfo?.variation[key]
                                  )}{" "}
                                  KG (
                                  <span>
                                    {(
                                      Math.round(
                                        (yearInfo?.variation_percentage[key] +
                                          Number.EPSILON) *
                                          100
                                      ) / 100
                                    )
                                      .toLocaleString("pt-PT", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                      .replace(/\.(\d)$/, ".0$1")
                                      .replace(
                                        /(\.[0-9]{2})[0-9]*$/,
                                        "$1"
                                      )}{" "}
                                    %
                                  </span>
                                  ) *
                                </div> */}
                              {/* </div> */}
                            </div>
                          </div>
                        );
                      return null;
                    })}
                  </div>
                )}
                <div className="flex flex-col items-center justify-center mt-10">
                  <div className="bg-white shadow-md rounded-lg">
                    <div className="flex items-center bg-[#1C6C5E] py-2 px-5 rounded-lg gap-5">
                      <p className="uppercase text-white">total</p>
                      <h2
                        id="animated-numbers"
                        className="text-white font-semibold text-2xl flex gap-3 items-center"
                      >
                        <AnimatedNumbers
                          locale="pt"
                          includeComma={true}
                          animateToNumber={totalValue}
                          fontStyle={{ fontSize: 32 }}
                          configs={(number, index) => {
                            return {
                              mass: 1,
                              tension: 230 * (index + 1),
                              friction: 140,
                            };
                          }}
                        />
                        <span className="font-semibold uppercase text-sm">
                          Kg
                        </span>
                      </h2>
                    </div>
                    {/* <p className="flex gap-5 mt-3 px-3 font-semibold justify-center">
                      <div className="flex flex-col items-center">
                        <p> * Variação </p>
                        <span className="uppercase font-semibold text-center">
                          (
                          {moment(startDate)
                            .subtract(1, "year")
                            .format("MMM YYYY")}{" "}
                          -{" "}
                          {moment(endDate)
                            .subtract(1, "year")
                            .format("MMM YYYY")}
                          )
                        </span>
                        <span className="uppercase font-semibold text-center -my-1">
                          <KeyboardArrowDownIcon />
                        </span>
                        <span className="uppercase font-semibold text-center">
                          ({moment(startDate).format("MMM YYYY")} -{" "}
                          {moment(endDate).format("MMM YYYY")})
                        </span>
                      </div>
                    </p> */}
                    {/* <p className="flex justify-center gap-5 font-semibold">
                      <span
                        className={`${
                          variationValue?.value > 0
                            ? "text-[#0267B6]"
                            : "text-[#FF4949]"
                        }`}
                      >
                        {variationValue?.value
                          ? new Intl.NumberFormat("pt").format(
                              variationValue?.value
                            )
                          : 0}{" "}
                        KG
                      </span>

                      <span
                        className={`${
                          variationValue?.percentage > 0
                            ? "text-[#0267B6]"
                            : "text-[#FF4949]"
                        }`}
                      >
                        {variationValue?.percentage
                          ? (
                              Math.round(
                                (variationValue?.percentage + Number.EPSILON) *
                                  100
                              ) / 100
                            )
                              .toLocaleString("pt-PT", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              .replace(/\.(\d)$/, ".0$1")
                              .replace(/(\.[0-9]{2})[0-9]*$/, "$1")
                          : 0}{" "}
                        %
                      </span>
                    </p> */}
                  </div>
                </div>
                {!toogleCenters &&
                  moment(endDate).diff(moment(startDate), "days") > 31 && (
                    <div className="flex mt-10 items-center justify-center w-full">
                      <LineGraph dates={dates} series={series} />
                    </div>
                  )}
              </>
            )}
          </Wrapper>
        </ProdData>
      </ProdTextureBackground>

      <SummerSeasonBackground id="prices">
        <Wrapper>
          <div className="py-5">
            <h2 className="text-[#172327] text-center text-2xl font-semibold">
              PREÇOS À PRODUÇÃO
            </h2>
            <h3 className="text-[#172327] text-center text-xl uppercase mb-5">
              BANANA DA MADEIRA
            </h3>
            <div className="flex flex-col gap-5  justify-center items-center mb-5 ">
              <div className="flex w-full justify-center my-5">
                <div className="flex flex-col justify-center gap-10 lg:gap-20">
                  {loading ? (
                    <div className="my-10">
                      <div className="w-fit mx-auto">
                        <SpinningLogo />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex flex-row gap-5 my-5 items-center">
                        <TableTitle>{`Época de Verão`}</TableTitle>
                        <SeasonIconContainer>
                          <SunIcon />
                        </SeasonIconContainer>
                      </div>
                      <div className="flex flex-col gap-10">
                        {pricing &&
                          pricing
                            .filter((record) => record.season !== "Inverno")
                            .map((record, index) => {
                              let values = record?.price_table_extra?.fields;
                              let parsedValues;
                              if (values) {
                                parsedValues = JSON.parse(values);
                              }
                              return (
                                <PriceTableList
                                  record={record}
                                  extras={parsedValues}
                                  index={index}
                                  reversed={false}
                                  season="summer"
                                />
                              );
                            })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Leaf
            className="absolute -top-5 left-0 z-[-1] rotate-180 scale-75"
            color="#00000080"
          />
        </Wrapper>
      </SummerSeasonBackground>

      <WinterSeasonBackground>
        <Wrapper className={"relative"}>
          <div className="py-5">
            <div className="flex flex-col gap-5  justify-center items-center mb-5 ">
              <div className="flex w-full justify-center my-5">
                <div className="flex flex-col justify-center gap-10 lg:gap-20">
                  {loading ? (
                    <div className="my-10">
                      <div className="w-fit mx-auto">
                        <SpinningLogo />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex flex-row gap-5 my-5 items-center">
                        <TableTitle>{`Época de Inverno`}</TableTitle>
                        <SeasonIconContainer>
                          <CloudIcon />
                        </SeasonIconContainer>
                      </div>
                      <div className="flex flex-col gap-10">
                        {pricing &&
                          pricing
                            .filter((record) => record.season === "Inverno")
                            .map((record, index) => {
                              let values = record?.price_table_extra?.fields;
                              let parsedValues;
                              if (values) {
                                parsedValues = JSON.parse(values);
                              }
                              return (
                                <PriceTableList
                                  record={record}
                                  extras={parsedValues}
                                  index={index}
                                  reversed={true}
                                />
                              );
                            })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Leaf
            className="absolute bottom-5 left-0 z-[-1] -rotate-45 scale-75"
            color="#00000080"
          />
        </Wrapper>
      </WinterSeasonBackground>

      <PaymentSection id="payments">
        <Wrapper>
          <h2 className="text-white text-2xl uppercase font-semibold">
            pagamento à produção
          </h2>
          <h3 className="text-white text-xl my-10 uppercase">
            Próximos Pagamentos
          </h3>
          {loading ? (
            <div className="my-10">
              <div className="w-fit mx-auto">
                <SpinningLogo />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-8">
              <div className="flex w-full gap-8 flex-wrap">
                {productionPayments &&
                  productionPayments.slice(-4).map((item, index) => {
                    return <PaymentCard data={item} index={index} />;
                  })}
              </div>
              {productionLink && productionLink.length > 0 && (
                <a
                  href={productionLink[0].doc_url}
                  className="flex gap-1 text-white self-end"
                >
                  <p>
                    Consulte a área de documentação para descarregar o mapa
                    completo
                  </p>
                  <KeyboardArrowRightIcon />
                </a>
              )}
            </div>
          )}
        </Wrapper>
      </PaymentSection>
    </>
  );
}
export default Production;

function DateFilter({ startDate, endDate, months, setStartDate, setEndDate }) {
  const [expanded, setExpanded] = useState(false);
  const [acquisitionYears, setYears] = useState([]);
  const [calendarView, setCalendarView] = useState("year");

  useEffect(() => {
    axiosConfig
      .get(`/banana-acquisition/years`)
      .then((res) => {
        let aux = res.data
          .filter((year) => year >= 2015)
          .sort()
          .reverse();
        setYears(aux);
        if (aux[0]) {
          setStartDate(
            moment(`${aux[0]}/01/01`).startOf("year").format("YYYY/MM/DD")
          );
          setEndDate(
            moment(`${aux[0]}/01/01`).endOf("year").format("YYYY/MM/DD")
          );
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="overflow-visible relative z-10 w-fit max-h-16">
      {expanded && (
        <div
          onClick={() => {
            setExpanded(false);
          }}
          className="fixed w-screen h-screen left-0 top-0 z-10"
        />
      )}
      <div className="bg-[#D9D9D9] rounded-md p-2 z-30 relative">
        <div className="bg-[#FAFAFA] border-2 border-transparent rounded shadow-md p-3 cursor-pointer">
          <div className="flex" onClick={() => setExpanded(!expanded)}>
            <div>
              <CalendarTodayIcon />
            </div>
            <div className="flex-grow mx-5 flex justify-center">
              <span className="text-[#172726] font-semibold uppercase">
                {calendarView === "month"
                  ? moment(startDate).format("MMMM") +
                    " " +
                    moment(startDate).format("YYYY")
                  : calendarView === "year"
                  ? moment(startDate).format("YYYY")
                  : calendarView === "between" &&
                    moment(startDate).format("MMM YYYY") +
                      " - " +
                      moment(endDate).format("MMM YYYY")}
              </span>
            </div>
            <div>{!expanded ? <ExpandMoreIcon /> : <ExpandLess />}</div>
          </div>
          {expanded && (
            <>
              <div className="flex left-0 border-2 border-[transparent] flex-col w-full overflow-visible">
                <div className="rounded bg-white flex my-2">
                  <div
                    onClick={() => setCalendarView("month")}
                    className={`cursor-pointer uppercase font-extrabold basis-1/2 flex justify-center p-2 ${
                      calendarView === "month"
                        ? "text-white bg-[#1C6C5E] rounded-l"
                        : "text-[#A0A8B1]"
                    }`}
                  >
                    Mensal
                  </div>
                  <div
                    onClick={() => setCalendarView("year")}
                    className={`cursor-pointer uppercase font-extrabold basis-1/2 flex justify-center p-2 ${
                      calendarView === "year"
                        ? "text-white bg-[#1C6C5E]"
                        : "text-[#A0A8B1]"
                    }`}
                  >
                    Anual
                  </div>
                  {/* <div
                    onClick={() => setCalendarView("between")}
                    className={`cursor-pointer uppercase font-extrabold basis-1/3 flex justify-center p-2 ${
                      calendarView === "between"
                        ? "text-white bg-[#1C6C5E] rounded-r"
                        : "text-[#A0A8B1]"
                    }`}
                  >
                    Entre
                  </div> */}
                </div>
                <LocalizationProvider
                  dateFormats={{
                    monthShort: "MMMM",
                  }}
                  dateAdapter={AdapterMoment}
                  adapterLocale="pt"
                >
                  {calendarView === "month" ? (
                    <div className="w-full">
                      <div className="w-full flex flex-row items-center justify-center gap-3">
                        <div
                          className={`${
                            acquisitionYears.includes(
                              parseInt(moment(startDate).format("YYYY")) - 1
                            )
                              ? "cursor-pointer"
                              : "cursor-default"
                          }`}
                          onClick={() => {
                            if (
                              acquisitionYears.includes(
                                parseInt(moment(startDate).format("YYYY")) - 1
                              )
                            ) {
                              setStartDate(
                                moment(startDate).subtract(1, "years")
                              );
                              setEndDate(moment(endDate).subtract(1, "years"));
                            }
                          }}
                        >
                          <ArrowLeftIcon
                            style={{
                              color: acquisitionYears.includes(
                                moment(startDate).format("YYYY") - 1
                              )
                                ? "#7F8695"
                                : "#E2E8F0",
                            }}
                          />
                        </div>
                        <span className="text-[#7F8695] font-extrabold">
                          {moment(startDate).format("YYYY")}
                        </span>
                        <div
                          className={`${
                            acquisitionYears.includes(
                              parseInt(moment(startDate).format("YYYY")) + 1
                            )
                              ? "cursor-pointer"
                              : "cursor-default"
                          }`}
                          onClick={() => {
                            if (
                              acquisitionYears.includes(
                                parseInt(moment(startDate).format("YYYY")) + 1
                              )
                            ) {
                              setStartDate(moment(startDate).add(1, "years"));
                              setEndDate(moment(endDate).add(1, "years"));
                            }
                          }}
                        >
                          <ArrowRightIcon
                            style={{
                              color: acquisitionYears.includes(
                                parseInt(moment(startDate).format("YYYY")) + 1
                              )
                                ? "#7F8695"
                                : "#E2E8F0",
                            }}
                          />
                        </div>
                      </div>
                      <MonthCalendar
                        sx={{
                          "& button.MuiPickersMonth-monthButton": {
                            textTransform: "capitalize",
                            color: "#A0A8B1",
                            width: "fit-content",
                            padding: "5px",
                          },
                          "& button.Mui-selected, & button.MuiPickersMonth-monthButton:focus":
                            {
                              backgroundColor: "#C5DBA4",
                              borderRadius: "5px",
                              color: "#172726",
                            },
                          "& button:hover, & button.MuiPickersMonth-monthButton:hover":
                            {
                              backgroundColor: "#C5DBA440",
                              borderRadius: "5px",
                              color: "#172726",
                            },
                        }}
                        shouldDisableMonth={(month) => {
                          if (
                            moment().isSameOrBefore(
                              moment(startDate).set(
                                "month",
                                month.format("MM") - 1
                              )
                            )
                          )
                            return true;
                          return false;
                        }}
                        onChange={(e) => {
                          setStartDate(
                            moment(startDate)
                              .set("month", e.format("MM") - 1)
                              .startOf("month")
                              .format("YYYY/MM/DD")
                          );
                          setEndDate(
                            moment(endDate)
                              .set("month", e.format("MM") - 1)
                              .endOf("month")
                              .format("YYYY/MM/DD")
                          );
                        }}
                        value={moment(startDate)}
                      />
                    </div>
                  ) : calendarView === "year" ? (
                    <div>
                      <YearCalendar
                        disableFuture
                        minDate={
                          new Date(
                            acquisitionYears[acquisitionYears.length - 1],
                            0,
                            1
                          )
                        }
                        maxDate={new Date(acquisitionYears[0], 0, 1)}
                        sx={{
                          "& button.MuiPickersYears-yearButton": {
                            textTransform: "capitalize",
                            color: "#A0A8B1",
                            width: "fit-content",
                            padding: "5px",
                          },
                          "& button.Mui-selected, & button.Mui-selected:hover, & button.Mui-selected:focus, & button.MuiPickersYears-yearButton:focus":
                            {
                              backgroundColor: "#C5DBA4",
                              borderRadius: "5px",
                              color: "#172726",
                            },
                          "& button:hover, & button.MuiPickersYears-yearButton:hover":
                            {
                              backgroundColor: "#C5DBA440",
                              borderRadius: "5px",
                              color: "#172726",
                            },
                        }}
                        value={moment(startDate)}
                        onChange={(e) => {
                          setStartDate(
                            moment(startDate)
                              .set("year", e.format("YYYY"))
                              .startOf("year")
                              .format("YYYY/MM/DD")
                          );
                          setEndDate(
                            moment(endDate)
                              .set("year", e.format("YYYY"))
                              .endOf("year")
                              .format("YYYY/MM/DD")
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="flex flex-row w-full gap-1">
                        <Select
                          sx={{
                            padding: 0,
                            borderColor: "white",
                            "& fieldset": {
                              border: "1px white solid",
                            },
                          }}
                          size="small"
                          className={`cursor-pointer font-semibold text-xl rounded-sm p-2 flex-grow`}
                          onChange={(e) => {
                            setStartDate(
                              moment(startDate)
                                .set("month", e.target.value)
                                .startOf("month")
                            );
                            if (
                              moment(startDate)
                                .set("month", e.target.value)
                                .startOf("month")
                                .isAfter(moment(endDate))
                            ) {
                              setEndDate(
                                moment(startDate)
                                  .set("month", e.target.value)
                                  .endOf("month")
                              );
                            }
                          }}
                          value={moment(startDate).format("M") - 1}
                        >
                          {months && months?.length > 0 ? (
                            months.map((month, index) => (
                              <MenuItem
                                className="bg-transparent font-semibold"
                                key={index}
                                value={index}
                              >
                                {month}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem className="bg-transparent font-semibold">
                              Janeiro
                            </MenuItem>
                          )}
                        </Select>

                        <Select
                          sx={{
                            padding: 0,
                            borderColor: "white",
                            "& fieldset": {
                              border: "1px white solid",
                            },
                          }}
                          size="small"
                          className={`cursor-pointer font-semibold text-xl rounded-sm p-2`}
                          onChange={(e) => {
                            setStartDate(
                              moment(startDate)
                                .set("year", e.target.value)
                                .startOf("month")
                            );
                            if (
                              moment(startDate)
                                .set("year", e.target.value)
                                .startOf("month")
                                .isAfter(moment(endDate))
                            ) {
                              setEndDate(
                                moment(startDate)
                                  .set("year", e.target.value)
                                  .endOf("month")
                              );
                            }
                          }}
                          value={moment(startDate).format("YYYY")}
                        >
                          {acquisitionYears && acquisitionYears.length > 0 ? (
                            acquisitionYears.map((year, index) => (
                              <MenuItem
                                className="bg-transparent font-semibold"
                                key={index}
                                value={year}
                              >
                                {year}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem className="bg-transparent font-semibold">
                              2023
                            </MenuItem>
                          )}
                        </Select>
                      </div>

                      <p className="text-[#A0A8B1] flex justify-center my-2">
                        Até
                      </p>
                      <div className="flex flex-row gap-1">
                        <Select
                          sx={{
                            padding: 0,
                            borderColor: "white",
                            "& fieldset": {
                              border: "1px white solid",
                            },
                          }}
                          size="small"
                          className={`cursor-pointer font-semibold text-xl rounded-sm p-2 flex-grow`}
                          onChange={(e) => {
                            setEndDate(
                              moment(endDate)
                                .set("month", e.target.value)
                                .endOf("month")
                            );

                            if (
                              moment(endDate)
                                .set("month", e.target.value)
                                .endOf("month")
                                .isBefore(moment(startDate))
                            ) {
                              setStartDate(
                                moment(startDate)
                                  .set("month", e.target.value)
                                  .startOf("month")
                              );
                            }
                          }}
                          value={moment(endDate).format("M") - 1}
                        >
                          {months && months?.length > 0 ? (
                            months.map((month, index) => (
                              <MenuItem
                                className="bg-transparent font-semibold"
                                key={index}
                                value={index}
                              >
                                {month}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem className="bg-transparent font-semibold">
                              Janeiro
                            </MenuItem>
                          )}
                        </Select>
                        <Select
                          sx={{
                            padding: 0,
                            borderColor: "white",
                            "& fieldset": {
                              border: "1px white solid",
                            },
                          }}
                          size="small"
                          className={`cursor-pointer font-semibold text-xl rounded-sm p-2`}
                          onChange={(e) => {
                            setEndDate(
                              moment(endDate)
                                .set("year", e.target.value)
                                .endOf("month")
                            );
                            if (
                              moment(endDate)
                                .set("year", e.target.value)
                                .endOf("month")
                                .isBefore(moment(startDate))
                            ) {
                              setStartDate(
                                moment(startDate)
                                  .set("year", e.target.value)
                                  .startOf("month")
                              );
                            }
                          }}
                          value={moment(endDate).format("YYYY")}
                        >
                          {acquisitionYears && acquisitionYears.length > 0 ? (
                            acquisitionYears.map((year, index) => (
                              <MenuItem
                                className="bg-transparent font-semibold"
                                key={index}
                                value={year}
                              >
                                {year}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem className="bg-transparent font-semibold">
                              2022
                            </MenuItem>
                          )}
                        </Select>
                      </div>
                    </div>
                  )}
                </LocalizationProvider>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
