import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import React from "react";
import { useInView } from "react-intersection-observer";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import styled from "styled-components";
import Wrapper from "../../resources/components/general/Wrapper";
import CertifiedSticker from "../../resources/images/about/about_certify.png";
import AboutImg from "../../resources/images/about/about_img.png";
import QualitySticker from "../../resources/images/about/about_quality.png";
import SchoolSticker from "../../resources/images/about/about_school.png";
import TeachSticker from "../../resources/images/about/about_teach.png";
import GlobalChart from "../../resources/images/about/global_chart.svg";
import GlobalLogo from "../../resources/images/about/global_logo.svg";
import HistoryScroll from "../../resources/images/about/history_scroll.png";
import MissionImg from "../../resources/images/about/mission_img.png";
import VisionImg from "../../resources/images/about/vision_img.png";
import GesbaLogo from "../../resources/images/home/Logo.png";
import { ReactComponent as LeafBG } from "../../resources/images/news/palm_bg.svg";
import TenisLogo from "../../resources/logo/ATMAD 1.png";
import CiclismoLogo from "../../resources/logo/Associação de ciclismo da Madeira 1.png";
import NacionalLogo from "../../resources/logo/C.D._Nacional_logo 1.png";
import MaritimoLogo from "../../resources/logo/Logo_CS_Maritimo 1.png";
import AtletismoLogo from "../../resources/logo/Logotipo-AARAM_2017 1.png";
import RunLogo from "../../resources/logo/RUN porto 1.png";
import LiceuLogo from "../../resources/logo/ceo-liceu-logo.png";
import MaioLogo from "../../resources/logo/clube-desportivo-1maio 1.png";
import CmofLogo from "../../resources/logo/cmof.png";
import JardimLogo from "../../resources/logo/jardimLogo 1.png";
import NavalLogo from "../../resources/logo/logo-rodape 1.png";
import MaratonLogo from "../../resources/logo/maraton  2.png";
import MotociclismoLogo from "../../resources/logo/motociclismo.png";
import RugbyLogo from "../../resources/logo/rugby 1.png";
import TodoTerrenoLogo from "../../resources/logo/todo-terreno.jpg";
import { DarkTextureBackground } from "../contactos/styles/contactos_styles";
import {
  AboutHero,
  AboutHeroCaption,
  AboutUS,
  Administration,
  CalltoAction,
  HistorySection,
  Responsability,
  TextureBackground,
  Values,
} from "./styles/about_styles";

const OverflowDiv = styled("div")`
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* width */
  ::-webkit-scrollbar {
    /* width: 10px; */
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff4cfaa;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff4cf;
  }
`;

const MyTimelineElement = styled(VerticalTimelineElement)`
  .vertical-timeline-element-date {
    order: 1;
  }
  .vertical-timeline-element-content {
    display: flex;
    flex-direction: column-reverse;
    order: 2;
    box-shadow: none;
  }

  .dateClass {
    span {
      color: #fafafa;
      font-size: 25px;
      line-height: 38px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
`;

const ValuesDiv = styled("div")`
  background: linear-gradient(90deg, #ffd760 0%, #ffb600 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  text-transform: uppercase;
  font-weight: 600;

  > p {
    text-align: center;
  }
`;

function About() {
  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: ref3, inView: inView3 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const { ref: ref5, inView: inView5 } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  const { ref: ref6, inView: inView6 } = useInView({
    threshold: 0.25,
    triggerOnce: true,
  });

  function handleClick(e) {
    e.preventDefault();
    const target = document.querySelector(e.target.getAttribute("href"));
    target.scrollIntoView({
      behavior: "smooth",
    });
  }

  return (
    <>
      <AboutHero>
        <Wrapper>
          <div className="flex flex-col min-h-[70vh] items-center justify-center gap-20 pt-[80px]">
            <div className="flex-grow" />
            <AboutHeroCaption>
              <h4
                className="text-1xl text-white uppercase "
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
              >
                Sobre Nós
              </h4>
              <h1
                className="text-5xl text-white uppercase font-bold"
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
              >
                A GESBA
              </h1>
            </AboutHeroCaption>
            <div className="flex flex-wrap justify-center w-full mt-auto mb-20 gap-10 text-white uppercase invisible sm:visible flex-grow items-end">
              <a href="#aboutus" onClick={handleClick}>
                Quem Somos
              </a>
              <a href="#administration" onClick={handleClick}>
                Administração
              </a>
              <a href="#history" onClick={handleClick}>
                História
              </a>
              <a href="#mission" onClick={handleClick}>
                Missão e Visão
              </a>
              <a href="#responsability" onClick={handleClick}>
                Responsabilidade Social
              </a>
              <a href="#values" onClick={handleClick}>
                Valores
              </a>
            </div>
          </div>
        </Wrapper>
      </AboutHero>
      <TextureBackground className="overflow-hidden">
        <AboutUS id="aboutus">
          <Wrapper full className={"xl:ml-auto"}>
            <div
              className="flex flex-col items-center h-full gap-10 md:flex-row overflow-hidden xl:overflow-visible"
              ref={ref1}
            >
              <div
                className={`basis-1/2 ${
                  inView1 ? "slide-in-left visible" : "invisible"
                }`}
              >
                <img src={AboutImg} alt="" className="lg:ml-[-80px]" />
              </div>
              <div
                className={`flex flex-col basis-1/2 mx-3 md:mx-20 slide-in-right 
                `}
              >
                <h3 className="text-[#172726] text-2xl font-bold uppercase py-5">
                  Quem Somos
                </h3>
                <p className="mb-5 text-justify">
                  A Gesba - Empresa de Gestão do Sector da Banana, Lda., iniciou
                  a sua atividade em setembro de 2008, no âmbito de uma
                  reestruturação do setor da Banana da Madeira, que visava a sua
                  própria sustentabilidade, de forma a assegurar o escoamento e
                  a valorização da produção, bem como o aumento do rendimento
                  dos produtores, substituindo as anteriores estruturas.
                </p>
                <p className="mb-5 text-justify">
                  A reestruturação da empresa permitiu a redução de custos pela
                  integração dos meios operacionais, otimizando-se e
                  maximizando-se a gestão dos recursos financeiros e humanos
                  disponíveis e estabelecer uma política comercial que reforçou
                  e valorizou o posicionamento da marca "Banana da Madeira" face
                  à sua qualidade e excelência, de modo que o setor tenha um
                  crescimento sustentável e contínuo.
                </p>
                <p className="mb-5 text-justify">
                  Permitiu também a modernização do setor e uma maior
                  monitorização de toda a operacionalidade dos centros de
                  acondicionamento de Banana e o pagamento quinzenal à produção.
                </p>
              </div>
            </div>
          </Wrapper>
          <LeafBG className="absolute bottom-0 right-0 origin-bottom-right scale-75 opacity-50" />
        </AboutUS>
      </TextureBackground>
      <DarkTextureBackground noAfter>
        <Administration id="administration" className="py-10">
          <Wrapper right full className={"xl:mr-auto"}>
            <div
              className={`flex flex-col items-center h-full gap-10 lg:flex-row overflow-hidden ${
                inView6 ? "slide-in-right visible" : "invisible"
              }`}
              ref={ref6}
            >
              <div
                className={`flex flex-col mx-3 md:mx-20 my-10 slide-in-left 
                `}
              >
                <h3 className="text-[#172726] text-2xl lg:text-4xl font-bold uppercase pt-5">
                  Gesba
                </h3>
                <h3 className="text-[#172726] text-xl lg:text-3xl font-semibold uppercase pb-5 mt-0">
                  Administração
                </h3>
                <p className="mb-5 text-justify">
                  Seja bem-vindo ao website da GESBA – Empresa de Gestão do
                  Sector da Banana, Lda.
                </p>
                <p className="mb-5 text-justify">
                  Este website resulta e assenta numa das prioridades da
                  empresa, a proximidade com os produtores de Banana da Madeira
                  através de uma comunicação aberta e transparente.
                </p>
                <p className="mb-5 text-justify">
                  Desde 2008, ano em que a GESBA foi criada e iniciou a sua
                  atividade, procuramos dar ao sector um novo rumo, procurando
                  um crescimento estável e sustentável que valorize e dê mais
                  notoriedade ao fruto do trabalho dos produtores da Banana da
                  Madeira. Nos últimos 15 anos a GESBA apostou na formação dos
                  produtores, colaboradores e na modernização do sector, através
                  da requalificação e construção de infraestruturas que
                  permitiram a implementação de certificações de qualidade,
                  garantindo sempre autenticidade e reconhecimento da Banana da
                  Madeira nos mais exigentes mercados, proporcionando o
                  crescimento da produção e o aumento do rendimento dos
                  produtores.
                </p>
                <p className="mb-5 text-justify">
                  Queremos aqui deixar um agradecimento público aos produtores
                  de Banana da Madeira, que são a energia do sector, mas também
                  a todos aqueles que de uma forma ou de outra interagem
                  connosco: colaboradores, clientes, fornecedores, parceiros e
                  amigos, que fazem da GESBA uma empresa de referência, sólida e
                  em evolução constante, para fazer face aos desafios futuros.
                </p>
              </div>
            </div>
          </Wrapper>
        </Administration>
      </DarkTextureBackground>
      <HistorySection id="history">
        <Wrapper>
          <h2 className="text-[#E8E7E3] text-3xl font-semibold uppercase pt-[100px] pb-10 leading-8">
            A nossa história
          </h2>

          <div className="flex">
            <div className="basis-full md:basis-3/6 xl:basis-4/6">
              <OverflowDiv className="overflow-y-scroll max-h-screen h-full mb-[40px]">
                <VerticalTimeline layout="1-column-left">
                  <MyTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{ background: "transparent", color: "#fff" }}
                    contentArrowStyle={{
                      borderRight: "7px solid  transparent",
                    }}
                    date={<span>2008 a 2010</span>}
                    dateClassName="dateClass"
                    iconStyle={{
                      background: "#FFF4CF",
                      color: "#fff",
                      boxShadow: "unset",
                    }}
                    icon={""}
                  >
                    <div className="flex flex-col items-center gap-5">
                      {/* <TimelineItem company={"ilhapneu"} /> */}
                      <div className="text-center">
                        <p className="text-[#FAFAFA] font-medium text-lg uppercase text-left leading-7">
                          Criação da gesba - Empresa de gestão do setor da
                          banana,Lda
                        </p>
                        <p className="text-[#E8E7E3] mt-0 text-justify font-normal text-base leading-7">
                          A Gesba, empresa pública fundada para gerir o setor da
                          Banana da Madeira, iniciou a sua atividade em setembro
                          de 2008, no âmbito de uma reestruturação do setor da
                          Banana da Madeira, que visava a sustentabilidade do
                          setor da banana, de forma a assegurar o escoamento e a
                          valorização da produção, bem como o aumento do
                          rendimento dos produtores, substituindo as anteriores
                          estruturas (cooperativas).
                        </p>
                      </div>
                    </div>
                  </MyTimelineElement>
                  <MyTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{ background: "transparent", color: "#fff" }}
                    contentArrowStyle={{
                      borderRight: "7px solid  transparent",
                    }}
                    date={<span>2008 a 2010</span>}
                    dateClassName="dateClass"
                    iconStyle={{
                      background: "#FFF4CF",
                      color: "#fff",
                      boxShadow: "unset",
                    }}
                    icon={""}
                  >
                    <div className="flex flex-col items-center gap-5">
                      <div className="text-center">
                        <p className="text-[#FAFAFA] font-medium text-lg uppercase text-left">
                          Os primeiros anos - a reestruturação do setor
                        </p>
                        <p className="text-[#E8E7E3] mt-0 text-justify font-normal text-base leading-7">
                          A reestruturação do setor, através da GESBA permitiu a
                          redução de custos pela integração de meios
                          operacionais, otimizando e maximizando a gestão dos
                          recursos financeiros e humanos na altura disponíveis.
                          Com a Gesba, o setor entrou num período de crescimento
                          sustentável e contínuo estabelecendo-se uma política
                          comercial que permitiu e garantiu o escoamento de toda
                          a produção, o pagamento quinzenal aos bananicultores,
                          e um reforço e valorização da marca “Banana da
                          Madeira” face à sua qualidade e excelência e a
                          modernização do setor.
                        </p>
                      </div>
                    </div>
                  </MyTimelineElement>
                  <MyTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{ background: "transparent", color: "#fff" }}
                    contentArrowStyle={{
                      borderRight: "7px solid  transparent",
                    }}
                    date={<span>2011</span>}
                    dateClassName="dateClass"
                    iconStyle={{
                      background: "#FFF4CF",
                      color: "#fff",
                      boxShadow: "unset",
                    }}
                    icon={""}
                  >
                    <div className="flex flex-col items-center gap-5">
                      <div className="text-center">
                        <p className="text-[#FAFAFA] font-medium text-lg uppercase text-left">
                          O Governo Regional cria um sistema de Certificação de
                          Origem Garantida dos Produtos da Região Autónoma da
                          Madeira e a marca Produto da Madeira.
                        </p>
                        <p className="text-[#E8E7E3] mt-0 text-left font-normal text-base leading-7">
                          A Banana da Madeira foi o primeiro produto a integrar
                          esta certificação e a utilizar o selo criado para
                          identificação da marca “Produto Madeira”.
                        </p>
                      </div>

                      <div className="text-center">
                        <p className="text-[#FAFAFA] font-medium text-lg uppercase text-left">
                          Lançamento da primeira grande campanha de promoção da
                          Banana da Madeira.
                        </p>
                        <p className="text-[#E8E7E3] mt-0 text-justify font-normal text-base leading-7">
                          Entre 2011 e 2014, a GESBA integrou o Programa Europeu
                          Transnacional de Informação sobre o Símbolo Gráfico
                          das Regiões Ultraperiféricas (2011-2014), associado às
                          bananas europeias. Desenvolveram-se um conjunto de
                          ações promocionais em Portugal, Bélgica e Alemanha,
                          direcionadas para o setor, o consumidor e a imprensa,
                          permitindo aumentar a notoriedade da Banana da
                          Madeira.
                        </p>
                      </div>
                    </div>
                  </MyTimelineElement>
                  <MyTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{ background: "transparent", color: "#fff" }}
                    contentArrowStyle={{
                      borderRight: "7px solid  transparent",
                    }}
                    date={<span>2016</span>}
                    dateClassName="dateClass"
                    iconStyle={{
                      background: "#FFF4CF",
                      color: "#fff",
                      boxShadow: "unset",
                    }}
                    icon={""}
                  >
                    <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-10">
                      <div className="flex flex-col items-center gap-5">
                        <div className="text-center">
                          <p className="text-[#FAFAFA] font-medium text-lg uppercase text-left">
                            Lançamento da Campanha “Para Mim, a 2ª melhor
                            exportação da Madeira”
                          </p>
                          <p className="text-[#E8E7E3] mt-0 text-justify font-normal text-base leading-7">
                            Esta foi a maior campanha publicitária da Banana da
                            Madeira, tendo estado presente em televisão, mupis,
                            meios e redes digitais e pontos de venda. “Para mim,
                            a segunda melhor exportação da Madeira” foi
                            protagonizada pela mãe de Cristiano Ronaldo, Dolores
                            Aveiro, e teve a assinatura da conceituada agência
                            de comunicação Bar Ogilvy.
                          </p>
                        </div>
                      </div>
                    </div>
                  </MyTimelineElement>
                  <MyTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{
                      background: "transparent",
                      color: "#fff",
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid transparent",
                    }}
                    date={<span>2017</span>}
                    dateClassName="dateClass"
                    iconStyle={{
                      background: "#FFF4CF",
                      color: "#fff",
                      boxShadow: "unset",
                    }}
                    icon={""}
                  >
                    <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-10">
                      <div className="flex flex-col items-center gap-5">
                        <div className="text-center">
                          <p className="text-[#FAFAFA] font-medium text-lg uppercase text-left">
                            Primeiras explorações de Banana da Madeira obtêm
                            certificado GlobalG.A.P.
                          </p>
                          <p className="text-[#E8E7E3] mt-0 text-justify font-normal text-base leading-7">
                            O GlobalG.A.P. é um sistema de boas práticas
                            agrícolas (G.A.P. – Good Agricultural Practices),
                            sendo um referencial reconhecido internacionalmente.
                            Numa primeira fase 100 explorações agrícolas foram
                            certificadas com esse referencial. Atualmente conta
                            com cerca de 650 explorações certificadas, o
                            equivalente a 40% da área total explorada pelos
                            bananicultores da RAM. A certificação inclui um
                            módulo complementar, designado GRASP (Risk
                            Assessment on Social Practice), que avalia as
                            práticas sociais das explorações, abordando aspetos
                            específicos das condições de trabalho e da segurança
                            e bem-estar dos trabalhadores.
                          </p>
                        </div>
                      </div>
                    </div>
                  </MyTimelineElement>
                  <MyTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{ background: "transparent", color: "#fff" }}
                    contentArrowStyle={{
                      borderRight: "7px solid  transparent",
                    }}
                    date={<span>2019</span>}
                    dateClassName="dateClass"
                    iconStyle={{
                      background: "#FFF4CF",
                      color: "#fff",
                      boxShadow: "unset",
                    }}
                    icon={""}
                  >
                    <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-10">
                      <div className="flex flex-col items-center gap-5">
                        <div className="text-center">
                          <p className="text-[#FAFAFA] font-medium text-lg uppercase text-left">
                            Implementação da certificação ISO 22000 - Gestão do
                            Sistema de Segurança Alimentar
                          </p>
                          <p className="text-[#E8E7E3] mt-0 text-justify font-normal text-base leading-7">
                            Desde julho de 2019, o Centro de Processamento de
                            Banana da Ponta do Sol está certificado com a ISO
                            22000 - Gestão do Sistema de Segurança Alimentar,
                            uma importante norma de qualidade. Este Sistema
                            baseia-se nos princípios do HACCP (Análise de
                            Perigos e Pontos Críticos de Controlo),
                            internacionalmente reconhecidos, e que visam
                            garantir a qualidade e segurança do produto, em
                            todas as etapas da cadeia de processamento.
                          </p>
                        </div>
                      </div>
                    </div>
                  </MyTimelineElement>
                  <MyTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{ background: "transparent", color: "#fff" }}
                    contentArrowStyle={{
                      borderRight: "7px solid  transparent",
                    }}
                    date={<span>2022</span>}
                    dateClassName="dateClass"
                    iconStyle={{
                      background: "#FFF4CF",
                      color: "#fff",
                      boxShadow: "unset",
                    }}
                    icon={""}
                  >
                    <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-10">
                      <div className="flex flex-col items-center gap-5">
                        <div className="text-center">
                          <p className="text-[#FAFAFA] font-medium text-lg uppercase text-left">
                            dIA 23 DE JUNHO, FOI InaugURADO O BAM - cENTRO DA
                            BANANA DA MADEIRA.
                          </p>
                          <p className="text-[#E8E7E3] mt-0 text-justify font-normal text-base leading-7">
                            No dia 23 de junho de 2022, foi inaugurado o BAM –
                            Centro da Banana da Madeira. Implantado numa área de
                            cerca de 15 000 m2, o Centro está dotado de vários
                            serviços e estruturas funcionais, que lhe dão as
                            seguintes valências: <br />
                            <p>
                              <span className="underline">
                                Investigação e Qualidade:
                              </span>
                              <br />
                              Estudo/desenvolvimento de novas técnicas e
                              práticas agrícolas que visem a melhoria da
                              qualidade do produto Banana da Madeira.
                            </p>
                            <p>
                              <span className="underline">
                                Apoio técnico e científico nas Certificações da
                                Banana da Madeira:
                              </span>
                              <ul className="list-disc pl-5">
                                <li>GLOBALG.A.P., GRASP</li>
                                <li>Segurança Alimentar ISO 22000</li>
                                <li>Modo de produção Biológico</li>
                                <li>Outras certificações</li>
                              </ul>
                            </p>
                            <p>
                              <span className="underline">
                                Formação e apoio técnico:
                              </span>
                              <br />
                              Ações de formação e workshops para dar a conhecer
                              o trabalho de investigação técnica e científica,
                              bem como demonstrar a sua aplicação prática nas
                              explorações.
                            </p>
                            <p>
                              <span className="underline">
                                Pedagogia ao nível Escolar/Académico e do
                                Turismo:
                              </span>
                              <br />
                              Promover e gerar interesse pelo setor agrícola e
                              em particular pela cultura da banana. O Centro tem
                              uma componente turística e pedagógica, fundamental
                              para promover e a Banana da Madeira, e aumentar o
                              conhecimento sobre as suas especificidades ao
                              nível do cultivo e das suas características tão
                              diferenciadoras, através de dois espaços
                              distintos, mas complementares:
                              <ul className="list-disc pl-5">
                                <li>
                                  Núcleo museológico: exposição permanente que
                                  prima pela componente digital, interativa e
                                  multi-sensorial, onde se aborda a história da
                                  Banana da Madeira e sua importância na Região,
                                  se dá a conhecer o seu valor nutritivo e os
                                  benefícios do produto, e se apresentam os
                                  desafios para o futuro para o setor e o seu
                                  cultivo.
                                </li>
                                <li>
                                  Caminho agrícola: percurso pedestre exterior,
                                  que permite para visitar os viveiros e os
                                  campos experimentais de bananeiras, e aí
                                  assistir aos trabalhos de cultivo (operações
                                  culturais) que estejam a ser realizadas, tais
                                  como a rega, a adução, a colheita e o
                                  transporte dos cachos, entre outras.
                                </li>
                              </ul>
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </MyTimelineElement>
                  <MyTimelineElement
                    className="vertical-timeline-element"
                    contentStyle={{ background: "transparent", color: "#fff" }}
                    contentArrowStyle={{
                      borderRight: "7px solid  transparent",
                    }}
                    date={<span>2023</span>}
                    dateClassName="dateClass"
                    iconStyle={{
                      background: "#FFF4CF",
                      color: "#fff",
                      boxShadow: "unset",
                    }}
                    icon={""}
                  >
                    <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-10">
                      <div className="flex flex-col items-center gap-5">
                        <div className="text-center">
                          <p className="text-[#FAFAFA] font-medium text-lg uppercase text-left">
                            Inauguração do Centro de Processamento de Banana de
                            São Martinho.
                          </p>
                          <p className="text-[#E8E7E3] mt-0 text-justify font-normal text-base leading-7">
                            A aposta na modernização que a Gesba tem vindo a
                            levar a cabo nos últimos anos, e que foi fulcral
                            para o setor, irá ser continuada. Durante o ano de
                            2023, será inaugurado um novo centro de
                            processamento, com uma capacidade de processamento
                            superior às 100 toneladas diárias. Com o aumento da
                            produção de banana em modo de produção biológico,
                            nos últimos anos, fruto de um trabalho conjunto com
                            o setor da produção e comercialização no sentido de
                            serem implementadas práticas sustentáveis em toda a
                            cadeia de produção com um impacto positivo nas
                            explorações e nos ecossistemas envolventes, este
                            Centro terá uma das suas seis linhas de
                            processamento integralmente dedicada à banana
                            produzida em modo de produção biológico.
                          </p>
                        </div>
                      </div>
                    </div>
                  </MyTimelineElement>
                </VerticalTimeline>
              </OverflowDiv>
            </div>
            <div className="basis-0 md:basis-3/6 xl:basis-2/6 flex items-center justify-center">
              <img src={HistoryScroll} alt="História da Banana" />
            </div>
          </div>
        </Wrapper>
      </HistorySection>
      <CalltoAction>
        <Wrapper className={"flex flex-col items-center"}>
          <h2 className="text-[#fff] italic text-xl md:text-2xl xl:text-3xl font-bold uppercase text-center py-5 w-full max-w-screen-md sm:w-2/3 md:w-1/2 xl:w-full">
            "O sucesso é a soma de pequenos esforços repetidos dia após dia."
          </h2>
          <p className="text-[#fff] italic text-xl uppercase">Robert Collier</p>
        </Wrapper>
      </CalltoAction>
      <TextureBackground id="mission">
        <LeafBG className="absolute bottom-1/2 right-0 origin-bottom-right scale-75 opacity-40" />
        {/* <LeafLeftBG className="absolute bottom-1/2 left-0" /> */}
        <Wrapper full>
          <div
            className="flex flex-col items-center h-full gap-10 md:flex-row pt-10 overflow-hidden"
            ref={ref2}
          >
            <div
              className={`flex flex-col basis-1/2 mx-3 md:ml-20  ${
                inView2 ? "slide-in-left visible" : "invisible"
              } `}
            >
              <h3 className="text-[#172726] text-2xl font-bold uppercase py-10 ">
                A nossa missão
              </h3>
              <p className="mb-5 text-justify">
                A GESBA recebe atualmente a produção de mais de 2900
                bananicultores, que se dedicam ao cultivo da “Banana da
                Madeira”, e tem como principal missão a colheita/recolha da
                Banana da Madeira no produtor passando pela classificação,
                certificação, embalamento e preparação para a distribuição e
                comércio.
              </p>
              <p className="mb-5 text-justify">
                Detentora da marca Banana da Madeira, a GESBA está empenhada em
                valorizar o produto e promover o seu consumo e os seus
                benefícios.
              </p>
              <p className="mb-5 text-justify">
                Em novembro de 2017, foram acrescidas competências à GESBA,
                nomeadamente ligadas a atividades de: investigação científica e
                experimentação, viveiristas, formativas na área da agricultura,
                museológicas, culturais e turísticas. Neste ano, a empresa
                passou também a ter por objeto a gestão e comercialização de
                outros produtos que integram o setor primário e agroindustrial
                da Região e que contribuem para a sua valorização, como é o caso
                das frutas tropicais e subtropicais: Abacate e Anona da Madeira.
              </p>
            </div>
            <div
              className={`basis-1/2 hidden sm:flex justify-end ${
                inView2 ? "slide-in-right visible" : "invisible"
              }`}
            >
              <img src={MissionImg} alt="" />
            </div>
          </div>
          <div ref={ref5} className="overflow-hidden max-5 md:ml-20">
            <div
              className={`flex flex-col gap-20 ${
                inView5 ? "slide-in-right visible" : "invisible"
              }`}
            >
              <div className="flex flex-col md:flex-row gap-10 items-center ">
                <img src={QualitySticker} alt="Qualidade" />
                <div className="flex flex-col gap-3 items-center lg:items-start">
                  <h3 className="w-3/4 text-xl text-center lg:text-left font-medium uppercase leading-8">
                    Vetor Qualidade:
                  </h3>
                  <p className="w-3/4 text-[#5E5E5E] text-justify">
                    Investigação e desenvolvimento de técnicas agrícolas que
                    visem a melhoria da qualidade do produto e a sua
                    valorização, preservando a tipicidade e caraterísticas
                    próprias, ao nível do sabor, doçura, tamanho, etc.
                  </p>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-10 items-center lg:ml-20">
                <img src={CertifiedSticker} alt="Qualidade" />
                <div className="flex flex-col gap-3 items-center lg:items-start">
                  <h3 className="w-3/4 text-xl text-center lg:text-left font-medium uppercase leading-8">
                    Vetor Certificação da Banana da Madeira:
                  </h3>
                  <p className="w-3/4 text-[#5E5E5E] text-justify">
                    Apoio técnico e científico nas certificações da Banana da
                    Madeira que visem a valorização do produto, tais como:
                    <ul className="pl-5">
                      <li className="list-disc">
                        Certificação Global G.A.P.® da produção da Banana da
                        Madeira;
                      </li>
                      <li className="list-disc">
                        Certificação de Segurança Alimentar.
                      </li>
                      {/* <li className="list-disc">
                        Denominação de Origem/ Identificação Geográfica;
                      </li> */}
                    </ul>
                  </p>
                  <div className="flex flex-col md:flex-row justify-center w-full">
                    <img src={GlobalLogo} alt="" className="z-[2]" />
                    <img src={GlobalChart} alt="" className="z-[2]" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-10 items-center lg:ml-36">
                <img src={TeachSticker} alt="Qualidade" />
                <div className="flex flex-col gap-3 items-center lg:items-start">
                  <h3 className="w-3/4 text-xl text-center lg:text-left font-medium uppercase leading-8">
                    Vetor formação e apoio técnico:
                  </h3>
                  <p className="w-3/4 text-[#5E5E5E] text-justify">
                    Ações de formação e workshops para dar a conhecer o trabalho
                    de investigação técnico e científico, demonstrar a aplicação
                    prática das técnicas agrícolas e acompanhar a respetiva
                    aplicação nas explorações agrícolas.
                  </p>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-10 items-center lg:ml-56">
                <img src={SchoolSticker} alt="Qualidade" />
                <div className="flex flex-col gap-3 items-center lg:items-start">
                  <h3 className="w-3/4 text-xl text-center lg:text-left font-medium uppercase leading-8">
                    Vetor pedagogia a nível escolar, académico e do turismo
                  </h3>
                  <p className="w-3/4 text-[#5E5E5E] text-justify">
                    Promover e gerar interesse pelo setor agrícola e em
                    particular pela cultura da banana.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
        <Wrapper full className="py-10">
          <div
            className="flex flex-col items-center h-full gap-10 md:flex-row overflow-hidden xl:overflow-visible mb-10"
            ref={ref3}
          >
            <div
              className={`basis-1/2 ${
                inView3 ? "slide-in-left visible" : "invisible"
              }`}
            >
              <img src={VisionImg} alt="" className="lg:ml-[-80px]" />
            </div>
            <div
              className={`flex flex-col basis-1/2 mx-3 md:mr-20 ${
                inView3 ? "slide-in-right visible" : "invisible"
              }`}
            >
              <h3 className="text-[#172726] text-2xl font-bold uppercase py-5">
                a nossa Visão
              </h3>
              <p className="mb-5 text-justify">
                A GESBA desenvolve a sua atividade tendo como visão a
                sustentabilidade do setor da banana na Região Autónoma da
                Madeira, assegurando o escoamento e a valorização da produção,
                bem como criando condições para o aumento do rendimento dos
                produtores.
              </p>
              <p className="mb-5 text-justify">
                Trabalhamos para a contínua melhoria da qualidade da Banana da
                Madeira e para o maior conhecimento sobre as suas
                especificidades ao nível do cultivo e das suas características
                tão diferenciadoras, apostando na formação, na modernização,
                investigação e experimentação, de modo a inovar as técnicas de
                produção e cultivo, transporte e processamento, as quais
                rentabilizarão a produção e, ao mesmo tempo, manterão as
                características tão genuínas da nossa fruta.
              </p>
              <p className="mb-5 text-justify">
                A empresa visa ainda promover a transmissão do conhecimento,
                dando a conhecer a história, as formas de cultivo e um vislumbre
                sobre a cultura da banana, através de um espaço interpretativo /
                museológico, que promove não só o nosso produto, mas também a
                Região.
              </p>
            </div>
          </div>
        </Wrapper>
      </TextureBackground>
      <DarkTextureBackground>
        <Responsability id="responsability" className="py-10">
          <Wrapper full className={"my-10"}>
            <div className="flex flex-col justify-center items-center h-full gap-10 md:flex-row overflow-hidden">
              <div className="flex flex-col md:flex-row justify-between gap-3 items-center">
                <div
                  className={`flex flex-col mx-3 md:mx-20 slide-in-left 
                `}
                >
                  <h3 className="text-[#172726] text-center text-2xl lg:text-4xl font-semibold uppercase py-5">
                    Responsabilidade social da Gesba
                  </h3>
                  <p className="mt-8 text-lg">
                    A GESBA desenvolve a sua atividade tendo sempre presente uma
                    dimensão social, implementando uma cultura organizacional
                    que visa:
                  </p>
                  <ul className="list-disc ml-8 basis-5/12 text-lg">
                    <li>
                      O bem-estar dos seus colaboradores e produtores, através
                      da criação de boas condições de trabalho e receção do
                      produto, do respeito pelos seus direitos e do apoio à
                      formação e desenvolvimento das suas competências,
                      estimulando a valorização pessoal e profissional;
                    </li>
                    <li>
                      A adoção de políticas e boas práticas de produção que
                      promovam a sustentabilidade ambiental e a biodiversidade;
                    </li>
                    <li>
                      O apoio a dezenas de instituições culturais, recreativas e
                      de solidariedade social.
                    </li>
                  </ul>
                </div>
                {/* <div className="w-fit">
                  <img
                    src={Socialresponsability1}
                    alt="Responsabilidade Social 1"
                  />
                </div> */}
              </div>
            </div>
            <div className="flex flex-col my-10 justify-center gap-10 ">
              <h3 className="mx-3 md:mx-20 text-2xl font-normal">
                Instituições que apoiamos:
              </h3>
              <div className="flex flex-col gap-10">
                <Splide
                  options={{
                    start: 0,
                    type: "loop",
                    autoplay: true,
                    autoWidth: true,
                    arrows: false,
                    direction: "rtl",
                    pagination: false,
                    interval: 5000,
                    speed: 30000,
                    gap: 40,
                    lazyLoad: true,
                  }}
                  hasTrack={false}
                  aria-label="..."
                >
                  <SplideTrack>
                    {/* <SplideSlide className="self-center px-10">
                  <img src={FedGolfe} alt="" />
                </SplideSlide> */}
                    <SplideSlide className="self-center px-10">
                      <img src={RugbyLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={CmofLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={NacionalLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={MaritimoLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={MaioLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={LiceuLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={JardimLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={NavalLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={MaratonLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={TenisLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={AtletismoLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={CiclismoLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={RunLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={TodoTerrenoLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={MotociclismoLogo} alt="" />
                    </SplideSlide>
                  </SplideTrack>
                  <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev">
                      Prev
                    </button>
                    <button className="splide__arrow splide__arrow--next">
                      Next
                    </button>
                  </div>
                </Splide>
                <Splide
                  options={{
                    start: 8,
                    type: "loop",
                    autoplay: true,
                    autoWidth: true,
                    arrows: false,
                    pagination: false,
                    interval: 5000,
                    speed: 30000,
                    gap: 40,
                    lazyLoad: true,
                  }}
                  hasTrack={false}
                  aria-label="..."
                >
                  <SplideTrack>
                    <SplideSlide className="self-center px-10">
                      <img src={CmofLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={RugbyLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={NacionalLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={MaritimoLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={MaioLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={LiceuLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={JardimLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={NavalLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={MaratonLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={TenisLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={AtletismoLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={CiclismoLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={RunLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={TodoTerrenoLogo} alt="" />
                    </SplideSlide>
                    <SplideSlide className="self-center px-10">
                      <img src={MotociclismoLogo} alt="" />
                    </SplideSlide>
                  </SplideTrack>
                  <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev">
                      Prev
                    </button>
                    <button className="splide__arrow splide__arrow--next">
                      Next
                    </button>
                  </div>
                </Splide>
              </div>
            </div>
          </Wrapper>
        </Responsability>
      </DarkTextureBackground>
      <CalltoAction>
        <Wrapper className={"flex flex-col items-center"}>
          <h2 className="text-[#fff] italic text-xl md:text-2xl xl:text-3xl font-bold uppercase text-center py-5 w-full max-w-screen-md sm:w-2/3 md:w-2/5">
            "Seja a mudança que você quer ver no mundo"
          </h2>
          <p className="text-[#fff] italic text-xl uppercase">Mahatma Gandhi</p>
        </Wrapper>
      </CalltoAction>
      <TextureBackground>
        <Values id="values" className="py-10">
          <Wrapper full className={"my-10"}>
            <div className="flex flex-col justify-center items-center h-full gap-10 md:flex-row overflow-hidden">
              <div
                className={`flex flex-col ml-10 mr-10 md:mr-20 slide-in-left 
                `}
              >
                <h3 className="text-[#172726] text-center text-2xl lg:text-4xl font-semibold uppercase py-5">
                  Valores da Gesba
                </h3>
                <p className="mb-5 text-center">
                  A GESBA reúne valores que permitem ter um produto de
                  excelência e de grande qualidade
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full my-10">
              <div className="flex md:flex-row flex-col">
                <div className="md:basis-1/3 flex order-1 md:order-1 justify-center">
                  <div className="flex flex-col  gap-10 my-5 w-[250px]">
                    <ValuesDiv>
                      <p>sustentabilidade</p>
                    </ValuesDiv>
                    <ValuesDiv>
                      <p>transparência</p>
                    </ValuesDiv>
                  </div>
                </div>
                <div className="md:basis-1/3 order-2 md:order-2 md:flex justify-center">
                  <img src={GesbaLogo} alt="" className="mx-auto" />
                </div>
                <div className="md:basis-1/3 flex order-3 justify-center">
                  <div className="flex flex-col gap-10 my-5 w-[250px]">
                    <ValuesDiv>
                      <p>qualidade</p>
                    </ValuesDiv>
                    <ValuesDiv>
                      <p>compromisso</p>
                    </ValuesDiv>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:basis-1/3"></div>
                <div className="md:basis-1/3 flex justify-center my-5 w-[250px]">
                  <ValuesDiv>
                    <p>Segurança alimentar</p>
                  </ValuesDiv>
                </div>
                <div className="md:basis-1/3"></div>
              </div>
            </div>
          </Wrapper>
        </Values>
      </TextureBackground>
    </>
  );
}

export default About;
