import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import axiosConfig from "../../../providers/axiosConfig";
import InsuranceCard from "../../../resources/components/general/InsuranceCard";
import Wrapper from "../../../resources/components/general/Wrapper";
import { InsuranceHero, TextureBackground } from "../styles/producers_styles";
import { CircularProgress } from "@mui/material";

function Insurance() {
  const [insurances, setInsurances] = useState();
  const [loading, setLoading] = useState(false);

  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/insurance-and-supports`)
      .then((res) => {
        let alldata = res.data;
        let filteredData = alldata.filter((item) => item.category === "Seguro");
        setInsurances(filteredData);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <>
      <TextureBackground>
        <InsuranceHero className="max-h-[50vh]">
          <Wrapper closeFull>
            <div className="relative min-h-[50vh]">
              <h1 className="text-5xl text-white font-bold absolute top-[50%] -translate-y-[-50%]">
                Seguros
              </h1>
            </div>
          </Wrapper>
        </InsuranceHero>
      </TextureBackground>
      <TextureBackground
        className="pb-10 z-[-3] py-10 overflow-hidden"
        ref={ref1}
      >
        <Wrapper closeFull className={"min-h-min"}>
          <div className="w-full flex justify-center mx-auto">
            <h2 className="text-2xl w-full text-[#707070] font-semibold uppercase mb-5">
              Madeira
            </h2>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pb-10 justify-center ">
            {loading ? (
              <CircularProgress className="mx-auto" sx={{ color: "#1C6C5E" }} />
            ) : insurances && insurances.length > 0 ? (
              insurances.map((item, index) => {
                if (index % 2 !== 0) {
                  return (
                    <div
                      className={`basis-full md:basis-5/12 ${
                        inView1 ? "slide-in-right visible" : "invisible"
                      }`}
                    >
                      <InsuranceCard data={item} />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`basis-full md:basis-5/12 ${
                        inView1 ? "slide-in-left visible" : "invisible"
                      }`}
                    >
                      <InsuranceCard data={item} />
                    </div>
                  );
                }
              })
            ) : (
              <h3 className="text-xl w-full text-[#707070] font-medium uppercase py-5">
                Sem seguros disponíveis de momento
              </h3>
            )}
          </div>
        </Wrapper>
      </TextureBackground>
    </>
  );
}
export default Insurance;
