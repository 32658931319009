import React from "react";
import Wrapper from "../../../resources/components/general/Wrapper";
import { SubPagesHero, TextureBackground } from "../styles/producers_styles";
import HeroBg from "../../../resources/images/producers/quality_hero.png";
import { ReactComponent as BananaTree } from "../../../resources/images/producers/banana_tree.svg";
import QualityImg from "../../../resources/images/producers/quality_img.png";

function Quality() {
  return (
    <>
      <TextureBackground>
        <SubPagesHero herobg={HeroBg}>
          <Wrapper closeFull>
            <div className="relative min-h-[60vh] flex flex-col justify-center items-center ">
              <h1
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
                className="text-4xl md:text-5xl text-white font-bold text-center z-[1] leading-[66px]"
              >
                Qualidade e Segurança Alimentar
              </h1>
            </div>
          </Wrapper>
        </SubPagesHero>
      </TextureBackground>
      <TextureBackground className="py-20">
        <Wrapper closeFull>
          <h2 className="text-2xl text-[#172726] font-semibold uppercase text-center mb-10">
            Produção biológica
            <br />
            <span className="text-xl">Modo de produção Biológica</span>
          </h2>
          <div className="flex flex-col lg:flex-row-reverse items-start relative z-[1] gap-5">
            <div className="basis-full md:basis-3/4">
              <ul>
                <li className="list-disc text-lg">
                  O modo de produção biológico visa a produção de alimentos de
                  elevada qualidade, saudáveis, através de práticas sustentáveis
                  e com um impacto positivo nas explorações e nos ecossistemas
                  envolventes.
                </li>
                <li className="list-disc text-lg">
                  Neste modo de produção apenas estão autorizados, para
                  aplicação na exploração agrícola, adubos de origem natural.
                </li>
              </ul>
            </div>
            <img src={QualityImg} alt="" />
          </div>
        </Wrapper>
        <BananaTree className="absolute -bottom-20 left-1/2 hidden md:block" />
      </TextureBackground>
    </>
  );
}
export default Quality;
