import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import axiosConfig from "../../../providers/axiosConfig";
import InsuranceCard from "../../../resources/components/general/InsuranceCard";
import Wrapper from "../../../resources/components/general/Wrapper";
import { InsuranceHero, TextureBackground } from "../styles/producers_styles";
import { CircularProgress } from "@mui/material";

function Aids() {
  const [aids, setAids] = useState();
  const [loading, setLoading] = useState(false);

  const { ref: ref1, inView: inView1 } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`/insurance-and-supports`)
      .then((res) => {
        let alldata = res.data;
        let filteredData = alldata.filter((item) => item.category === "Apoio");
        setAids(filteredData);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);
  return (
    <>
      <TextureBackground>
        <InsuranceHero className="min-h-min">
          <Wrapper closeFull>
            <div className="relative min-h-[50vh]">
              <h1 className="text-5xl text-white font-bold absolute top-[50%] -translate-y-[-50%]">
                Ajudas
              </h1>
            </div>
          </Wrapper>
        </InsuranceHero>
      </TextureBackground>
      <TextureBackground className="pb-10 overflow-hidden" ref={ref1}>
        <Wrapper closeFull className={"min-h-[30vh]"}>
          <div className="w-full flex justify-center mx-auto pt-5">
            <h2 className="text-2xl w-full text-[#707070] font-semibold uppercase mb-5">
              Madeira
            </h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pb-10 justify-center">
            {loading ? (
              <CircularProgress className="mx-auto" sx={{ color: "#1C6C5E" }} />
            ) : aids && aids.length > 0 ? (
              aids.map((item, index) => {
                if (index % 2 !== 0) {
                  return (
                    <div
                      className={`basis-full md:basis-5/12 ${
                        inView1 ? "slide-in-right" : "invisible"
                      }`}
                    >
                      <InsuranceCard data={item} />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`basis-full md:basis-5/12 ${
                        inView1 ? "slide-in-left" : "invisible"
                      }`}
                    >
                      <InsuranceCard data={item} />
                    </div>
                  );
                }
              })
            ) : (
              <h3 className="text-xl w-full text-[#707070] font-medium uppercase py-5">
                Sem ajudas disponíveis de momento
              </h3>
            )}
          </div>
        </Wrapper>
      </TextureBackground>
    </>
  );
}
export default Aids;
