import styled from "styled-components";
import HeroBg from "../../../resources/images/about/hero_bg.png";
import TextureBG from "../../../resources/images/home/bg_texture.png";
import PaintTop from "../../../resources/images/about/PaintTop.svg";
import PaintBottom from "../../../resources/images/about/PaintBottom.svg";
import GreenTop from "../../../resources/images/home/divider_green_top.svg";
import GreenBottom from "../../../resources/images/home/divider_green_bottom.svg";
import HistoryBG from "../../../resources/images/about/history_bg.png";
import AdminBottom from "../../../resources/images/about/AdminBottom.png";
import ResponsabilityTop from "../../../resources/images/about/ResponsabilityTop.svg";

export const AboutHero = styled("div")`
  width: 100%;
  min-height: 70vh;
  background-position: 50% 25%;
  background-image: url(${HeroBg});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    background-image: url(${PaintTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const AboutHeroCaption = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  gap: 20px;
`;
export const TextureBackground = styled("div")`
  width: 100%;
  background-image: url(${TextureBG});
  position: relative;
`;
export const AboutUS = styled("div")`
  width: 100%;
  background-color: transparent;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  align-items: center;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    transform: rotate(180deg);
    background-image: url(${PaintBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const Administration = styled("div")`
  width: 100%;
  background-color: transparent;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  align-items: center;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    transform: rotate(180deg);
    background-image: url(${AdminBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const Responsability = styled("div")`
  width: 100%;
  background-color: transparent;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    background-image: url(${ResponsabilityTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    transform: rotate(180deg);
    background-image: url(${AdminBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const Values = styled("div")`
  width: 100%;
  background-color: transparent;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    background-image: url(${PaintTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
  /* &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    transform: rotate(180deg);
    background-image: url(${AdminBottom});
    background-size: cover;
    background-repeat: no-repeat;
  } */
`;

export const CalltoAction = styled("div")`
  width: 100%;
  background-color: #1c6c5e;
  position: relative;
  min-height: 30vh;
  display: flex;
  align-items: center;
  z-index: 1;
  & img {
    position: absolute;
    width: 50%;
    right: 0;
  }
  @media (min-width: 1024px) {
    min-height: 50vh;
  }
  @media (min-width: 1280px) {
    & img {
      width: unset;
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    background-image: url(${GreenTop});
    background-size: cover;
    background-repeat: no-repeat;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: -1;
    transform: rotate(180deg);
    background-image: url(${GreenBottom});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
export const HistorySection = styled("div")`
  width: 100%;
  min-height: 100vh;
  background-image: url(${HistoryBG});
  background-size: cover;
  background-repeat: no-repeat;
`;
