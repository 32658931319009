import { useMediaQuery } from "@mui/material";
import React from "react";
// import Animation from "../../video/Gesba_animation.mp4";
// import AnimationMobile from "../../video/Gesba_animation_mobile.mp4";

const Loading = () => {
  const mobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="fixed flex items-center justify-center w-screen h-screen bg-[#172726] z-[9999]">
      <video
        autoPlay
        muted
        className="h-full"
        id="loadingVideo"
        controls={false}
        playsInline
      >
        <source
          src={
            mobile
              ? "/files/video/Gesba_animation_mobile.mp4"
              : "/files/video/Gesba_animation.mp4"
          }
          type="video/mp4"
          style={{ pointerEvents: "none" }}
        />
      </video>
    </div>
  );
};

export default Loading;
