import React, { useEffect, useState } from "react";
import Wrapper from "../../../resources/components/general/Wrapper";
import {
  GreenSection,
  SubPagesHero,
  TextureBackground,
} from "../styles/producers_styles";
import HeroBg from "../../../resources/images/producers/auxiliaries_hero.png";

import InsectSticker from "../../../resources/images/producers/insect_sticker.png";
import Wasp1 from "../../../resources/images/producers/wasp_1.png";
import Wasp2 from "../../../resources/images/producers/wasp_2.png";
import Wasp3 from "../../../resources/images/producers/wasp_3.png";
import Wasp4 from "../../../resources/images/producers/wasp_4.png";
import Spider1 from "../../../resources/images/producers/spider_1.png";
import Spider2 from "../../../resources/images/producers/spider_2.png";
import Spider3 from "../../../resources/images/producers/spider_3.png";

import { ReactComponent as LeafAntRight } from "../../../resources/images/producers/leaf_ant_right_bg.svg";
import { ReactComponent as LeafAntLeft } from "../../../resources/images/producers/leaf_ant_left_bg.svg";
import { ReactComponent as LeafRightYellow } from "../../../resources/images/producers/leaf_right_yellow.svg";
import { ReactComponent as LeafLeftYellow } from "../../../resources/images/producers/leaf_left_yellow.svg";
import axiosConfig from "../../../providers/axiosConfig";

function Auxiliaries() {
  const [documents, setDocuments] = useState();
  useEffect(() => {
    axiosConfig.get(`/documentation/Fitofarmacêuticos`).then((res) => {
      setDocuments(res.data?.documents);
    });
  }, []);
  return (
    <>
      <TextureBackground>
        <SubPagesHero herobg={HeroBg}>
          <Wrapper closeFull>
            <div className="relative min-h-[60vh] flex flex-col justify-center items-start ">
              <h1
                style={{
                  textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
                }}
                className="text-4xl lg:text-5xl text-white font-bold text-center z-[1] leading-[66px]"
              >
                Auxiliares (Referente aos fitofarmacêuticos a respeitar a
                biodiverisidade)
              </h1>
            </div>
          </Wrapper>
        </SubPagesHero>
      </TextureBackground>
      <TextureBackground className="py-20">
        <LeafAntLeft className="absolute left-0 top-0 z-[-1] max-h-[450px] max-w-[450px] hidden sm:block" />
        <Wrapper closeFull>
          <div className="flex flex-col md:flex-row items-start ">
            <div className="basis-full flex flex-col items-center">
              <img src={InsectSticker} alt="" className="max-h-[150px]" />
              <h2 className="text-2xl text-[#172726] font-semibold text-center uppercase mb-3 w-full md:w-1/2 mx-auto">
                Estão na sua exploração! trabalham para proteger o seu bananal!
              </h2>
              <p className="text-[#5E5E5E] w-full md:w-1/2 text-center mb-5">
                Lembre-se que as aplicações de produtos fitofarmacêuticos devem
                respeitar a biodiversidade.
              </p>
              {documents && documents.length > 0 && (
                <a
                  href={documents[0].doc_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-[#89A164]"
                >
                  Descarregar lista de fitofarmacêuticos
                </a>
              )}
            </div>
          </div>
        </Wrapper>
        <LeafAntRight className="absolute right-0 top-0 z-[-1] max-h-[450px] max-w-[450px] hidden sm:block" />
      </TextureBackground>
      <GreenSection>
        <Wrapper className="z-[1] relative" closeFull>
          <LeafLeftYellow className="absolute -left-0 top-1/2 translate-y-[-50%] z-[-1] hidden sm:block" />
          <LeafRightYellow className="absolute -right-0 top-1/2 translate-y-[-50%] z-[-1] hidden sm:block" />
          <div className="flex flex-col md:flex-row items-center flex-wrap justify-center gap-5 py-10">
            <div className="flex flex-col items-center basis-[calc(100%_/_4_-_20px)] gap-5">
              <img src={Wasp1} alt="" />
              <p className="text-center font-bold">
                VESPA PARASITOIDE
                <br />
                Encarsia hispida
              </p>
            </div>
            <div className="flex flex-col items-center basis-[calc(100%_/_4_-_20px)] gap-5">
              <img src={Wasp2} alt="" />
              <p className="text-center font-bold">
                VESPA PARASITOIDE
                <br />
                Família Braconidae
              </p>
            </div>
            <div className="flex flex-col items-center basis-[calc(100%_/_4_-_20px)] gap-5">
              <img src={Wasp3} alt="" />
              <p className="text-center font-bold">
                VESPA PARASITOIDE
                <br />
                Pimpla sp
              </p>
            </div>
            <div className="flex flex-col items-center basis-[calc(100%_/_4_-_20px)] gap-5">
              <img src={Wasp4} alt="" />
              <p className="text-center font-bold">
                JOANINHA
                <br />
                Stethorus spp
              </p>
            </div>
            <div className="flex flex-col items-center basis-[calc(100%_/_4_-_20px)] gap-5">
              <img src={Spider1} alt="" />
              <p className="text-center font-bold">
                TRIPE PREDADOR
                <br />
                Franklinothrips vespiformis
              </p>
            </div>
            <div className="flex flex-col items-center basis-[calc(100%_/_4_-_20px)] gap-5">
              <img src={Spider2} alt="" />
              <p className="text-center font-bold">
                ÁCAROS PREDADORES
                <br />
                Phytoseiulus persimilis
              </p>
            </div>
            <div className="flex flex-col items-center basis-[calc(100%_/_4_-_20px)] gap-5">
              <img src={Spider3} alt="" />
              <p className="text-center font-bold">
                ÁCAROS PREDADORES
                <br />
                Amblyseius californicus
              </p>
            </div>
          </div>
        </Wrapper>
      </GreenSection>
    </>
  );
}
export default Auxiliaries;
